import React from "react";

function Pagination ({ page, setPage, pagesArray, className }) {
  return (
    <nav aria-label="Page navigation example" className={className}>
      <ul className="pagination">
        <li className={`page-item me-1 ${page <= 2 ? "disabled" : ""}` }>
          <a className="page-link" href="#" aria-label="Previous 2" onClick={() => setPage(page - 2)} aria-disabled={page <= 2}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li className={`page-item me-1 ${page === 1 ? "disabled" : ""}` }>
          <a className="page-link" href="#" aria-label="Previous" onClick={() => setPage(page - 1)} aria-disabled={page === 1}>
            <span aria-hidden="true">&lsaquo;</span>
          </a>
        </li>
        {
              pagesArray.slice(0, page).length > 3 &&
                <>
                  <li className={`page-item me-1 ${page === 1 ? "active" : ""}`}>
                    <a className="page-link" href="#" role="button" onClick={() => setPage(1)}>1</a>
                  </li>
                  <span className="me-2 ms-1 align-middle">...</span>
                </>
            }

        {pagesArray.slice(
          page <= 2 ? 0 : page >= pagesArray.length - 1 ? pagesArray.length - 3 : page - 2,
          page <= 2 ? 3 : page >= pagesArray.length - 1 ? pagesArray.length : page + 1
        ).map((pagen, index) => {
          return (
            <li className={`page-item me-1 ${pagen === page ? "active" : ""}`} key={index}>
              <a className="page-link" href="#" role="button" onClick={() => setPage(pagen)}>{pagen}</a>
            </li>
          );
        })}
        {pagesArray.slice(page - 1).length > 3 &&
          <>
            <span className="me-2 ms-1 align-middle">...</span>
            <li className={`page-item me-1 ${pagesArray[pagesArray.length - 1] === page ? "active" : ""}`}>
              <a className="page-link" href="#" role="button" onClick={() => setPage(pagesArray[pagesArray.length - 1])}>{pagesArray[pagesArray.length - 1]}</a>
            </li>
          </>
            }
        <li className={`page-item me-1 ${page === pagesArray.length ? "disabled" : ""}` }>
          <a className="page-link" href="#" role="button" onClick={() => setPage(page + 1)} aria-label="Next" aria-disabled={page === pagesArray.length}>
            <span aria-hidden="true">&rsaquo;</span>
          </a>
        </li>
        <li className={`page-item me-1 ${page >= pagesArray.length - 1 ? "disabled" : ""}` }>
          <a className="page-link" href="#" aria-label="Next 2" onClick={() => setPage(page + 2)} aria-disabled={page >= pagesArray.length - 1}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
