import { MainLayout } from "./MainLayout";
import { Container } from "react-bootstrap";
import "../styles/globals.css";
import AccountSideMenu from "./AccountSideMenu";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import i18n from "../i18n";
export const AccountLayout = props => {
  const history = useHistory();
  useEffect(() => {
    console.log(history.location);
  }, []);
  return (
    <MainLayout>
      <style>
        {`
          .search-input .react-select__indicators,
          .language-container .react-select__indicators,
          .pagination-container .react-select__indicators {
              ${i18n.language === "ar" ? "left" : "right"}: 0!important;
              ${i18n.language === "ar" ? "right" : "left"}: auto !important;
          }
          .search-input .form-select,
          .language-container .form-select {
              ${i18n.language === "ar" ? "padding-left" : "padding-right"}: 10px !important;
          }
          .react-select__single-value {
            margin-${i18n.language === "ar" ? "left" : "right"}: auto !important;
          }
        `}
      </style>
      <Container className="py-4">
        <div className="row py-4 my-4">
          <AccountSideMenu />
          <div className="col-md-9">
            <div className="account-content-container">

              {props.children}

            </div>
          </div>
        </div>
      </Container>
    </MainLayout>
  );
};
