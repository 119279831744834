import { MainLayout } from "../../layouts/MainLayout";
import { Container } from "react-bootstrap";
import { useEffect } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/userSlice";
import { useHistory } from "react-router-dom";
export default function Logout () {
  const history = useHistory();
  const { t } = useTranslation(["translation"]);
  const isLoggedIn = useSelector(state => state.userStore.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div>
        <Container>
          <div className="row justify-content-center mt-5">
            <div className="col-md-12">
              {isLoggedIn && (<LoadingSpinner/>)}
              {!isLoggedIn && (<span>{t("Logged out")}</span>)}

            </div>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
}
