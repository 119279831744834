import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/js/bootstrap.min.js.map";
import "./styles/checkoutPage.css";
import "./styles/globals.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "./i18n";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";

if (process.env.REACT_APP_ENV_NAME === "prod") {
  ReactGA.initialize("G-PDD9YHVNNM");
}

const sentryEnvs = ["dev", "stage", "prod"];
if (sentryEnvs.includes(process.env.REACT_APP_ENV_NAME)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: process.env.REACT_APP_ENV_NAME
  });
}

const persistor = persistStore(store);
const rootEL = document.getElementById("root");
const All = () => {
  return (
    <React.StrictMode>
      <Suspense fallback={<div>Loading... </div>}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App/>
          </PersistGate>
        </Provider>
      </Suspense>
    </React.StrictMode>
  );
};
const root = createRoot(rootEL); // createRoot(container!) if you use TypeScript
root.render(<All />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
