import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb";
import { MainLayout } from "../../layouts/MainLayout";
import { useFetchUserSubscriptionMutation, useCancelUserSubscriptionMutation, useDownloadInvoiceMutation } from "../../store/services/smApi";
import { useParams, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import LoadingSpinner from "../../components/LoadingSpinner";
import FormatDate from "../../utils/formatDate";
// import ModalConfirm from "../../components/ModalConfirm";
import invokeDownload from "../../utils/invokeDownload";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";
import PaymentIcon from "../../components/PaymentIcon";
import UpdatePaymentModal from "./UpdatePaymentModal";
import { toast, ToastContainer } from "react-toastify";
import i18n from "../../i18n";

import SubscriptionDetailsSection from "../../components/SubscriptionDetailsSection";

function PdfIcon (props) {
  return (
    <div className={["pdf-icon", "p-1", ...props.className.split(" ") ?? ""].join(" ")}>
      <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height={props.size ?? 24}
      width={props.size ?? 24}
    >
        <path d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651 0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396.006-.83-.479-1.268-1.255-1.268z" />
        <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 01-.308-.018v1.426H7v-3.936A7.558 7.558 0 018.219 14c.557 0 .953.106 1.22.319.254.202.426.533.426.923-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0111.66 14c.757 0 1.249.136 1.633.426.415.308.675.799.675 1.504 0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z" />
      </svg>
    </div>
  );
}
function SubscriptionDetails () {
  const { t } = useTranslation(["translation"]);

  const [subscription, setSubscription] = useState({});

  const [fetchUserSubscription] = useFetchUserSubscriptionMutation();
  const [cancelUserSubscription, { isLoading: isLoadingCancelUserSubscription }] = useCancelUserSubscriptionMutation();
  const [downloadInvoice] = useDownloadInvoiceMutation();
  const { userSubscriptionId } = useParams();
  const [crumbs, setCrumbs] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isLoading, setIsLoading] = useState({});
  const history = useHistory();
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
  // const [showCancelModal, setShowCancelModal] = useState(false);

  const handleCancelSubscription = async () => {
    const result = await cancelUserSubscription({ id: userSubscriptionId });
    if (result && result.data) {
      fetchSubscription();
    }
  };

  const handleDownloadInvoice = async (id) => {
    setIsLoading({ [id]: true });
    const result = await downloadInvoice({ id });
    if (result && result.data) {
      invokeDownload(result.data, "invoice.pdf");
    }
    setIsLoading({ });
  };

  const fetchSubscription = async () => {
    const result = await fetchUserSubscription({ id: userSubscriptionId });
    if (result && result.data) {
      let method = result.data.payment_source.schema_local ?? result.data.payment_source.brand;
      method = method?.toLowerCase().replace(method[0].toLowerCase(), method[0].toUpperCase());
      setPaymentMethod(method);
      setSubscription(result.data);
      setCrumbs([
        { name: "profile/account-info", display: "Account Management" },
        { name: "subscriptions", display: "Subscriptions" },
        { name: userSubscriptionId, display: result.data.plan_name }
      ]);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  return (
    <MainLayout>
      {/* <ModalConfirm
            show={showCancelModal}
            cancelText={t("No")}
            cancelAction={() => setShowCancelModal(false)}
            isLoading={isLoadingCancelUserSubscription}
            confirmAction={handleCancelSubscription}/> */}
      <ToastContainer
        position={i18n.language === "ar" ? "top-left" : "top-right"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={i18n.language === "ar"}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Container className="py-4">
        <UpdatePaymentModal
          show={showUpdatePaymentModal}
          onHide={() => setShowUpdatePaymentModal(false)}
          onSuccess={(resData) => {
            toast.warn("You will be redirecting To verify your card");
            setTimeout(() => {
              window.location.href = resData.redirect_url;
            }, 500);
          }}
          subscriptionId={subscription?.id}
          prevMethod={subscription?.payment_source?.type}
        />
        <div className="my-4 py-4">
          <Breadcrumb crumbs={crumbs} basePath="/account" />
          <div className="subscription-container p-4">
            {!subscription.id
              ? <LoadingSpinner />
              : <>
                <div className="row">
                  <div className ="col-12 col-lg-5">
                    <div className="d-flex flex-column justify-content-start align-items-start gap-3">

                      <SubscriptionDetailsSection style="" subscription={subscription}>
                        <div className="d-flex justify-content-between flex-wrap mt-30  mt-md-20 mw-sm-25   ">
                          <button className="btn btn-primary flex-grow-1 me-2 "
                              disabled={!subscription.upgrade_promotion || subscription.current_status !== "2"}
                            onClick={() => history.push(`/subscription/upgrade/${subscription.id}`)}
                              >{t("Upgrade Plan")}
                          </button>
                          <button className="btn btn-outline-primary cancel-plan flex-grow-1" onClick={handleCancelSubscription}
                             disabled={isLoadingCancelUserSubscription || ["4", "5", "6"].includes(subscription.current_status)}
                             >
                            {isLoadingCancelUserSubscription
                              ? <ButtonLoadingSpinner />
                              : t("Cancel Plan")}
                          </button>
                          <button className="btn mt-2 w-100 btn btn-primary" onClick={() => setShowUpdatePaymentModal(true)}>
                            {t("Update Payment Method")}
                          </button>
                        </div>
                      </SubscriptionDetailsSection>
                      {
                        subscription.subscription_type === "Renewal" && (
                          <div className="w-100">

                            <div className="subscription-details-card p-4">
                              <div className="d-flex justify-content-start flex-wrap">
                                <PaymentIcon paymentMethod={paymentMethod} />
                                <div className="mt-1">
                                  <h2 className="mb-0">
                                    **** **** **** {subscription.payment_source.last_four}
                                  </h2>
                                  {
                                  subscription.payment_source.expiry_month && subscription.payment_source.expiry_year && (
                                    <p className="plan-price fw-light mb-0">
                                      <span className="text-muted">EX.Date:</span> {subscription.payment_source.expiry_month}/{subscription.payment_source.expiry_year}
                                    </p>

                                  )
                                }
                                </div>
                                {
                                subscription.address.full_name !== "Eshtarek Default Address" && (
                                  <div className="mt-3">
                                    <p className="plan-status text-muted mb-0"> {t("Default Address")}</p>
                                    <p className="plan-advs fw-bolder mb-0">
                                      {subscription?.address.building} {subscription.address.street}, {subscription.address.city}, {subscription.address.state}, {subscription.address.country}, {subscription.address.zip}
                                    </p>
                                  </div>
                                )
                              }
                                <div className="row w-100 mt-3">
                                  <div className="col">
                                    <p className="plan-status text-muted mb-0"> {t("Subscription Date")}</p>
                                    <p className="plan-advs fw-bolder mb-0">
                                      {FormatDate(subscription.date_billing_start, "DD/MM/YYYY")}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="plan-status text-muted mb-0"> {t("Recurring Date")}</p>
                                    <p className="plan-advs fw-bolder mb-0">
                                      {FormatDate(subscription.date_billing_next, "DD/MM/YYYY")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>

                  <div className="invoices mt-4 col-12 col-lg-7">
                    <h3>
                      {t("Invoices")} ({subscription.latest_transactions.length})
                    </h3>
                    <div className="d-flex flex-column justify-content-start align-items-start gap-3 w-100">
                      {
                    subscription.latest_transactions.map((transaction, index) => {
                      return (<div className="invoice-card p-3 d-flex justify-content-center justify-content-md-between  align-items-center flex-wrap w-100" key={transaction.id}>

                        <PdfIcon className="me-1" size={40} />
                        <p className="mb-0 ms-2 invoice-name me-sm-2 me-md-auto">
                          {t("Invoice")}_{FormatDate(transaction.created_at, "MM/YYYY")}.pdf
                        </p>
                        <p className="invoice-name mb-0 mt-2 mt-sm-0">
                          <span className="text-muted fw-lighter">{t("Invoice Date")}</span> : {FormatDate(transaction.created_at, "DD/MM/YYYY")}
                        </p>
                        <button disabled={isLoading[transaction.id] || transaction.status !== "success"} className="btn btn-outline-primary w-auto mt-2 mt-md-0" onClick={() => handleDownloadInvoice(transaction.id)}>
                          {isLoading[transaction.id] ? <ButtonLoadingSpinner /> : t("Download Invoice")}
                        </button>

                      </div>);
                    })
                  }
                    </div>
                  </div>
                </div>

              </>
            }
          </div>
        </div>
      </Container>
    </MainLayout>

  );
}

export default SubscriptionDetails;
