import { useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "i18next";

export const MainNav = () => {
  const history = useHistory();
  const isAuthenticated = useSelector(state => state.userStore.isLoggedIn);
  const merchant = useSelector(state => state.configStore.merchant);
  const savedLang = useSelector(state => state.userStore.language);
  const { t } = useTranslation(["translation"]);

  const changeDirection = (lang) => {
    if (lang === "ar") {
      document.body.lang = lang;
      document.body.dir = "rtl";

      document.getElementById("ltr").disabled = true;
      document.getElementById("rtl").disabled = false;
    } else {
      document.body.dir = "ltr";

      document.getElementById("rtl").disabled = true;
      document.getElementById("ltr").disabled = false;
    }
  };
  useEffect(() => {
    i18n.changeLanguage(savedLang);
    changeDirection(savedLang);
  }, [localStorage]);

  return (
    <Navbar bg="light" expand="lg">
      <Container className="ps-lg-0">
        {/* { !merchant.logo && <NavLink className={"navbar-brand w-25 me-0"} to="/">{merchant.company_name}</NavLink>} */}
        <NavLink className={"navbar-brand w-25 me-0"} to="/"><Image src={merchant.logo ?? "/logo.png"}/></NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-start align-items-center ps-lg-2" style={{ width: "100%" }}>
            <li className={history.pathname === "/" ? "active" : ""}>
              <NavLink exact to="/" className={"nav-link"}>{t("Home")}</NavLink>
            </li>
            {isAuthenticated && (
              <li className={history.pathname && history.pathname.includes("account") ? "nav-item active" : ""}>
                <NavLink to="/account/profile/account-info"
								         className={`nav-link ${history.pathname && history.pathname.includes("account") ? "active" : ""}`}>{t("Account")}</NavLink>
              </li>
            )}
            {
              merchant.external_website && (
                <li className="nav-item ms-lg-auto">
                  <a href={merchant.external_website} className="nav-link" target="_blank" rel="noreferrer">{t("Visit Us")}</a>
                </li>
              )

            }

            {isAuthenticated
						  ? (
  <li className={(history.pathname === "/logout" ? "active" : "") + "ms-lg-auto"}>
    <NavLink to="/logout" className={"btn btn-outline-primary"}>{t("Logout")}</NavLink>
  </li>
                )
              : (
                <li className={(history.pathname === "/login" ? "active" : "") + "ms-lg-auto "}>
                  <NavLink to="/login" className={"btn btn-outline-primary"} activeClassName="active">{t("Login")}</NavLink>
                </li>
                )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
