import React from "react";
import AuthSteps from "./AuthSteps";
import { MainLayout } from "../../layouts/MainLayout";

function Auth () {
  return (
    <MainLayout>
      <AuthSteps />
    </MainLayout>
  );
}

export default Auth;
