import React, { useMemo } from "react";
import { Field } from "formik";
import countryList from "react-select-country-list";
import Select from "react-select";
import CountryCodes from "../utils/constants/CountryCodes";
import { useTranslation } from "react-i18next";

function CountryPhoneField ({ values, setFieldValue, errors, handleFocus, touched, handleBlur, disabled, autoFocus }) {
  const options = useMemo(() => countryList().getData(), []);
  const { t } = useTranslation();
  return (
    <div className="position-relative" dir="ltr">
      <label
        htmlFor="exampleInputphone"
        className="form-label mb-1"
        >
        {t("Phone")}
      </label>
      <div className="d-flex px-2 country-phone align-items-center">
        <div className="position-relative">
          <Select
            name="country"
            options={options}
            formatOptionLabel={(country) => {
              return (
                <div className="country-option">
                  <img style={{ width: "32px" }} src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.value}.svg`} alt={country.label}/>
                  <span className="mx-2">({CountryCodes[country.value]})</span>
                </div>
              );
            }}
            defaultValue={options.find(el => el.value === values.country)}
            isDisabled={disabled}
            onChange={(country) => setFieldValue("country", country.value)}
            onBlur={handleBlur}
            classNamePrefix="react-select"
            placeholder=""
            className={`form-select ${
            errors.country &&
            touched.country
                ? " is-invalid"
                : ""
            }`}
        />
        </div>
        <div className="pipeline">
        </div>
        <div className="position-relative">
          <Field
            style={{
              border: "none"
            }}
                name="phone_number"
                disabled={disabled}
                className={`form-control ps-2 ${
                    errors.phone_number && touched.phone_number ? " is-invalid ps-4" : ""
                }`}
                inputMode="numeric"
                onChange={(e) => {
                  let nextValue;
                  const code = CountryCodes[values.country].substring(1);
                  if (e.target.value.startsWith("+") && !e.target.value.startsWith(CountryCodes[values.country])) {
                    nextValue = values.phone_number;
                  } else {
                    e.target.value.length && isNaN(e.target.value)
                      ? nextValue = values.phone_number
                      : e.target.value.startsWith(code) || e.target.value.startsWith(`00${code}`) || e.target.value.startsWith(`+${code}`)
                        ? nextValue = e.target.value.substring(e.target.value.indexOf(code) + code.length)
                        : nextValue = e.target.value;
                  }

                  setFieldValue("phone_number", nextValue);
                }}
                onBlur={handleBlur}
                value={values.phone_number}
                onFocus={handleFocus}
                autoFocus={autoFocus}
                />
        </div>
      </div>
      {errors.phone_number && touched.phone_number
        ? (
          <div className="invalid-feedback px-1 d-block">
            {t(errors.phone_number)}
          </div>
          )
        : null}
    </div>
  );
}

export default CountryPhoneField;
