import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { ProfileLayout } from "../../layouts/ProfileLayout";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useFetchPaymentSourcesMutation } from "../../store/services/smApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
export default function PaymentInfo () {
  const { t } = useTranslation(["translation"]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [fetchPaymentSources, { isLoading: isLoadingFetchPaymentSources }] = useFetchPaymentSourcesMutation();

  useEffect(() => {
    fetchPaymentSources().then((fetchedPaymentSources) => {
      if (fetchedPaymentSources && fetchedPaymentSources.data) {
        setPaymentMethods(fetchedPaymentSources.data);
      }
    });
  }, []);

  return (
    <ProfileLayout>
      <div className="tab-pane-top text-end mb-4">
        {(paymentMethods.length >= 3 && false) && <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">You can not add more then 3 payment source</Tooltip>}>
          <span className="d-inline-block">
            <Button variant="secondary" disabled style={{ pointerEvents: "none" }}>
              Add payment source
            </Button>
          </span>
        </OverlayTrigger>}
        {(paymentMethods.length < 3 || true) && <NavLink className={"btn btn-secondary"} variant="secondary" to={"/account/profile/payment-add-source"} >
          Add payment source
        </NavLink>}
      </div>
      <div className={""}>
        {isLoadingFetchPaymentSources && (<LoadingSpinner/>)}
        {!isLoadingFetchPaymentSources && paymentMethods.length === 0 && (
          <p>{t("No payment sources added yet")}</p>
        )}
        {paymentMethods.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ maxWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Type")}</TableCell>
                  <TableCell align="left">{t("Last Four")}</TableCell>
                  <TableCell align="left">{t("Brand")}</TableCell>
                  <TableCell align="left">{t("Expiry Date")}</TableCell>
                  {/* <TableCell align="left">{t("Is Default")}</TableCell> */}
                  {/* <TableCell align="right">{t("Action")}</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {(paymentMethods.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      Card
                    </TableCell>
                    <TableCell align="left">{row.last_four}</TableCell>
                    <TableCell align="left">{row.brand}</TableCell>
                    <TableCell align="left">{row.expiry_month && <>{row.expiry_month}/{row.expiry_year}</>}</TableCell>
                    {/* <TableCell align="left">{row.is_default}</TableCell> */}
                    {/* <TableCell align="right"> */}
                    {/*	/!*<Button>{t("Cancel")}</Button>*!/ */}
                    {/* </TableCell> */}
                  </TableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </ProfileLayout>
  );
}
