import { useState, useCallback, useContext } from "react";
import { makeUrl } from "../utils/makeUrl";
import { AuthContext } from "../context/auth.context";

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const auth = useContext(AuthContext);

  const request = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setLoading(true);
      try {
        if (body) {
          body = JSON.stringify(body);
          headers["Content-Type"] = "application/json";
        }

        const response = await fetch(makeUrl(url), { method, body, headers });
        let data = "";
        await response
          .json()
          .then((body) => {
            data = body;
          })
          .catch((e) => {
            console.error(e);
          });

        if (!response.ok) {
          setLoading(false);
          setErrors([data]);
          if (data) {
            return { errors: data };
          }
          // throw new Error(data.message || "Oops, something went wrong");
        }

        setLoading(false);

        return { data };
      } catch (e) {
        setLoading(false);
        // setError(e.message);
        throw e;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const authRequest = useCallback(
    async (
      url,
      method = "GET",
      body = null,
      headers = {},
      isFormData = false
    ) => {
      setLoading(true);
      try {
        if (body && !isFormData) {
          body = JSON.stringify(body);
          headers["Content-Type"] = "application/json";
        }

        const jwtToken =
          headers.Authorization ||
          JSON.parse(localStorage.getItem("data"))?.token;

        const updatedHeaders = {
          ...headers,
          Authorization: `Bearer ${jwtToken}`
        };

        const response = await fetch(makeUrl(url), {
          method,
          body,
          headers: updatedHeaders
        });
        let data = "";

        if (method.toLowerCase() !== "delete") {
          await response
            .json()
            .then((body) => {
              data = body;
            })
            .catch((e) => {
              console.error(e);
            });
        }

        if (!response.ok) {
          if (response.status === 403 || response.status === 401) {
            setErrors([{ details: "Unauthorized" }]);

            auth.logout();
          }

          setLoading(false);
          setErrors([data]);
          if (data) {
            return { errors: data };
          } else if (response.status === 500) {
            setErrors([{ details: "Server error" }]);
          }
          // throw new Error(data.message || "Oops, something went wrong");
        }

        setLoading(false);

        return { data };
      } catch (e) {
        setLoading(false);
        // setError(e.message);
        throw e;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clearError = useCallback(() => setErrors(null), []);

  return { loading, request, authRequest, errors, clearError };
};
