function useQueryParams () {
  const params = new URLSearchParams(
    window ? window.location.search : {}
  );

  return new Proxy(params, {
    get (target, prop) {
      return target.get(prop);
    }
  });
}
export default useQueryParams;

export const removeQueryParam = (param) => {
  const url = new URL(window.location);
  url.searchParams.delete(param);

  // Update the URL without reloading the page
  window.history.replaceState({}, "", url);
};
