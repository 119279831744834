import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import { useCalculateUpgradeFeeMutation, useUserSubscriptionUpgradeRequestMutation } from "../../store/services/smApi";
import { toast, ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
// import CheckoutSuccess from "../CheckoutSuccess/CheckoutSuccess";
import LoadingSpinner from "../../components/LoadingSpinner";
import Close from "../../components/close";
import formatPeriod from "../../utils/formatPeriod";
import { RECURRING_UNITS } from "../../utils/beDicts";
import { BsCheckCircleFill } from "react-icons/bs";
import { ImRadioUnchecked } from "react-icons/im";
import { useSelector } from "react-redux";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";
import AddCard from "../../components/AddCard";
import TabbyPayment from "../../components/payment/TabbyPayment";
import TapPayment from "../../components/TapPayment";
import Alert from "react-bootstrap/Alert";
import MoyasarPayment from "../../components/payment/MoyasarPayment";

export default function UpgradePayment ({ changeStep, prevStep, upgradeCost }) {
  const { userSubscriptionId } = useParams();
  const [sellectedOptions, setSellectedOptions] = useState([]);
  const [promotion, setPromotion] = useState();
  const [upgradeFee, setUpgradeFee] = useState();
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  // const [showModal, setShowModal] = useState(false);
  const [calculateUpgradeFee, { isLoading: calculatingUpgradeFee }] = useCalculateUpgradeFeeMutation();
  const [userSubscriptionUpgradeRequest, { isLoading: upgradeRequestIsLoading }] = useUserSubscriptionUpgradeRequestMutation();
  const isLoggedIn = useSelector((state) => state.userStore.isLoggedIn);
  const [showPayment, setShowPayment] = useState(false);

  const currency = useSelector(
    (state) => state.configStore.merchant.currency_code
  );
  const isCheckoutEnabled = useSelector(
    (state) => state.configStore.merchant.is_checkout_enabled
  );
  const isArbEnabled = useSelector(
    (state) => state.configStore.merchant.is_arb_enabled
  );
  const isTapEnabled = useSelector(
    (state) => state.configStore.merchant.is_tap_enabled
  );
  const isTabbyEnabled = useSelector(
    (state) => state.configStore.merchant.is_tabby_enabled
  );
  const vat_setting = useSelector(
    (state) => state.configStore.merchant?.vat_setting
  );
  const [isCheckoutComPayment, setIsCheckoutComPayment] = useState(
    !!isCheckoutEnabled
  );
  const isMoyasarEnabled = useSelector(
    (state) => state.configStore.merchant.is_moyasar_enabled
  );
  const calculateUpgrade = async () => {
    try {
      const result = await calculateUpgradeFee({ id: upgradeCost, body: { user_subscription: userSubscriptionId, options: sellectedOptions } });
      if (result.data) {
        setPromotion(result.data.upgrade_promotion);
        setUpgradeFee(result.data.totals);
      } else if (result.error?.data) {
        toast.error(result.error.data.error);
      } else {
        toast.error(t("Something went wrong"));
      }
    } catch (err) {
      toast.error(t("Something went wrong"));
    }
  };

  const handlePayment = async (payment_provider, payment_token, schemaLocal, return_data) => {
    console.log(userSubscriptionUpgradeRequest);
    const body = {
      user_subscription: userSubscriptionId,
      upgrade_promotion_plan_cost: upgradeCost,
      payment_provider,
      payment_token,
      upgrade_options: sellectedOptions
    };
    if (payment_provider === "checkout") {
      body.schema_local = schemaLocal;
    }
    const upgradeRequest = await userSubscriptionUpgradeRequest({ body });
    if (upgradeRequest.data?.status === "success") {
      // redirect user to the incoming data.redirect_url
      if (return_data && upgradeRequest.data.new_source) {
        return upgradeRequest.data;
      } else if (upgradeRequest.data.redirect_url) {
        window.location
          .assign(upgradeRequest.data.redirect_url);
      } else {
        toast.success(t("Upgrade request has been submitted successfully"));
        setTimeout(() => {
          history.push(`/account/subscriptions/${userSubscriptionId}`);
        }, 2000);
      }
    } else if (upgradeRequest.error?.data) {
      if (upgradeRequest.error.data instanceof Object) {
        Object.entries(upgradeRequest.error.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value[0] : value}`));
      } else {
        toast.error(upgradeRequest.error.data);
      }
    } else {
      toast.error(t("Something went wrong"));
    }
  };

  const handleChangeOption = (checked, id) => {
    if (checked) {
      setSellectedOptions([...sellectedOptions, id]);
    } else {
      setSellectedOptions(sellectedOptions.filter((option) => option !== id));
    }
  };

  useEffect(() => {
    console.log("SELECTED_UPGRADE_COST FROM PAYMENT", upgradeCost);
    if (!upgradeCost) {
      toast.error(t("No upgrade cost selected, select a plan first"));
      changeStep(prevStep);
    } else {
      calculateUpgrade();
    }
  }, [sellectedOptions]);

  return (
    <>
      {/* <Modal
        show={showModal}
        onHide={() => changeStep(prevStep)}
        backdrop={true}
        keyboard={false}
        size="lg"
        autoFocus
        centered
      >
        <CheckoutSuccess />
      </Modal> */}
      <ToastContainer />
      <Container>
        <div className="row py-4 py-lg-5  justify-content-center">
          <div className="col-lg-8">
            {calculatingUpgradeFee &&
              (
                <div className="d-flex justify-content-center align-items-center">
                  <LoadingSpinner />
                </div>
              )
            }
            {promotion && !calculatingUpgradeFee &&
               (
                 <div className="ui-card">
                   <div className="">
                     <Close>
                       <div className="cursor-pointer close-text" onClick={() => changeStep(prevStep)}>
                         {t("Change upgrade plan")}
                       </div>
                     </Close>

                     <p className="card-title-bigger mb-12">
                       {promotion.plan_cost.plan.subscription.title}
                     </p>
                     <p className="card-info mt-0 mb-12">
                       {t("plan")} : {promotion.plan_cost.plan.name}
                     </p>

                     <p className="mb-16">
                       <span className="card-info-bolder color-text">
                         {parseFloat(promotion.plan_cost.full_vat_data.total_after_vat).toFixed(2)}{" "}
                         {t(promotion.plan_cost.display_currency_code)}{" "}
                       </span>
                       <span className="card-info color-info">
                         /
                         {formatPeriod(
                           promotion.plan_cost.recurrence_unit,
                           promotion.plan_cost.recurrence_period,
                           t,
                           false
                         )}
                       </span>
                       {
                          +promotion.plan_cost.setup_feet > 0 && (
                            <span className="fw-bold small text-black ms-1">
                              {`( + ${promotion.plan_cost.setup_fee_with_vat} ${t(promotion.plan_cost.display_currency_code)} ${t("Setup Fee")} )`}
                            </span>
                          )
                        }
                     </p>
                     <p className="card-info color-info">
                       {promotion.plan_cost.plan.html_content}
                     </p>
                     {
                        promotion.plan_cost.billing_cycle !== "forever" && (
                          <p className="card-info text-black">
                            {t("Total Subscription Length")} : {promotion.plan_cost.billing_cycle_count * promotion.plan_cost.recurrence_period} {t((RECURRING_UNITS[promotion.plan_cost.recurrence_unit] + (
                              promotion.plan_cost.billing_cycle_count * promotion.plan_cost.recurrence_period > 1 ? "s" : ""
                            )).toLowerCase())}
                            {" "}
                            {
                              `(${promotion.plan_cost.billing_cycle_count} ${t("Billing Cycles")})`
                            }
                          </p>
                        )
                      }
                   </div>
                   <div className="mt-24 d-flex flex-column align-items-start justify-content-start gap-2">
                     <p className="card-title-bigger color-text m-0">
                       {t("plan features")}
                     </p>
                     {promotion.plan_cost.plan.plan_features.map((option, idx) => (
                       <div
                        key={`option${idx}`}
                        className="d-flex gap-2 align-items-center"
                      >
                         {option.is_active
                           ? (
                             <BsCheckCircleFill className="color-primary" />
                             )
                           : (
                             <ImRadioUnchecked className="color-primary" />
                             )}

                         <span className="feat-text">
                           {option.name} {option.value}
                         </span>
                       </div>
                     ))}
                   </div>
                   {
                      promotion.plan_cost.plan.plan_options.length > 0 && (
                        <div className="mt-24 d-flex flex-column align-items-start justify-content-start gap-2">
                          {promotion.plan_cost.plan.plan_options?.length > 0 && (
                            <p className="card-title-bigger color-text m-0">
                              {t("Plan Addons")}
                            </p>
                          )}

                          <ul className="list-style-none m-0">
                            {promotion.plan_cost.plan.plan_options.map((plan_option) => (
                              <>
                                <li className="pt-2">
                                  <div className="form-check">
                                    <input
                                  className="form-check-input flex-shrink-0 font-20"
                                  type="checkbox"
                                  onChange={(e) => handleChangeOption(e.target.checked, plan_option.id)}
                                  id={plan_option.id}
                                  checked={
                                    sellectedOptions.includes(plan_option.id)
                                  }
                                />
                                    <label
                                  className="form-check-label mb-0"
                                  htmlFor={plan_option.id}
                                >
                                      {plan_option.name}:{" "}
                                      <strong>
                                        {t(currency)}{" "}
                                        {parseFloat(plan_option.full_vat_data.total_after_vat).toFixed(2)}
                                      </strong>
                                    </label>
                                  </div>
                                </li>
                              </>
                            ))}
                          </ul>
                        </div>
                      )
                    }
                   {vat_setting?.vat_enable && (
                     <div className="mt-24">
                       <p className="card-info-bolder color-text">
                         {t("Vat Number")} : {vat_setting.vat_number}
                       </p>
                     </div>
                   )}
                 </div>
               )}
          </div>

          <div className="col-lg-4 ui-card-outer pd-20">
            <div className="mt-20 card-info-bolder">
              <p className="card-sub-title mb-20">{t("Price Summery")}</p>
            </div>
            <div className="divider"></div>
            {calculatingUpgradeFee &&
              (
                <div className="d-flex justify-content-center align-items-center">
                  <LoadingSpinner />
                </div>
              )
              }
            {promotion && !calculatingUpgradeFee &&
              (
                <div>
                  <div className="mt-20 d-flex align-content-center justify-content-between">
                    <p className="summery-item-title">{t("New Plan Price")} </p>
                    <p>
                      <strong>
                        {" "}
                        {parseFloat(upgradeFee.new_price).toFixed(
                          2
                        )}{" "}
                        {t(currency)}{" "}
                        <span className="card-info color-info fs-16">
                          /
                          {formatPeriod(
                            promotion.plan_cost.recurrence_unit,
                            promotion.plan_cost.recurrence_period,
                            t,
                            false
                          )}
                        </span>
                      </strong>
                    </p>
                  </div>
                  {upgradeFee?.new_setup_fee > 0 && (
                    <div className="mt-20 d-flex align-content-center justify-content-between">
                      <p className="summery-item-title">{t("New Setup Fee")} </p>
                      <p>
                        <strong>
                          {" "}
                          {parseFloat(
                            upgradeFee?.new_setup_fee
                          ).toFixed(2)}{" "}
                          {t(currency)}{" "}
                        </strong>
                      </p>
                    </div>
                  )}
                  {
                      upgradeFee?.new_options > 0 && (
                        <div className="mt-20 d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">{t("Plan Addons")} </p>
                          <p>
                            <strong>
                              {" "}
                              {parseFloat(
                                upgradeFee?.new_options
                              ).toFixed(2)}{" "}
                              {t(currency)}{" "}
                            </strong>
                          </p>
                        </div>
                      )
                    }
                  {
                      upgradeFee?.remaining_amount > 0 && (
                        <div className="mt-20 d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">
                            {t("Remaining Amount")}{" "}
                            <span className="card-info color-info fs-16">
                              ({upgradeFee.remaining_period} {t((RECURRING_UNITS[upgradeFee.recurrence_unit] + (
                              upgradeFee.remaining_period > 1 ? "s" : ""
                            )).toLowerCase())})
                            </span>
                          </p>
                          <p>
                            <strong>
                              {" - "}
                              {parseFloat(
                                upgradeFee?.remaining_amount
                              ).toFixed(2)}{" "}
                              {t(currency)}{" "}
                            </strong>
                          </p>
                        </div>
                      )
                    }
                  <div className="mt-20 mb-20 d-flex align-content-center justify-content-between">
                    <p className="summery-item-title">
                      {t("Total Before Discount")}
                    </p>
                    <p>
                      <strong>
                        {" "}
                        {upgradeFee.total_before_discount} {t(currency)}{" "}
                      </strong>
                    </p>
                  </div>
                  {promotion.discount_type === "2" && (
                    <div className="mt-20  d-flex align-content-center justify-content-between">
                      <p className="summery-item-title">
                        {t("Discount Percentage")}{" "}
                      </p>
                      <p>
                        <strong>
                          {" "}
                          -{promotion.discount} %{" "}
                        </strong>
                      </p>
                    </div>
                  )}
                  <div className="mt-20  d-flex align-content-center justify-content-between">
                    <p className="summery-item-title">
                      {t("Discount Amount")}{" "}
                    </p>
                    <p>
                      <strong>
                        {" "}
                        -{parseFloat(upgradeFee?.discount_amount).toFixed(2)} {t(currency)}{" "}
                      </strong>
                    </p>
                  </div>
                  <div className="mt-20  d-flex align-content-center justify-content-between">
                    <p className="summery-item-title">
                      {t(!upgradeFee.upgrade_amount.vat_enable ? "Total After Discount" : "Total Before VAT")}{" "}
                    </p>
                    <p>
                      <strong>
                        {" "}
                        {upgradeFee?.upgrade_amount.total_before_vat} {t(currency)}{" "}
                      </strong>
                    </p>
                  </div>
                  {upgradeFee.vat_enable && (
                    <div className="mt-20 d-flex align-content-center justify-content-between">
                      <p className="summery-item-title">{t("VAT")} </p>
                      <strong>
                        {" "}
                        {upgradeFee.upgrade_amount.vat_amount} {t(currency)} (
                        {upgradeFee.upgrade_amount.vat_percentage}% ){" "}
                      </strong>
                    </div>
                  )}
                  {
                      upgradeFee.vat_enable && (
                        <div className="mt-20 mb-20 d-flex align-content-center justify-content-between">
                          <p className="summery-item-title">{t("Total")} </p>
                          <p>
                            <strong>
                              {" "}
                              {parseFloat(upgradeFee.upgrade_amount.total_after_vat).toFixed(2)} {t(currency)}{" "}
                            </strong>
                          </p>
                        </div>
                      )}
                </div>
              )}
            <hr className="my-5" />
            <div>
              {!showPayment
                ? (
                  <div className="d-flex gap-2">
                    <button
                  className="btn btn-lg btn-primary w-full height-unset flex-grow-1"
                  onClick={() => handlePayment()}
                  disabled={upgradeRequestIsLoading}
                >
                      {t("Pay")}{" "}
                      <strong>
                        {t(currency)}{" "}
                        {parseFloat(upgradeFee?.upgrade_amount.total_after_vat).toFixed(2)}{" "}
                      </strong>
                      {t("with your saved card")}
                      {upgradeRequestIsLoading && <ButtonLoadingSpinner />}
                    </button>
                    <button
                  className="btn btn-lg btn-primary w-full height-unset flex-grow-1"
                  onClick={() => setShowPayment(true)}
                >
                      {t("Pay")}{" "}
                      <strong>
                        {t(currency)}{" "}
                        {parseFloat(upgradeFee?.upgrade_amount.total_after_vat).toFixed(2)}{" "}
                      </strong>
                      {t("with a new card")}
                    </button>
                  </div>
                  )
                : (
                  <PaymentProvider
                handlePayment={handlePayment}
                isCheckoutComPayment={isCheckoutComPayment}
                isArbEnabled={isArbEnabled}
                isTapEnabled={isTapEnabled}
                isTabbyEnabled={isTabbyEnabled}
                isMoyasarEnabled={isMoyasarEnabled}
                upgradeRequestIsLoading={upgradeRequestIsLoading}
                upgradeFee={upgradeFee}
                currency={currency}
                isCheckoutEnabled={isCheckoutEnabled}
                isLoggedIn={isLoggedIn}
                setIsCheckoutComPayment={setIsCheckoutComPayment}

                />
                  )
            }

            </div>
          </div>
        </div>
      </Container>
    </>

  );
}

export const PaymentProvider = ({
  handlePayment,
  isCheckoutComPayment,
  isArbEnabled,
  isTapEnabled,
  isTabbyEnabled,
  isMoyasarEnabled,
  upgradeRequestIsLoading,
  upgradeFee,
  currency,
  isCheckoutEnabled,
  isLoggedIn,
  setIsCheckoutComPayment,
  isUpdateMethod = false,
  amount
}) => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();

  useEffect(() => {
    console.log("IS CHECKOUT ENABLED", isCheckoutEnabled);
    console.log("IS CHECKOUT COM ENABLED", isCheckoutComPayment);
  }, [isCheckoutComPayment, isCheckoutEnabled]);

  return (
    <div>
      {isArbEnabled && !isCheckoutComPayment && !isTapEnabled && (
        <button
          type="submit"
          className="btn btn-lg btn-primary w-100 height-unset pay-btn"
          onClick={() => {
            isLoggedIn
              ? handlePayment("arb")
              : history.push("/login?next=checkout");
          }}
          disabled={upgradeRequestIsLoading}
        >
          {upgradeRequestIsLoading && <ButtonLoadingSpinner />}
          {
            isUpdateMethod
              ? t("Update Card Details")
              : <>
                {t("Pay")}{" "}
                <strong>
                  {t(currency)}{" "}
                  {parseFloat(amount ?? upgradeFee?.upgrade_amount.total_after_vat).toFixed(2)}{" "}
                  {t("with")} ARB
                </strong>
              </>
          }
        </button>
      )}
      {isCheckoutEnabled &&
        isCheckoutComPayment &&
        !isArbEnabled &&
        !isTapEnabled &&
        !isMoyasarEnabled && (
          <div className="mt-20 mb-20">
            <AddCard
              setError={() => {}}
              onSuccess={(res) => {
                handlePayment("checkout", res.token, res.schema_local);
              }}
              display_amount={
                isUpdateMethod
                  ? `${t("Update Card Details")}`
                  : `${t("Pay")} ${parseFloat(
                amount ?? upgradeFee?.upgrade_amount.total_after_vat
              ).toFixed(2)} ${t(currency)}`}
            />
          </div>
      )}
      {isCheckoutEnabled &&
        !isCheckoutComPayment &&
        !isArbEnabled &&
        !isTapEnabled &&
        !isMoyasarEnabled && (
          <button
            type="submit"
            className="btn btn-lg btn-primary w-100 mt-4 height-unset"
            onClick={() => {
              isLoggedIn
                ? setIsCheckoutComPayment(true)
                : history.push("/login?next=checkout");
            }}
            disabled={upgradeRequestIsLoading}
          >
            {
              isUpdateMethod
                ? t("Update Card Details")
                : <>
                  {upgradeRequestIsLoading && <ButtonLoadingSpinner />}
                  {t("Pay")}{" "}
                  <strong>
                    {t(currency)}{" "}
                    {parseFloat(amount ?? upgradeFee?.upgrade_amount.total_after_vat).toFixed(2)}{" "}

                  </strong>
                </>
            }
          </button>
      )}
      {isTapEnabled && !isCheckoutEnabled && !isArbEnabled && !isMoyasarEnabled && (
        <TapPayment
        isUpdateMethod={isUpdateMethod}
          amount={ isUpdateMethod
            ? "1.00"
            : parseFloat(amount ?? upgradeFee?.upgrade_amount.total_after_vat).toFixed(2)}
          currency={t(currency)}
          onSuccess={(res) => {
            const localCards = {
              MADA: "mada",
              MIZA: "miza",
              AMEX: "amex"
            };
            handlePayment("tap", res.id, localCards[res?.card?.scheme]);
          }}
          LoadingSubscripe={upgradeRequestIsLoading}
        />
      )}

      {
        isTabbyEnabled && !isTapEnabled && !isCheckoutEnabled && !isArbEnabled && !isMoyasarEnabled &&
        (<TabbyPayment
              amount={parseFloat(amount ?? upgradeFee?.upgrade_amount.total_after_vat).toFixed(2)}
          currency={currency}
          handlePayment={(res) => {
            handlePayment("tabby");
          }}
          upgradeRequestIsLoading={upgradeRequestIsLoading}
          ></TabbyPayment>)

      }
      {
        isMoyasarEnabled && !isTabbyEnabled && !isTapEnabled && !isCheckoutEnabled && !isArbEnabled && (
          <MoyasarPayment
            amount={parseFloat(amount ?? upgradeFee?.upgrade_amount?.total_after_vat).toFixed(2)}
            currency={currency}
            handlePayment={handlePayment}
            loadingInit={upgradeRequestIsLoading}
          />
        )

      }

      {!isArbEnabled && !isCheckoutEnabled && !isTapEnabled && !isTabbyEnabled && !isMoyasarEnabled &&
       (
         <Alert variant={"warning"}>
           {t(
             "No payment providers are available, please contact merchant to resolve the problem"
           )}
         </Alert>
       )}
    </div>

  );
};
