import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Breadcrumb ({ crumbs, basePath }) {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  return (
    <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
      <ol className="breadcrumb">
        {
                        crumbs.map((item, index) => {
                          if (history.location.pathname?.split("/").pop() === item.name) {
                            return (
                              <li key={index} className="breadcrumb-item active" aria-current="page">{t(item.display)}</li>
                            );
                          }
                          return (
                            <li key={index} className="breadcrumb-item"><NavLink to={`${basePath}/${item.name}`}>{t(item.display)}</NavLink></li>
                          );
                        })
                    }

      </ol>
    </nav>
  );
}

export default Breadcrumb;
