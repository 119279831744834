import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import { ImDrawer2 } from "react-icons/im";
import Select from "react-select";
import { useState, useEffect } from "react";
import Pagination from "../Pagination";
import LoadingSpinner from "../LoadingSpinner";

const CustomTable = ({ columns, data, onSearch, onSort, isLoading, indexed, extraHeader, onPaginate, emptyDataText, emptyDataTitle }) => {
  const { t } = useTranslation(["translation"]);
  const [pagesArray, setPagesArray] = useState([1]);

  useEffect(() => {
    const pagesCount = Math.ceil(onPaginate.count / onPaginate.limit);
    setPagesArray(Array.from({ length: pagesCount }, (_, i) => i + 1));
  }, [onPaginate]);

  return (
    <div className="w-100">
      <div className="row mb-3 search-input">
        {
            onSearch && (
              <div className="position-relative col-lg-6 col-xl-4 ">
                <Form.Control
                type="text"
                onChange={onSearch}
                className="form-control me-auto w-100"
                placeholder={t("Search")}
            />
                <BiSearch className="position-absolute top-50 start-0 translate-middle-y ms-4" size={20} />

              </div>
            )
        }
        <div className="col-lg-6 col-xl-8 pt-2 pt-lg-0 ps-lg-3 ps-xl-5 text-end d-flex justify-content-end align-items-center">
          {extraHeader && extraHeader}
        </div>
      </div>
      <div className="subscriptions-table mb-3 table-responsive">
        {isLoading && <LoadingSpinner className="my-5 mx-auto"/>}
        {data.length === 0 && !isLoading &&
          <div className="empty-address d-flex justify-content-center align-items-center">
            <div className="position-relative text-center">
              <ImDrawer2 className="m-b1" size={40} />
              <h3 className="my-2">{t(emptyDataTitle)}</h3>
              <p>{t(emptyDataText)}</p>
            </div>
          </div>
        }
        {data.length > 0 && !isLoading &&
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                {indexed && (
                  <th className="p-3 align-middle" scope="col">#</th>
                )}
                {columns.map((column, idx) => {
                  if (column.renderColumn) {
                    return <>  {column.renderColumn()}</>;
                  } else {
                    return <th className="p-3 align-middle" scope="col" key={idx}>{column.title}</th>;
                  }
                })}
              </tr>
            </thead>
            <tbody>
              { data.map((row, index) => {
                return (
                  <tr key={index} className={data.length === index + 1 ? "last-row" : ""} >
                    {indexed && (
                      <th scope="row" className="px-3 py-4 align-middle">
                        #{index + 1}
                      </th>
                    )}
                    {columns.map((column, cindex) => {
                      let value;
                      if (Array.isArray(column.dataIndex)) {
                        value = column.dataIndex.reduce((acc, curr) => {
                          return acc[curr] ?? "";
                        }, row);
                      } else {
                        value = row[column.dataIndex];
                      }
                      if (column.render) {
                        value = column.render(value, row, index);
                      }
                      return (
                        <td key={`${cindex}-${index}`} className="px-3 py-4 align-middle">
                          {value}
                        </td>
                      );
                    }
                    )}
                  </tr>
                );
              }
              )}
            </tbody>
          </table>
          }
      </div>
      {data.length > 0 && !isLoading && onPaginate &&
        <div className="d-flex justify-content-start align-items-center  pagination-container">
          <Pagination className="me-auto" page={onPaginate.page} pagesArray={pagesArray} setPage={onPaginate.setPage} />

          <span className="me-2">{t("Page")}</span>
          <Select
          classNamePrefix="react-select"
          className="form-select form-control w-25 ps-3 pe-0"
          options={pagesArray.map((page) => ({ value: page, label: page }))} defaultValue={{ value: onPaginate.page, label: onPaginate.page }}
          onChange={(select) => onPaginate.setPage(select.value)}
          />
          <span className="ms-2">{t("Of")} {pagesArray.length}</span>
        </div>
      }
    </div>
  );
};

export default CustomTable;
