import { useEffect, useState } from "react";
import { AccountLayout } from "../../layouts/AccountLayout";
import { IoCalendarOutline } from "react-icons/io5";
import formatDate from "../../utils/formatDate";
import { useTranslation } from "react-i18next";
import { useFetchUserSubscriptionsMutation } from "../../store/services/smApi";
import { useSubscriptionsColumns } from "../../hooks/columns/Subscriptions";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { USER_SUBSCRIPTION_STATUS } from "../../utils/beDicts";
import CustomTable from "../../components/Table/CustomTable";

const SubscriptionsExtraHeader = ({ handleChangeQueryParams, queryParam }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState({ start: null, end: null });

  return (
    <div className="col-lg-6 col-xl-8 pt-2 pt-lg-0 ps-lg-3 ps-xl-5 text-end d-flex justify-content-end align-items-center">
      <div className="w-50 ms-lg-3 ms-xl-5 me-3  position-relative">
        <DatePicker className="form-control w-100 px-lg-3 px-xl-5 " placeholderText={t("Select Date from to")} selected={selectedDate.start} startDate={selectedDate.start} endDate={selectedDate.end} selectsRange
            onChange={(date) => {
              setSelectedDate({ start: date[0], end: date[1] });
              handleChangeQueryParams("date_billing_start_after", formatDate(date[0], "YYYY-MM-DD"));
              handleChangeQueryParams("date_billing_start_before", formatDate(date[1] ?? new Date(), "YYYY-MM-DD"));
            }} />
        <IoCalendarOutline className="position-absolute top-50 end-0 translate-middle-y me-4 me-lg-2 me-xl-4" size={20} />
      </div>
      <Select
          classNamePrefix="react-select"
          className="form-select form-control w-50 ps-3 pt-2"
          label={t("Status")}
          defaultValue={{ value: "2", label: t("Active") }} isSearchable={false} isClearable={false} name="status"
          options={[...Object.values(USER_SUBSCRIPTION_STATUS).map(([label, value]) => ({ value, label: t(label) })), { value: "", label: t("All") }]}
          onChange={(select) => {
            handleChangeQueryParams("current_status", select.value);
          }} />
    </div>
  );
};

export default function Subscriptions () {
  const [subscriptions, setSubscriptions] = useState([]);
  const [fetchUserSubscriptions, { isLoading: isLoadingFetchUserSubscriptions }] = useFetchUserSubscriptionsMutation();
  const { t } = useTranslation(["translation"]);
  const columns = useSubscriptionsColumns();
  const [count, setCount] = useState(0);
  const [queryParam, setQueryParam] = useState({
    page: 1,
    date_billing_start_after: "",
    date_billing_start_before: "",
    current_status: "2",
    page_size: 8,
    search: ""
  });

  const prepareQuery = () => {
    const query = { ...queryParam };
    Object.keys(query).forEach(key => (!query[key] || query[key] === "") && delete query[key]);
    const queryStr = new URLSearchParams(query).toString();
    return queryStr;
  };

  const update_data = async () => {
    fetchUserSubscriptions(prepareQuery()).then((result) => {
      if (result && result.data) {
        setCount(result.data.count);
        setSubscriptions(result.data.results);
      }
    });
  };
  const handleChangeQueryParams = (param, value) => {
    setQueryParam({ ...queryParam, [param]: value, page: param === "page" ? value : 1 });
  };

  useEffect(() => {
    update_data();
  }, [queryParam]);

  return (
    <AccountLayout>

      <h2 className="py-2 mb-0">{t("Subscriptions")}</h2>
      <hr />
      <CustomTable
        columns={columns}
        data={subscriptions}
        onSearch={(e) => handleChangeQueryParams("search", e.target.value)}
        onPaginate={{
          count,
          limit: queryParam.page_size,
          page: queryParam.page,
          setPage: (page) => handleChangeQueryParams("page", page)
        }}
        isLoading={isLoadingFetchUserSubscriptions}
        emptyDataTitle={t("There is no subscriptions yet")}
        emptyDataText={t("Please subscribe to any subscription first")}
        indexed
        extraHeader={<SubscriptionsExtraHeader handleChangeQueryParams={handleChangeQueryParams} queryParam={queryParam} />}
      />
    </AccountLayout>
  );
}
