import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { VscAccount, VscBell } from "react-icons/vsc";
import { CiLocationOn, CiCreditCard1, CiLogout } from "react-icons/ci";
import { AiOutlineCrown } from "react-icons/ai";
import { HiOutlineLanguage } from "react-icons/hi2";

function AccountSideMenu () {
  const { t } = useTranslation();
  const history = useHistory();
  const menuItems = [
    {
      name: "profile",
      displayName: t("Profile"),
      icon: <VscAccount size={28} className="me-2" />,
      path: "/account/profile/account-info"
    },
    {
      name: "addresses",
      displayName: t("Addresses"),
      icon: <CiLocationOn size={28} className="me-2" />,
      path: "/account/profile/addresses"
    },
    {
      name: "subscriptions",
      displayName: t("Subscriptions"),
      icon: <AiOutlineCrown size={28} className="me-2" />,
      path: "/account/subscriptions"
    },
    {
      name: "payment_methods",
      displayName: t("Payment Methods"),
      icon: <CiCreditCard1 size={28} className="me-2" />,
      path: "/account/payment_methods",
      disabled: false
    },
    {
      name: "payment_requests",
      displayName: t("Payment Requests"),
      icon: <CiCreditCard1 size={28} className="me-2" />,
      path: "/account/profile/payment_requests"
    },
    {
      name: "email_notifications",
      displayName: t("Email Notifications"),
      icon: <VscBell size={28} className="me-2" />,
      path: "/account/profile/email_notifications",
      disabled: true
    },
    {
      name: "language",
      displayName: t("Language"),
      icon: <HiOutlineLanguage size={28} className="me-2" />,
      path: "/account/profile/language"
    },
    {
      name: "logout",
      displayName: t("Logout"),
      icon: <CiLogout size={28} className="me-2" />,
      path: "/logout"
    }
  ];
  return (
    <div className="col-md-3 ps-lg-0 pe-lg-4">
      <aside className="rounded-3">
        <div className="account-aside">
          {
                menuItems.map(item => {
                  return (
                    <NavLink
                    // onClick={(e) => {
                    //   if (item.disabled) e.preventDefault();
                    // }}
                     key={item.name}
                     to={item.path}
                     className={`w-100 btn btn-outline-primary py-3 mb-2 text-start ${history.location.pathname === item.path ? "active" : ""} ${item.disabled ? "disabled" : ""}`}>
                      {item.icon} <span>{item.displayName}</span>
                    </NavLink>
                  );
                })
            }
        </div>
      </aside>
    </div>
  );
}

export default AccountSideMenu;
