// export const apiBaseUrl =
//   process.env.NODE_ENV === "development"
//     ? "http://test.atmet.io/api"
//     : `${window.location.origin}/api`;
export const apiBaseUrl =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV === "development"
    ? `http://${window.location.host.split(".")[0]}.localhost:${process.env.REACT_APP_BACK_PORT ?? "8010"}/api`
    : `${window.location.origin}/api`;
// process.env.NODE_ENV === "development"
//   ? "https://merchant.eshtarek.co/api"
//   : `${window.location.origin}/api`;

export const makeUrl = (urlSuff) => {
  return `${apiBaseUrl}/${urlSuff}`;
};
