import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  planData: null
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCheckoutingPlan: (state, action) => {
      console.log("setCheckoutingPlan");
      state.planData = action.payload;
    }
  }
  // extraReducers: (builder) => {}
});

export const { setCheckoutingPlan } = checkoutSlice.actions;

export default checkoutSlice.reducer;
