import React, { useState, useEffect } from "react";
import { useQuery } from "../../hooks/query.hook";
import EmailOrPhoneStep from "./EmailOrPhoneStep";
import OtherDataStep from "./OtherDataStep";
import VerifyStep from "./VerifyStep";
function AuthSteps ({ empedded }) {
  const { query, appendQuery } = useQuery();
  const key = query?.step ?? "email_or_phone";
  const next = `/${query.next ?? ""}`;
  const [usedCredentials, setUsedCredentials] = useState({});
  const [userCountry, setUserCountry] = useState();
  const changeStep = (key) => {
    appendQuery("step", key);
  };

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const result = await fetch("https://ipapi.co/json/").then(res => res.json())
          .catch(err => {
            console.log(err
            );
            setUserCountry("SA");
          });
        setUserCountry(result?.country_code ?? "SA");
      } catch (err) {
        console.log(err);
        setUserCountry("SA");
      }
    };
    fetchCountry();
  }, []);

  return (
    <>
      {key === "email_or_phone" && userCountry && <EmailOrPhoneStep changeStep={changeStep} setUsedCredentials={setUsedCredentials} userCountry={userCountry} empedded={empedded}/>}
      {key === "verify" && <VerifyStep changeStep={changeStep} usedCredentials={usedCredentials} next={next} setUsedCredentials={setUsedCredentials} empedded={empedded}/>}
      {key === "other_data" && <OtherDataStep changeStep={changeStep} userCredentials={usedCredentials} next={next} empedded={empedded}/>}
    </>
  );
}

export default AuthSteps;
