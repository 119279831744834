import { createSlice } from "@reduxjs/toolkit";
import { smApi } from "../services/smApi";

const initialState = {
  merchant: null,
  subscriptions: []
};

export const configSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // setSettings: (state, action) => {
    //   state.settings = action.payload;
    // },
    // setCurrency: (state, action) => {
    //   state.currency = action.payload;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        smApi.endpoints.fetchMerchant.matchFulfilled, (state, action) => {
          state.merchant = action.payload;
        })
      .addMatcher(
        smApi.endpoints.fetchSubscriptions.matchFulfilled, (state, action) => {
          state.subscriptions = action.payload;
        });
  }
});

// export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
