import { MainLayout } from "../../layouts/MainLayout";
import { useQuery } from "../../hooks/query.hook";
import PlanSelect from "./PlanSelect";
import { useState } from "react";
import UpgradePayment from "./Payment";

export default function UpgradePlan () {
  const { query, appendQuery } = useQuery();
  const key = query?.step ?? "choose_plan";
  const [selectedUpgradeCost, setSelectedUpgradeCost] = useState("");
  const [upgradeCost, setUpgradeCost] = useState("");

  const changeStep = (nextStep) => {
    appendQuery("step", nextStep);
  };

  return (
    <MainLayout>
      {key === "choose_plan" && <PlanSelect changeStep={changeStep} nextStep="payment" selectedUpgradeCost={selectedUpgradeCost} setSelectedUpgradeCost={setSelectedUpgradeCost} setUpgradeCost={setUpgradeCost} />}
      {key === "payment" && <UpgradePayment changeStep={changeStep} prevStep="choose_plan" upgradeCost={upgradeCost} />}
    </MainLayout>
  );
}
