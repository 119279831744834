import React from "react";
import { ImDrawer2 } from "react-icons/im";
import { useTranslation } from "react-i18next";
const EmptyData = ({ title, hint, icon, ...props }) => {
  const { t } = useTranslation();
  return (

    <div className="empty-address d-flex justify-content-center align-items-center w-100">
      <div className="position-relative text-center">
        {icon || <ImDrawer2 className="m-b1" size={40}/>}
        <h3 className="my-2">{title || t("There is no Data yet")}</h3>
        <p>{ hint || t("To add a new Item, click on the \"Create\" button")}</p>
      </div>
    </div>

  );
};
export default EmptyData;
