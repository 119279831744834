import { useState } from "react";
import { ProfileLayout } from "../../layouts/ProfileLayout";
import { Alert } from "react-bootstrap";
import AddCard from "../../components/AddCard";

export default function NewPaymentSource (props) {
  const [error, setError] = useState("");

  return (
    <ProfileLayout>
      {error && <Alert key={"danger"} variant={"danger"}>
        {error}
      </Alert>
			}
      <div className="rounded-3 shadow bg-white p-4">

        <AddCard
					setError={setError}
					onSuccess={() => { props.history.push("/account/profile/payment-info"); }}
				/>

      </div>
    </ProfileLayout>
  );
}
