import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
const TabbyPayment = ({ amount, currency, handlePayment, subscribePayIsLoading, ...props }) => {
  const lang = useSelector((state) => state.userStore.language);
  const { t } = useTranslation(["translation"]);

  // useEffect(() => {
  //   const initializeTabbyCard = () => {
  //   // eslint-disable-next-line no-undef,no-new
  //     new TabbyCard({
  //       selector: "#tabbyCard", // empty div for TabbyCard.
  //       currency: "SAR", // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
  //       lang: "en", // Optional, language of snippet and popups.
  //       price: 100, // required, total price or the cart. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
  //       size: "narrow", // required, can be also 'wide', depending on the width.
  //       theme: "black", // required, can be also 'default'.
  //       header: false // if a Payment method name present already.
  //     });
  //   };
  //   if (window.TabbyCard) {
  //     initializeTabbyCard();
  //   }
  // }, [window.TabbyCard]);

  useEffect(() => {
    const loadScript = (link) => {
      const script = document.createElement("script");
      script.src = link;
      // script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    const cleanup1 = loadScript("https://checkout.tabby.ai/tabby-card.js");
    const cleanup2 = loadScript("https://checkout.tabby.ai/tabby-promo.js");

    return () => {
      cleanup1();
      cleanup2();
    };
  }, []);
  useEffect(() => {
    tabbyButton();
  }, []);

  const tabbyButton = (e) => {
    if (window.TabbyPromo) {
    // eslint-disable-next-line no-undef,no-new
      new TabbyPromo({
        selector: "#tabbyCard", // required, content of tabby Promo Snippet will be placed in element with that selector.
        currency, // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
        price: amount, // required, price or the product. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
        installmentsCount: 4, // Optional, for non-standard plans.
        lang // Optional, language of snippet and popups, if the property is not set, then it is based on the attribute 'lang' of your html tag.

      });
    }
  };

  return <div>
    <IconButton aria-label="info" onClick={() => {
      console.log("Tabby button clicked");
      tabbyButton();
    }}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
    </IconButton>
    <Button style={{ padding: "10px" }} onClick={handlePayment} disabled={subscribePayIsLoading}>{t("Pay With Tabby")}</Button>

    <div id="tabbyCard" />

  </div>
  ;
};

export default TabbyPayment;
