import { AccountLayout } from "../../layouts/AccountLayout";
import { useEffect, useState, useMemo } from "react";
import { ImDrawer2 } from "react-icons/im";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import {
  useGetUserAddressesMutation,
  useDeleteUserAddressMutation
} from "../../store/services/smApi";
import { useHistory } from "react-router-dom";
import { setEditableAddress } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import countryList from "react-select-country-list";
import { FaPlus } from "react-icons/fa";
import LoadingSpinner from "../../components/LoadingSpinner";
export default function MyAddresses () {
  const options = useMemo(() => countryList().getData(), []);
  const history = useHistory();
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState([]);
  const { t } = useTranslation(["translation"]);
  const [getUserAddresses, { isLoading }] = useGetUserAddressesMutation();
  const [deleteUserAddress] = useDeleteUserAddressMutation();

  const deleteAddress = async (addressId) => {
    await deleteUserAddress({ id: addressId });
    fetchAddress();
  };

  const fetchAddress = async () => {
    const result = await getUserAddresses();
    if (result && result.data) {
      setAddresses(result.data);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);
  return (
    <AccountLayout>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="py-2 mb-0">{t("Addresses")}</h2>
        <button onClick={() => {
          dispatch(setEditableAddress(null));
          history.push("/account/profile/address_form");
        }} className="btn btn-outline-primary w-auto">
          <FaPlus className="me-1" size={12} />
          {t("Add address")}
        </button>
      </div>
      <hr className="mb-0" />
      {addresses.length
        ? addresses.map((address) => {
          return (
            <div key={address.id} className="address-container py-3 mt-3">
              <div className="d-flex justify-content-start align-items-center px-3 mx-1 py-1">
                <h2 className="m-0 me-1">{t("Address")} #{address.id}</h2>
                {address.is_default &&
                  <span className="badge rounded-pill rounded ms-2">{("Default Address")}</span>
                }
                <HiOutlinePencilSquare onClick={() => {
                  dispatch(setEditableAddress(address));
                  history.push("/account/profile/address_form");
                }} role="button" className="ms-auto" size={24} />
                <RiDeleteBin5Line onClick={() => deleteAddress(address.id)} role="button" className="ps-1 ms-2" size={24}/>
              </div>
              <hr className="mt-3 mb-0" />
              <div className="row mx-1">
                {Object.keys(address).map((key) => {
                  if (["is_default", "id"].includes(key)) return null;
                  return (
                    <div key={address.id + address[key]} className="col-sm-6 col-lg-4 col-xl-3 pt-3 px-3">
                      <p className="key mb-1">{t(key)}</p>
                      <p className="value m-0">{key === "country" ? options.find(co => co.value === address[key])?.label : address[key]}</p>
                    </div>

                  );
                })}

              </div>
            </div>
          );
        })
        : <div className="empty-address d-flex justify-content-center align-items-center">
          <div className="position-relative text-center">
            {isLoading
              ? <LoadingSpinner />
              : <>
                <ImDrawer2 className="m-b1" size={40} />
                <h3 className="my-2">{t("There is not any address currently added")}</h3>
                <p>{t("Please add new address by clicking on Add address button")}</p>
              </>
          }
          </div>
        </div>
      }
    </AccountLayout>
  );
}
