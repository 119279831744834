import { useState, useCallback, useEffect } from "react";

const storageName = "data";

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [ready, setReady] = useState(false);

  const login = useCallback((jwtToken, user) => {
    setReady(false);
    setToken(jwtToken);
    setUser(user);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        user,
        token: jwtToken
      })
    );

    setReady(true);
  }, []);

  const logout = useCallback(() => {
    console.log("logout hook");
    setToken(null);
    setUser(null);
    localStorage.removeItem(storageName);
  }, []);

  const isAuthenticated = !!token;

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName));

    if (data && data.token) {
      login(data.token, data.user);
    }

    setReady(true);
  }, [login]);

  return { login, logout, token, user, ready, isAuthenticated };
};
