// get icon depending on payment method key (e.g. "Visa", "Mastercard", "Mada".)
import { ReactComponent as VisaLogo } from "../assets/images/paymantBrands/visa.svg";
import { ReactComponent as MasterCardLogo } from "../assets/images/paymantBrands/mastercard.svg";
import { ReactComponent as MadaLogo } from "../assets/images/paymantBrands/mada.svg";

export default function PaymentIcon (props) {
  const { paymentMethod } = props;
  return (<div className={"me-2"} style={{ width: "100px" }}>
    { paymentMethod === "Visa" && <VisaLogo/> }
    { paymentMethod === "Mastercard" && <MasterCardLogo/> }
    { paymentMethod === "Mada" && <MadaLogo/> }
  </div>
  );
}
