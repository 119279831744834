import { MainLayout } from "../../layouts/MainLayout";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";
import {
  useFetchUserMutation,
  useRequestCodeMutation,
  useVerifyCodeMutation
} from "../../store/services/authApi";
import { confirmSchema, otpLoginSchema } from "../../utils/ValidationSchemas";
import { useLastCodeRequest } from "../../store/slices/userSlice";
export default function LoginOtp (props) {
  const { t } = useTranslation(["translation"]);
  const [successMessage, setSuccessMessage] = useState(
    props.successMessage || false
  );
  const [requestCode, { isLoading: isLoadingRequestCode }] =
    useRequestCodeMutation();
  const [verifyCode, { isLoading: isLoadingVerifyRequestCode }] =
    useVerifyCodeMutation();
  const [fetchUser] = useFetchUserMutation();
  const [otpCounter, setOtpCounter] = useState(0);
  const { last_code_request } = useLastCodeRequest();
  const [email, setEmail] = useState(props.email || "");
  const history = useHistory();
  const handleSubmitEmail = async (values, { setErrors, setStatus }) => {
    let resp;
    setEmail(values.email);
    try {
      resp = await requestCode({ email: values.email });
      console.log("resp", resp);
      if (resp && resp.error) {
        setErrors(resp.error.data);
        return null;
      } else if (resp && resp.data && resp.data.msg) {
        setSuccessMessage(`${t("The authorisation code has been sent to")} ${values.email}`);
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  useEffect(() => {
    const check = Math.floor(Date.now() / 1000) - last_code_request;
    setOtpCounter(check);
    const otpInterval = setInterval(() => {
      setOtpCounter((otpCounter) => otpCounter + 1);
    }, 1000);

    return () => clearInterval(otpInterval);
  }, [last_code_request]);
  const handleSubmitCode = async (values, { setErrors, setStatus }) => {
    console.log("handleSubmitCode");
    let resp;
    try {
      resp = await verifyCode({ code: values.code });
      console.log("handleSubmitCode resp", resp);
      if (resp && resp.errors) {
        setErrors(resp.errors);
        return null;
      } else if (resp && resp.data && resp.data.access) {
        const userResponse = await fetchUser(resp.data.access);
        if (userResponse) await history.push("/");
      } else {
        setStatus(t("Something went wrong, try again later"));
      }
    } catch (error) {
      console.error(error);
      setStatus(t("Something went wrong, try again later"));
      return null;
    }
  };

  return (
    <MainLayout>
      <div>
        <Container className={"py-4 my-4"}>
          <div className="text-center my-4 py-4">
            <h1 className="text-primary">{t("Login")}</h1>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-5">
              {!successMessage && (
                <Formik
                  validationSchema={otpLoginSchema}
                  // onSubmit={(e) => {e.preventDefault();}}
                  onSubmit={handleSubmitEmail}
                  validateOnChange
                  initialValues={{
                    email: ""
                  }}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur, // handler for onBlur event of form elements
                    handleFocus, // handler for onBlur event of form elements
                    isValid,
                    handleSubmit,
                    dirty,
                    status
                  }) => (
                    <Form
                      className="form rounded-3 shadow bg-white p-3 p-sm-4"
                      onSubmit={handleSubmit}
                    >
                      {status && (
                        <div className="error-msg error mt-4">{status}</div>
                      )}
                      <div className="mb-3 position-relative">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          {t("Email address")}
                        </label>
                        <div className="position-relative">
                          <span className="btn position-absolute start-0 text-muted px-0">
                            <i className="far fa-envelope"></i>
                          </span>
                          <Field
                            name="email"
                            className={`form-control ps-5 ${
                              errors.email && touched.email ? " is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            onFocus={handleFocus}
                          />
                          {errors.email && touched.email
                            ? (
                              <div className="invalid-feedback start-0 top-100">
                                {errors.email}
                              </div>
                              )
                            : null}
                        </div>
                      </div>

                      <div className="text-center pt-4">
                        <button
                          type="submit"
                          disabled={
                            !isValid ||
                            !dirty ||
                            isLoadingRequestCode ||
                            otpCounter < 60
                          }
                          className="btn btn-lg w-100 btn-primary"
                        >
                          {isLoadingRequestCode && <ButtonLoadingSpinner />}{" "}
                          {otpCounter < 60 ? 60 - otpCounter : t("Login")}
                        </button>

                        <div className="py-3">
                          <span className="form-text me-2">
                            {t("Don't have a account?")}
                          </span>
                          <a href="/registration" className="text-primary link">
                            {t("sign up now")}
                          </a>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {successMessage && (
                <>
                  <p>{successMessage}</p>
                  <Formik
                    validationSchema={confirmSchema}
                    onSubmit={handleSubmitCode}
                    validateOnChange
                    initialValues={{
                      email: ""
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur, // handler for onBlur event of form elements
                      handleFocus, // handler for onBlur event of form elements
                      isValid,
                      handleSubmit,
                      dirty,
                      status
                    }) => (
                      <Form
                        className="form rounded-3 shadow bg-white p-3 p-sm-4"
                        onSubmit={handleSubmit}
                      >
                        <div className="error-msg error mt-4">
                          {t("Resent OTP") + " "}
                          {otpCounter < 60
                            ? (
                              <span style={{ color: "#FFBB2D" }}>
                                {(60 - otpCounter) + t("Seconds")}
                              </span>
                              )
                            : (
                              <span
                              style={{ color: "#FFBB2D", cursor: "pointer" }}
                              onClick={() => {
                                handleSubmitEmail(
                                  { email },
                                  { errors, status }
                                );
                              }}
                            >
                                {isLoadingRequestCode ? <ButtonLoadingSpinner /> : t("Resend")}
                              </span>
                              )}
                        </div>
                        {status && (
                          <div className="error-msg error mt-4">{status}</div>
                        )}
                        <div className="mb-3 position-relative">
                          {/* <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            {t("Code from email")}
                          </label> */}
                          <div className="position-relative">
                            <Field
                              name="code"
                              className={`form-control ${
                                errors.code && touched.code ? " is-invalid" : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.code}
                              onFocus={handleFocus}
                            />
                            {errors.code && touched.code
                              ? (
                                <div className="invalid-feedback start-0 top-100">
                                  {errors.code}
                                </div>
                                )
                              : null}
                          </div>
                        </div>

                        <div className="text-center pt-4">
                          <button
                            type="submit"
                            disabled={
                              !isValid || !dirty || isLoadingVerifyRequestCode
                            }
                            className="btn btn-lg w-100 btn-primary"
                          >
                            {isLoadingVerifyRequestCode && (
                              <ButtonLoadingSpinner />
                            )}{" "}
                            {t("Login")} {t("Confirm")}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
}
