import { MainLayout } from "../../layouts/MainLayout";
import { Container } from "react-bootstrap";
import { useAuth } from "../../hooks/auth.hook";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../hooks/http.hook";
import { registrationSchema } from "../../utils/ValidationSchemas";
import LoginOtp from "../LoginOtp/LoginOtp";
import { useDispatch } from "react-redux";
import { lastCodeRequest } from "../../store/services/authActions";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";

export default function Registration (props) {
  const { loading, isAuthenticated } = useAuth();
  const { t } = useTranslation(["translation"]);
  const [serverError, setServerError] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const history = useHistory();
  const [isLoadingReg, setIsLoadingReg] = useState(false);
  const { request } = useHttp();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    let registrationSuccess = false;
    setIsLoadingReg(true);
    try {
      setEmail(values.email);
      await request("signup/", "POST", values)
        .then((result) => {
          console.log("result", result);
          if (result && result.errors) {
            setErrors(result.errors);
          } else {
            registrationSuccess = true;
          }
          // registrationSuccess = true;
        })
        .catch((e) => {
          console.log(JSON.parse(e.message));
          setErrors(JSON.parse(e.message));
          console.log(e);
          registrationSuccess = false;
        });
    } catch (e) {
      console.warn(e);
      setServerError(JSON.stringify(e));
    }
    if (registrationSuccess) {
      //   console.log("push");
      //   props.history.push("/registration/success");
      dispatch(lastCodeRequest(Math.floor(Date.now() / 1000)));
      setSuccessMessage(true);
    }
    setIsLoadingReg(false);
  };
  useEffect(() => {
    if (!loading && isAuthenticated) history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      {!successMessage && (
        <MainLayout>

          <div>
            <Container className={"py-4 my-4"}>
              <div className="text-primary text-center my-4 py-4">
                <h1>{t("Registration")}</h1>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <Formik
                    validationSchema={registrationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange
                    initialValues={{
                      name: "",
                      email: "",
                      terms: false
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur, // handler for onBlur event of form elements
                      isValid,
                      dirty
                    }) => (
                      <Form className="form border p-3 p-sm-4">
                        <label
                          htmlFor="exampleInputName2"
                          className="form-label"
                        >
                          {t("Full Name")}
                        </label>
                        <div className="mb-3 position-relative">
                          <div className="mb-4 position-relative">
                            <span className="btn position-absolute start-0 text-muted px-0">
                              <i className="far fa-user"></i>
                            </span>
                            <Field
                              name="name"
                              className={`form-control ps-5 ${
                                errors.name && touched.name ? " is-invalid" : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            {errors.name && touched.name
                              ? (
                                <div className="invalid-feedback start-0 top-100">
                                  {errors.name}
                                </div>
                                )
                              : null}
                          </div>
                        </div>

                        <div className="mb-4 position-relative">
                          <label
                            htmlFor="exampleInputEmail2"
                            className="form-label"
                          >
                            {t("Email address")}
                          </label>
                          <div className="position-relative">
                            <span className="btn position-absolute start-0 text-muted px-0">
                              <i className="far fa-envelope"></i>
                            </span>
                            <Field
                              name="email"
                              className={`form-control ps-5 ${
                                errors.email && touched.email
                                  ? " is-invalid"
                                  : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            {errors.email && touched.email
                              ? (
                                <div className="invalid-feedback start-0 top-100">
                                  {errors.email}
                                </div>
                                )
                              : null}
                          </div>
                        </div>

                        <div className="text-center pt-4">
                          <div className="mb-4 form-check text-start">
                            <Field
                              type="checkbox"
                              name="terms"
                              id="exampleCheck2"
                              className="form-check-input flex-shrink-0"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label text-muted fsz-md"
                              htmlFor="exampleCheck2"
                            >
                              I accept to{" "}
                              <button onClick={() => {

                              }} className="text-reset">
                                {t("Terms and Conditions")}
                              </button>{" "}
                              of and{" "}
                              <a onClick={() => { } } className="text-reset">
                                {t("Privacy Policy")}
                              </a>
                            </label>
                          </div>
                          {serverError
                            ? (
                              <div className="invalid-feedback start-0 top-100">
                                {serverError}
                              </div>
                              )
                            : null}
                          {isLoadingReg
                            ? <ButtonLoadingSpinner />
                            : <button
                            type="submit"
                            disabled={!isValid || !dirty}
                            className="btn btn-lg w-100 btn-primary"
                          >
                              {t("Registration")}
                            </button>
                        }
                          <div className="py-3">
                            <span className="form-text me-2">
                              {t("Already have an account?")}
                            </span>
                            <a href="/login" className="text-primary link">
                              {" "}
                              {t("Login")}
                            </a>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Container>
          </div>
        </MainLayout>
      )}
      {successMessage && (
        <LoginOtp successMessage={successMessage} email={email} />
      )}
    </>
  );
}
