import { Container } from "react-bootstrap";
import PlansBlockEmbedded from "../../components/PlansBlockEmbedded";
import { useTranslation } from "react-i18next";

export default function PlansEmbedded (props) {
  const { t } = useTranslation(["translation"]);

  return (
    <div>
      <Container>
        <div className="text-center my-4 py-4">
          <h1>
            {t("Plans")}
          </h1>
          <h6>
            {t("Lorem ipsum dolor")}
          </h6>
        </div>
        <PlansBlockEmbedded subscription={props.location.state.subscription}/>
      </Container>
    </div>
  );
}
