import React from "react";
import { useTranslation } from "react-i18next";
import { PAYMENT_REQUEST_STATUS } from "../utils/beDicts";
import { NavLink } from "react-router-dom";

const PaymentRequestSection = ({ paymentRequest, style = undefined, ...props }) => {
  const { t } = useTranslation();

  const hasPendingTry = () => {
    for (const payment_try of paymentRequest.payment_tries) {
      if (payment_try.status === PAYMENT_REQUEST_STATUS.PENDING) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={`${style ?? "w-100"}`}>
      <div className="subscription-details-card p-4">
        <p>{t("Payment Request Status")} </p>
        <p className="p">{`${t("Payment Status")}`} {t(paymentRequest.status)}</p>
        {
              !hasPendingTry()
                ? (
                  <div className="flex items-center">
                    <NavLink
                        to={`/account/profile/payment_requests/${paymentRequest.id}/try/new`}
                        className="btn btn-primary w-100">
                      {t("Try Payment")}
                    </NavLink>

                  </div>
                  )
                : t("There is pending payment try . You cannot try payment now")
          }
        {props.children}
      </div>
    </div>
  );
};
export default PaymentRequestSection;
