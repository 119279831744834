import { MainLayout } from "../../layouts/MainLayout";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PlansBlock from "../../components/PlansBlock";
import FAQsBlock from "../../components/FAQs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function Home (props) {
  const merchant = useSelector((state) => state.configStore.merchant);
  const subscriptions = useSelector((state) => state.configStore.subscriptions);
  const { t } = useTranslation(["translation"]);

  return (
    <MainLayout>
      {merchant.banner && <div className={"banner-block mt-3"}>
        <Image src={merchant.banner} alt={"banner"} className={""}/>
      </div>}
      <div>
        <Container>
          <div className="text-center my-4 py-4">
            {merchant.home_description ?? ""}
          </div>
          <Row className="justify-content-center pb-4">
            {(subscriptions.length > 1) && subscriptions.map((subscription, subscriptionIndex) => {
              return (
                <Col className="col-8 col-md-4 mb-4" key={subscriptionIndex}>

                  <Card className="card-hover h-100 text-center  ui-card-outer">
                    {subscription.is_subscribed
                      ? <div className="close p-3"><FontAwesomeIcon icon={faCheck} color={"green"} /></div>
                      : null}
                    <Card.Img src={subscription.image}></Card.Img>

                    <Card.Body>
                      <Card.Header className="py-3">
                        <h5 className="mb-0">
                          {subscription.title}
                        </h5>
                      </Card.Header>
                      <Card.Text className="text-secondary">
                        {subscription.header}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="card-footer border-0  ">
                      <NavLink to={{
                        pathname: `/subscriptions/${subscription.id}/plans`,
                        state: { subscription }
                      }}
                      className={"btn btn-lg w-100 btn-primary"} variant="primary">{t("Plans")}</NavLink>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            }
            )}
          </Row>
          {(subscriptions.length === 1) && (
            <>

              <PlansBlock subscription={subscriptions[0]} location={props.location}/>
              {subscriptions[0].faqs && subscriptions[0].faqs.length > 0 && <div className="text-center my-4 py-4   d-flex justify-content-center flex-column">
                <h2 className="plans-title-text">{t("faqs")}</h2>
                <div className="row">
                  <FAQsBlock faqs={subscriptions[0].faqs} />
                </div>
              </div>} </>

          )}

        </Container>
      </div>
    </MainLayout>
  );
}
