import { Card, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth.hook";
import PlansBlock from "../../components/PlansBlock";
import "../../styles/globals.css";
import { useTranslation } from "react-i18next";

export default function Embedded () {
  const [subscriptions, setSubscriptions] = useState([]);
  const { request } = useAuth();
  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    request("subscriptions/").then((result) => {
      setSubscriptions(result);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    <Row className={"py-4 my-4 row row-cols-1 row-cols-md-3 g-4"}>
      {subscriptions.length > 1 && subscriptions.map((subscription, subscriptionIndex) => {
			  return (
  <Col className={"col-8 col-md-4 mb-4"} key={subscriptionIndex}>
    <Card className={"h-100 text-center"}>
      <Card.Header className={"bg-primary text-white py-3"}>
        <h5 className="mb-0">
          {subscription.title}
        </h5>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          {subscription.header}
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <NavLink to={{ pathname: `/embedded/subscriptions/${subscription.id}/plans`, state: { subscription } }}
									className={"btn btn-lg w-100 btn-primary"} variant="primary">{t("Plans")}</NavLink>
      </Card.Footer>
    </Card>
  </Col>
			  );
      }
      )}
      {subscriptions.length === 1 && (<PlansBlock subscription={subscriptions[0]} />)}
    </Row>
  </>
  );
}
