import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { AccountLayout } from "../../../layouts/AccountLayout";
import { useRetrievePaymentRequestTryMutation, useCreatePaymentRequestTryMutation } from "../../../store/services/smApi";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { prepare_errors } from "../../../utils/formatError";
import formatDateTime from "../../../utils/formatDateTime";
import { Row, Col, Card } from "react-bootstrap";
import { PAYMENT_TRY_STATUS } from "../../../utils/beDicts";

const PaymentRequestTry = (props) => {
  const { t } = useTranslation();
  const { tryId } = useParams();
  const [retrievePaymentRequestTry, { isLoading: isLoadingPaymentRequestTry }] = useRetrievePaymentRequestTryMutation();
  const isCreate = tryId === "new";

  const [paymentTry, setPaymentTry] = useState({});
  const getPaymentTry = async () => {
    const result = await retrievePaymentRequestTry(tryId);
    if (result && result.data) {
      setPaymentTry(result.data);
    }
  };
  useEffect(() => {
    if (isCreate) {
      return;
    }
    getPaymentTry();
  }, []);

  return (
    <AccountLayout>
      <ToastContainer />
      <h2 className="py-2 mb-0">{isCreate ? t("New Payment Try") : t("View Payment Try")}  </h2>
      {
        isCreate ? <CreatePaymentRequestTry /> : <ViewPaymentRequestTry paymentTry={paymentTry} isLoading={isLoadingPaymentRequestTry} />
      }
    </AccountLayout>
  );
};
export default PaymentRequestTry;
const ViewPaymentRequestTry = ({ paymentTry, ...props }) => {
  const { t } = useTranslation();
  if (!paymentTry) return <></>;

  return (
    <div className="row row-cols-auto justify-content-between">
      <div className="col-12 col-lg-8">
        <h3 className="py-2 mb-0">{paymentTry.status === PAYMENT_TRY_STATUS.REJECTED && `${t("Rejection Information")}`} {t("Payment Try")}</h3>
        {/* status */}
        <p className="card-info p"><span className="bold"> {t("Status")}: </span> <span
              className="me-2">{paymentTry.status}</span></p>
        <p className="card-info p"><span className="bold">{t("Created At")}:</span> <span
              className="me-2">{formatDateTime(paymentTry.created_at)}</span></p>

        {
              paymentTry.status === PAYMENT_TRY_STATUS.REJECTED && (<>
                <p className="card-info p"><span className="bold">{t("Rejection Time")}:</span><span
                      className="me-2">{formatDateTime(paymentTry.updated_at)}</span></p>

                <p className="card-info p"><span className="bold">{t("Rejection Reason")}:</span>
                  <span className="me-2">{paymentTry.rejection_reason}</span></p>

                <p className="card-info p"><span className="bold">{t("Extra Notes")}:</span>
                  <span className="me-2">{paymentTry.rejection_notes}</span></p>

              </>
              )

        }

        {/*  User Payment Request */
        }
        <h3 className="py-2 mb-0">{`${t("Payment Request Information")}`} {t("Payment Try")}</h3>
        <p className="card-info p"><span className="bold">{t("Notes")}:</span> <span
      className="me-2">{paymentTry.user_notes}</span></p>
        {
            paymentTry.attachments && paymentTry.attachments.length > 0 && (
              <Row>
                {
                  paymentTry.attachments.map((attachment, index) => (
                    <Col key={index} xs={12} md={6} lg={4} className="mb-3">
                      <Card border="primary" style={{ width: "100%" }}>
                        <Card.Img variant="top" src={attachment.file} />
                      </Card>

                    </Col>
                  ))
                }
              </Row>
            )

        }

      </div>

    </div>
  );
};

const CreatePaymentRequestTry = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [attachments, setAttachments] = useState([null]);
  const [attachmentsImages, setAttachmentsImages] = useState([null]);
  const [createPaymentRequestTry, { isLoading: isCreatingPaymentRequestTry }] = useCreatePaymentRequestTryMutation();
  const { paymentRequestId } = useParams();
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (event, index) => {
    if (event.target.files.length === 0) return;
    const newAttachments = [...attachments];
    const newAttachmentsImages = [...attachmentsImages];
    if (index < newAttachments.length) {
      newAttachments[index] = event.target.files[0]; // Update specific attachment
      newAttachmentsImages[index] = await getBase64(event.target.files[0]);
    } else {
      newAttachments.push(event.target.files[0]); // Add new attachment
      newAttachmentsImages.push(await getBase64(event.target.files[0]));
    }
    setAttachments(newAttachments);
    setAttachmentsImages(newAttachmentsImages);
  };

  const validationSchema = yup.object({
    user_notes: yup.string().optional(),
    attachments: yup.array().min(1, "At least one attachment is required")
  });

  const handleAddFile = () => {
    setAttachments([...attachments, null]); // Add empty placeholder for new file input
  };
  const handleRemoveAttachment = (index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };
  const handleSubmit = async (values) => {
    const _values = { ...values };
    _values.attachments = [];
    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i]) {
        _values.attachments.push({ file: attachmentsImages[i], name: attachments[i].name });
      }
    }

    _values.payment_request = paymentRequestId;
    const result = await createPaymentRequestTry(_values);
    console.log("result", result);
    if (result && result.data) {
      history.push(`/account/profile/payment_requests/${paymentRequestId}`);
      toast.success(t("Payment Request Try created successfully"));
    } else {
      toast.error(`Something went wrong ${prepare_errors(result.error?.data || result.errors?.data)}`);
    }
  };
  return (
    <div>
      {isCreatingPaymentRequestTry && <LoadingSpinner/>}
      <Formik initialValues={{ user_notes: "", attachments: [null] }}
              validationSchema={validationSchema} onSubmit={handleSubmit} >
        {({
          handleChange,
          handleBlur,
          values,
          errors,
          touched
        }) => (
          <Form >
            <div className="mb-3">
              <label htmlFor="user_notes" className="form-label">User Notes</label>
              <textarea className="form-control w-100 " id="user_notes" name="user_notes" onChange={handleChange} onBlur={handleBlur} value={values.user_notes}/>
            </div>
            {attachments.length > 0
              ? attachments.map((attachment, index) => (
                <AttachmentInput key={index} handleFileChange={handleFileChange} handleRemoveAttachment={handleRemoveAttachment} index={index} />

              ))
              : <AttachmentInput key={0} handleFileChange={handleFileChange}
                                 handleRemoveAttachment={handleRemoveAttachment} index={0} />}
            <div className={"d-flex flex-row justify-content-end"}>
              <IconButton
              onClick={handleAddFile}
              className=" "
            >
                <FontAwesomeIcon icon={faPlusCircle} />
              </IconButton>
            </div>

            <button disabled={isCreatingPaymentRequestTry} type="submit" className="btn  btn-primary w-auto">{t("Confirm Payment")}</button>
          </Form>
        )}
      </Formik>
    </div>

  );
};

const AttachmentInput = ({ handleFileChange, handleRemoveAttachment, index, ...props }) => {
  const { t } = useTranslation();
  return <div key={index} className="mb-3  ">
    <label htmlFor={`attachments_${index}`} className="form-label mr-2 mb-3">
      Attachment {index + 1} {index === 0 && t("(at least one required)")}
    </label>
    <Field
        type="file"
        className="form-control  mb-3"
        id={`attachments_${index}`}
        name={`attachments[${index}]`}
        onChange={(e) => handleFileChange(e, index)}
        multiple
    />
    {index > 0 && ( // Only show remove button for attachments except the first one
      <IconButton
            onClick={() => handleRemoveAttachment(index)}
            className="ml-2 btn-danger"
        >
        <FontAwesomeIcon icon={faMinusCircle}/>
      </IconButton>

    )}
    <ErrorMessage name={`attachments[${index}]`} component="div" className="error ml-2"/>
  </div>;
};
