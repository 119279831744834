import React, { useEffect, useState } from "react";
import { useGetOfflinePaymentOptionsMutation } from "../../../store/services/smApi";
import { MainLayout } from "../../../layouts/MainLayout";
import EmptyData from "../../../components/Table/Empty";
import BaseTable from "../../../components/Table/Table";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

const OfflinePaymentOptionsList = () => {
  const { t } = useTranslation();
  const [offlinePaymentOptions, setOfflinePaymentOptions] = useState([]);
  const [getOfflinePaymentOptionsMutation, { isLoading }] = useGetOfflinePaymentOptionsMutation();
  const getOfflinePaymentOptions = async () => {
    const response = await getOfflinePaymentOptionsMutation();
    setOfflinePaymentOptions(response.data);
  };
  useEffect(() => {
    getOfflinePaymentOptions();
  }, []);

  return (
    <MainLayout>
      <Container className="py-4">
        <div className="row py-4 my-4">
          <h3 className="my-2 py-2 mb-0">{t("Offline Payment Options")}</h3>

          <BaseTable columns={[{
            title: t("Name"),
            dataIndex: "name",
            key: "name",
            render: (text, record) => {
              return (
                <span className="text-base font-medium text-neutral-600 leading-6">
                  {record?.name}
                </span>
              );
            }
          },
          {
            title: t("Account Number"),
            dataIndex: "account_number",
            key: "account_number",
            render: (text, record) => {
              return (
                <span className="text-base font-medium text-neutral-600 leading-6">
                  {record?.account_number}
                </span>
              );
            }
          },
          {
            title: t("Account Name"),
            dataIndex: "account_name",
            key: "account_name",
            render: (text, record) => {
              return (
                <span className="text-base font-medium text-neutral-600 leading-6">
                  {record?.account_name}
                </span>
              );
            }
          },
          {
            title: t("Details"),
            dataIndex: "details",
            key: "details",
            render: (text, record) => {
              return (
                <span className="text-base font-medium text-neutral-600 leading-6">
                  {record?.details}
                </span>
              );
            }
          }

          ]}
          isLoading={isLoading} data={offlinePaymentOptions} indexed={true}
          noDataWidget={<EmptyData title={t("There is no Offline Payment Option")}
           hint={t("Offline Payment Option will appear her .")}/>}/>
        </div>
      </Container>

    </MainLayout>
  );
};
export default OfflinePaymentOptionsList;
