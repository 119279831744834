const formatPeriod = (recurrenceUnit, recurrencePeriod, translate, slash = true) => {
  const conversion = {
    0: "one-time",
    1: { singular: "per second", plural: "seconds" },
    2: { singular: "per minute", plural: "minutes" },
    3: { singular: "per hour", plural: "hours" },
    4: { singular: "per day", plural: "days" },
    5: { singular: "per week", plural: "weeks" },
    6: { singular: "per month", plural: "months" },
    7: { singular: "per year", plural: "years" }
  };

  if (recurrenceUnit === 0) { return translate(conversion[0]); }

  if (recurrencePeriod === 1) { return translate(conversion[recurrenceUnit].singular); }

  return `${slash ? "/" : ""} ${recurrencePeriod} ${translate(conversion[recurrenceUnit].plural)}`;
};

export default formatPeriod;
