import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Image, Button, Form, FormGroup, Collapse, Row, Container } from "react-bootstrap";
import { useFetchUpgradePromotionMutation } from "../../store/services/smApi";
import { PlanHtml } from "../../components/PlansBlock";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import formatPeriod from "../../utils/formatPeriod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function PlanSelect ({ changeStep, nextStep, selectedUpgradeCost, setSelectedUpgradeCost, setUpgradeCost }) {
  const { t } = useTranslation(["translation"]);
  const [fetchUpgradePromotion, { isLoading: fetchingUpgradePromotion }] = useFetchUpgradePromotionMutation();
  const [promotion, setPromotion] = useState({});
  const [collapsed, setCollapsed] = useState({});
  const { userSubscriptionId } = useParams();

  const getPromotion = async () => {
    try {
      const res = await fetchUpgradePromotion({ id: userSubscriptionId });
      if (res.data) {
        setPromotion(res.data);
        let upgradeCosts = {};
        res.data.plans.forEach((plan) => {
          upgradeCosts = {
            ...upgradeCosts,
            [plan.id]: res.data.upgrade_promotion_plan_costs.find((cost) => cost.plan_cost === plan.costs[0].id)?.id
          };
        });
        setSelectedUpgradeCost(upgradeCosts);
      } else if (res.error?.data) {
        Object.entries(res.error.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value[0] : value}`));
      } else {
        throw new Error("Something went wrong");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    getPromotion();
  }, []);

  return (
    <Container>
      {
            fetchingUpgradePromotion
              ? <div className="text-center my-4 py-4">
                <LoadingSpinner/>
              </div>
              : (
                <>
                  <div className="text-center my-4 py-4">
                    <h2>
                      {t("Choose the plan to upgrade")}
                    </h2>
                  </div>
                  <Row className="justify-content-center pb-4 block-plans">
                    {
                promotion.plans && promotion.plans.map((plan, planIndex) => (

                  <Col className=" col-10 col-md-8 col-lg-4 mb-4" key={planIndex}>
                    <Card className="plan-card transition-card text-center h-100 d-flex ">
                      <Card.Header className="mb-24 flex-grow-1" style={{ minHeight: "300px", maxHeight: "400px" }}>
                        {plan.image &&
                          <Image className="img-fluid plan_img w-100" src={plan.image}/>
                            }
                      </Card.Header>
                      <Card.Header className="mb-24  ">
                        <p className="mb-16 p-0 plan-card-title text-start">
                          {plan.name}
                        </p>
                        {plan.html_content?.length >= 255
                          ? <Collapse in={collapsed[plan.id]} collapsedSize={200}>
                            <PlanHtml plan={plan} collapsed={collapsed} setCollapsed={setCollapsed}/>
                          </Collapse>
                          : <PlanHtml plan={plan} collapsed={collapsed} setCollapsed={setCollapsed}/>
                            }

                      </Card.Header>

                      <Card.Body className="pd-0  mb-48 d-flex flex-column">

                        <div className="justify-content-center mb-24  flex-grow-1">
                          <div className="col-sm-12">
                            <div
                                    className="plan-card-price">
                              <FormGroup>
                                {plan.costs.length >= 1 && plan.costs.map((cost, costIndex) => {
                                  return <Form.Check key={cost.id}
                                                            className="d-flex align-items-center plan-card-cost "
                                                            type="radio"
                                                            id={`plan_cost_${cost.id}`}
                                                            name={`plan_${plan.id}`}
                                                            value={cost.id}>
                                    <Form.Check.Input className="ml-6 flex-shrink-0 mt-0 icon"
                                                            type={"radio"}
                                                            name={`plan_${plan.id}`}
                                                            defaultChecked={costIndex === 0}
                                                            onChange={(e) => setSelectedUpgradeCost({
                                                              ...selectedUpgradeCost,
                                                              [plan.id]: promotion.upgrade_promotion_plan_costs.find((cost) => cost.plan_cost === e.target.value)?.id
                                                            })}
                                                            value={cost.id}/>

                                    <Form.Check.Label className="label-class">
                                      <div className="d-flex flex-column justify-content-center align-items-start gap-1 py-1">
                                        <p className="plan-card-price-text lh-sm">
                                          {parseFloat(cost.full_vat_data.total_after_vat).toFixed(2)} {t(cost.display_currency_code)}
                                          <span
                                                    className="text-muted d-inline-block ms-1 plan-card-price-text-span">{formatPeriod(cost.recurrence_unit, cost.recurrence_period, t)}</span>
                                        </p>
                                        {
                                            cost.setup_fee > 0 &&
                                              <span
                                            className="text-muted d-block lh-sm small fw-bold">
                                                {`( + ${parseFloat(cost.setup_fee_with_vat).toFixed(2)} ${t(cost.display_currency_code)} ${t("Setup Fee")})`}
                                              </span>

                                            }
                                      </div>

                                    </Form.Check.Label>

                                  </Form.Check>;
                                }
                                )
                                    }
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        {
                                plan.plan_options && plan.plan_options.length > 0 && <Row className=" mb-24 flex-grow-1 ">
                                  <Col><p className="plan-card-title fs-20 text-start mt-1">{t("plan options")}</p>
                                    <div className={"d-flex justify-content-start features-block costs-block"}>

                                      <FormGroup>

                                        {plan.plan_options && plan.plan_options.map(({
                                          id,
                                          name,
                                          price,
                                          full_vat_data,
                                          recurrence_type
                                        }) => {
                                          return <p className="plan-card-price-text" key={id}>
                                            <span
                                                className=""> {name} </span>

                                            <span
                                                className="">  {full_vat_data.total_after_vat}</span>
                                            <span className="mx-1">{`(${t(recurrence_type)})`}</span>

                                          </p>;
                                        }
                                        )
                                        }
                                      </FormGroup>
                                    </div>
                                  </Col>
                                </Row>

                            }

                        <div className="flex-grow-1">

                          {plan.plan_features?.length > 0 && <>
                            <p className="plan-card-title fs-20 text-start mt-1">{t("plan features")}</p>
                            <Row><Col>
                              <div className={"d-flex justify-content-start features-block"}>
                                <ul className="list-unstyled text-muted lh-lg mb-0">
                                  {plan.plan_features && plan.plan_features.map((plan_feature, planFIndex) => (
                                    <li key={plan.id + "_plan_feature_" + planFIndex}
                                        className={"d-flex align-items-center mb-16"}>
                                      <FontAwesomeIcon icon={faCheckCircle} className="ml-8 text-primary"/>
                                      {plan_feature.actual_value && <span
                                            className="feature-plan-text"> {plan_feature.name}: {plan_feature.actual_value} </span>}
                                      {!plan_feature.actual_value &&
                                        <span className="feature-plan-text"> {plan_feature.name}</span>}
                                    </li>))
                                        }
                                </ul>
                              </div>
                            </Col></Row></>
                                }
                        </div>
                      </Card.Body>
                      <Card.Footer className="border-0 d-grid gap-2 ">
                        <Button
                        onClick={() => {
                          setUpgradeCost(selectedUpgradeCost[plan.id]);
                          changeStep(nextStep);
                        }}
                         className="p-0 width-none btn">{t("Upgrade")}</Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                )
                )
            }
                  </Row>
                </>
                )
        }
    </Container>
  );
}
