import React, { useEffect, useState } from "react";
import { AccountLayout } from "../../../layouts/AccountLayout";
import { useTranslation } from "react-i18next";
import { useFetchPaymentRequestsMutation } from "../../../store/services/smApi";
import BaseTable from "../../../components/Table/Table";
import EmptyData from "../../../components/Table/Empty";
import { NavLink } from "react-router-dom";
import formatDateTime from "../../../utils/formatDateTime";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PaymentRequests = () => {
  const { t } = useTranslation();
  const [fetchPaymentRequests, { isLoading: isLoadingPaymentRequests }] = useFetchPaymentRequestsMutation();

  const [paymentRequests, setPaymentRequests] = useState([]);
  const get_payment_request = async () => {
    const result = await fetchPaymentRequests();

    if (result && result.data) {
      setPaymentRequests(result.data);
    }
  };
  useEffect(() => {
    get_payment_request();
  }, []);
  return (
    <AccountLayout>
      <div className="d-flex flex-row align-content-center justify-content-between mb-4">
        <h2 className="py-2 mb-0">{t("Payment Requests")}</h2>
        <NavLink to="/offline_payments/options" className="btn btn-primary w-auto">
          {t("View Available Payment Options")}
        </NavLink>
      </div>

      <BaseTable columns={[{
        title: t("Subscription Name"),
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          return (
            <span className="text-base font-medium text-neutral-600 leading-6">
              {record?.user_subscription?.subscription?.plan?.name}
            </span>
          );
        }
      }, {
        title: t("Billing Date"),
        dataIndex: "date_billing_next",
        key: "date_billing_next",
        render: (text, record) => {
          return (
            <span className="text-base font-medium text-neutral-600 leading-6">
              {formatDateTime(record?.user_subscription?.date_billing_next)}
            </span>
          );
        }
      }, {
        title: t("Amount"),
        dataIndex: "grant_total",
        key: "grant_total",
        render: (text, record) => {
          return (
            <span className="text-base font-medium text-neutral-600 leading-6">
              {record?.user_subscription?.total}
            </span>
          );
        }
      }, {
        title: t("Payment Request Status"),
        dataIndex: "status",
        key: "status",
        render: (text, record) => {
          return (
            <span className="text-base font-medium text-neutral-600 leading-6">
              {t(record?.status)}
            </span>
          );
        }
      },
      {
        title: t("Subscription Status"),
        dataIndex: "user_subscription_status",
        key: "user_subscription_status",
        render: (text, record) => {
          return (
            <span className="text-base font-medium text-neutral-600 leading-6">
              {t(record?.user_subscription.status)}
            </span>
          );
        }
      },
      {
        title: t("Actions"),
        dataIndex: "id",
        key: "actions",
        render: (text, record) => {
          return (
            <div className="flex items-center flex-column align-content-center">
              <NavLink to={`/account/profile/payment_requests/${record?.id}`} className="py-2 px-3 my-1 btn btn-primary btn-sm table-btn w-auto  ">
                <FontAwesomeIcon icon={faEye} className="mr-2" />
              </NavLink>
              {/* <NavLink to={`/account/subscriptions/${record?.user_subscription?.id}`} className="py-2 my-1  px-3 btn btn-primary btn-sm table-btn w-auto h-100">{t("View Details")}</NavLink> */}

            </div>
          );
        }
      }
      ]}
       isLoading={isLoadingPaymentRequests} data={paymentRequests} indexed={true}
       noDataWidget={<EmptyData title={t("There is no Payment Requests")} hint={t("Payment Requests will appear her .")}/>} />
      <hr/>
    </AccountLayout>
  );
};
export default PaymentRequests;
