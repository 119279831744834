import React from "react";

import LoadingSpinner from "../LoadingSpinner";
import EmptyData from "./Empty";

const BaseTable = ({
  data = [],
  columns = [],
  isLoading = false,
  indexed = false,
  noDataWidget = undefined
}) => {
  return (
    <table className="table table-hover mb-0 w-100 border-1 border-secondary  ">
      <thead>
        <tr>
          {
            indexed && (
              <th scope="row" className="px-3 py-4 align-middle" key={-1 }> #</th>

            )
          }

          {columns.map((column, idx) => {
            if (column.renderColumn) {
              return <>  {column.renderColumn()}</>;
            }
            return <th className="p-3 align-middle" scope="col" key={idx}>{column.title}</th>;
          })
          }

        </tr>
      </thead>
      <tbody>
        {isLoading
          ? (
            <tr>
              <td colSpan={columns.length} className="py-8">
                <div className="flex flex-col items-center justify-center">
                  <LoadingSpinner/>
                  <p className="text-gray-500 text-lg mt-4">Loading...</p>
                </div>
              </td>
            </tr>
            )
          : data?.length > 0
            ? (
                data.map((item, index) => {
                  return (
                    <tr key={index} className={data.length === index + 1 ? "last-row" : ""} >
                      {
                        indexed && (
                          <th scope="row"
                              className="px-3 py-4 align-middle">
                            #{index + 1}
                          </th>
                        )
                      }
                      {columns.map((column, cindex) => {
                        let value;
                        if (Array.isArray(column.dataIndex)) {
                          value = column.dataIndex.reduce((acc, curr) => {
                            return acc[curr] ?? "";
                          }, item);
                        } else {
                          value = item[column.dataIndex];
                        }
                        return (
                          <td key={`${cindex}-${index}`}
                                className="px-3 py-4 align-middle">
                            {
                              column.render ? column.render(value, item, index) : value
                            }
                          </td>
                        );
                      })}
                    </tr>
                  );
                }
                )
              )
            : (<tr>
              <td colSpan={columns.length + (indexed ? 1 : 0)}>{noDataWidget || <EmptyData/>}
              </td>
            </tr>
              )
      }

      </tbody>
    </table>
  );
};
export default BaseTable;
