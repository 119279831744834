import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form } from "formik";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";
import { Container } from "react-bootstrap";
import { confirmSchema } from "../../utils/ValidationSchemas";
import { useLastCodeRequest } from "../../store/slices/userSlice";
import { useRequestCodeMutation, useVerifyCodeMutation, useFetchUserMutation } from "../../store/services/authApi";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
function VerifyStep ({ usedCredentials, changeStep, setUsedCredentials, next, empedded }) {
  const [otpCounter, setOtpCounter] = useState(0);
  const formRef = useRef();
  const { t } = useTranslation();
  const { last_code_request } = useLastCodeRequest();
  const [fetchUser] = useFetchUserMutation();
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();
  const [requestCode, { isLoading: isLoadingRequestCode }] =
  useRequestCodeMutation();
  const [verifyCode, { isLoading: isLoadingVerifyRequestCode }] =
    useVerifyCodeMutation();

  const handleSubmitEmail = async (values, { setErrors, setStatus }) => {
    setSuccessMessage("");
    let resp;
    try {
      resp = await requestCode(usedCredentials);
      if (resp && resp.error) {
        setErrors(resp.error.data);
        return null;
      } else if (resp && resp.data && resp.data.msg) {
        setSuccessMessage(`${t("The authorisation code has been sent to")} ${usedCredentials[Object.keys(usedCredentials)[0]]}`);
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubmitCode = async (values, { setErrors, setStatus, resetForm }) => {
    let resp;
    try {
      let body = { code: values.code };
      if (usedCredentials.willShare) {
        body = { ...body, ...usedCredentials };
      }
      resp = await verifyCode(body);
      if (resp.error?.data) {
        setErrors(resp.error.data);
        console.log("OTP error", resp.error.data);
        return null;
      } else if (resp?.data?.access) {
        const userResponse = await fetchUser(resp.data.access);
        if (userResponse) {
          resetForm({ values: { code: "" } });
          if (!empedded) await history.push(next);
        }
      } else if (resp?.data?.signup_token) {
        setUsedCredentials({ ...usedCredentials, signup_token: resp.data.signup_token });
        resetForm({ values: { code: "" } });
        changeStep("other_data");
      } else {
        setStatus(t("Something went wrong, try again later"));
      }
    } catch (error) {
      console.error(error);
      setStatus(t("Something went wrong, try again later"));
      return null;
    }
  };

  useEffect(() => {
    const check = Math.floor(Date.now() / 1000) - last_code_request;
    setOtpCounter(check);
    if (check < 60) {
      const otpInterval = setInterval(() => {
        setOtpCounter((otpCounter) => otpCounter + 1);
      }, 1000);

      return () => clearInterval(otpInterval);
    }
  }, [last_code_request]);

  useEffect(() => {
    const msg = `${t("The authorisation code has been sent to")} ${usedCredentials.verification_method === "0" ? usedCredentials.email : usedCredentials.phone_number}`;
    setSuccessMessage(msg);
  }, [usedCredentials]);

  useEffect(() => {
    const inputs = document.querySelectorAll("input[autocomplete=\"one-time-code\"]");
    if (!inputs.length) return;
    const ac = new AbortController();
    const signal = ac.signal;
    const form = inputs[0].closest("form");
    if (form) {
      form.addEventListener("submit", () => {
        ac.abort();
      });
    }
    navigator.credentials.get({
      otp: { transport: ["sms"] },
      signal
    }).then((cred) => {
      if (cred) {
        const code = cred.code;
        inputs.forEach((input, i) => {
          if (input.name.includes("code")) {
            input.value = code[i];
          }
        });
        formRef.current.setFieldValue("code", code);
        formRef.current.submitForm();
      }
    }).catch((err) => {
      // alert(err);
      console.error(err);
    });
  }, [window]);

  useEffect(() => {
    const inputs = document.querySelectorAll("input[autocomplete=\"one-time-code\"]");
    inputs.forEach((input) => {
      if (input.name.includes("code")) {
        const { width } = input.getBoundingClientRect();
        input.setAttribute("style", "height: " + width + "px");
      }
    });
  });

  return (

    <div>
      <Container className={"py-4 my-4"}>
        <div className="row justify-content-center">
          <div className={empedded ? "col-lg-10 col-xl-7" : "col-md-8 col-lg-6 col-xl-5"}>
            <div className="form-container shadow bg-white">
              <div className="text-center">
                <h1 className="text-primary">{t(`Verify ${+parseInt(usedCredentials.verification_method) ? "phone_number" : "email"}`)}</h1>
                <p className="text-secondary pt-2 m-0 under-header">{successMessage}</p>
              </div>
              <Formik
              innerRef={formRef}
              validationSchema={confirmSchema}
              onSubmit={handleSubmitCode}
              validateOnChange
              initialValues={{
                email: "",
                code: ""
              }}
            >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur, // handler for onBlur event of form elements
                  handleFocus, // handler for onBlur event of form elements
                  isValid,
                  handleSubmit,
                  dirty,
                  status,
                  setErrors,
                  setStatus,
                  setFieldValue,
                  resetForm
                }) => (
                  <Form
                  className="form"
                  onSubmit={handleSubmit}
                >
                    {status && (
                      <div className="error-msg error mb-4">{status}</div>
                    )}
                    <div className="position-relative">
                      <div className="position-relative d-flex justify-content-between" dir="ltr">
                        {[1, 2, 3, 4, 5, 6].map(i => {
                          return (

                            <Field
                            key={i}
                            // autoFocus={i === 1}
                            autoComplete="one-time-code"
                            inputMode="numeric"
                            name={"code" + i}
                            className={"form-control px-0 text-center otp-input "}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (values.code.length >= 6 && value !== "") return;
                              else if (values.code.length < i - 1) i = values.code.length + 1;
                              const digits = /^[0-9]+$/;
                              if (value.length && !value.match(digits)) return;
                              if (value.length > 6) {
                                value = value.substring(0, 6);
                              }
                              if (value.length > 0) {
                                value.split("").forEach((c, ind) => {
                                  const next = document.querySelector(`input[name="code${i + ind + 1}"]`);
                                  if (next) {
                                    // next.disabled = false;
                                    next.focus();
                                    next.previousElementSibling.disabled = true;
                                  }
                                });
                                setFieldValue("code", values.code.substring(0, i - 1) + value + values.code.substring(i));
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Backspace") {
                                const prev = e.target.previousElementSibling;
                                if (prev) {
                                  prev.disabled = false;
                                  prev.focus();
                                }
                                if (values.code.length) {
                                  setFieldValue("code", values.code.substring(0, values.code.length - 1));
                                }
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.code[i - 1] ?? ""}
                            onFocus={handleFocus}
                          />
                          );
                        })}
                      </div>
                      {errors.code && touched.code && (
                        <span className="text-danger">
                          <small>{t(errors.code)}</small>
                        </span>
                      )
                      }
                    </div>

                    <div className="text-center form-footer">
                      <button
                      type="submit"
                      disabled={
                        values.code.length < 6 || isLoadingVerifyRequestCode
                      }
                      className="btn btn-lg w-100 btn-primary my-4"
                    >
                        {isLoadingVerifyRequestCode && (
                          <ButtonLoadingSpinner />
                        )}{" "}
                        {t("Login")} {t("Confirm")}
                      </button>
                      <div className="error-msg error mb-1">
                        {t("Resent OTP") + " "}
                        {otpCounter < 60
                          ? (
                            <span
                        className="resend-span">
                              {(60 - otpCounter) + t("Seconds")}
                            </span>
                            )
                          : (
                            <span
                        role="button"
                        className="resend-span fw-bold"
                        onClick={() => {
                          handleSubmitEmail(
                            {}, { setErrors, setStatus }
                          );
                        }}
                      >
                              {isLoadingRequestCode ? <ButtonLoadingSpinner /> : t("Resend")}
                            </span>
                            )}
                        {errors.email && touched.email
                          ? (
                            <div className="invalid-feedback start-0 top-100">
                              {errors.email}
                            </div>
                            )
                          : null}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </div>

  );
}

export default VerifyStep;
