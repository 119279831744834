import { MainLayout } from "../../layouts/MainLayout";
import { Container } from "react-bootstrap";
import PlansBlock from "../../components/PlansBlock";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FAQsBlock from "../../components/FAQs";

export default function Plans (props) {
  const lang = localStorage.getItem("lang", "en");

  const merchant = useSelector((state) => state.configStore.merchant);
  const { t } = useTranslation(["translation"]);
  console.log("merchant", merchant);
  return (
    <MainLayout>
      <div>
        <div style={{ minHeight: "300px" }}>
          {(props.location.state.subscription.image || merchant.banner) && <img className="img-fluid w-100" src={props.location.state.subscription.image ?? merchant.banner}
          style={{ maxHeight: "663px", minHeight: "400px" }} alt={props.location.state.subscription.title} /> }

        </div>
        <Container>

          <div className="text-center mt-60 mb-40 py-4">
            <h1 className="mb-8 plans-title-text">{t("plans")}</h1>
            <h1 className="plans-info-text">
              {lang === "en" ? props.location.state.subscription.plans_title : props.location.state.subscription.plans_title_ar}
            </h1>
            {/* <p className="card-title-big">
              {lang === "en" ? props.location.state.subscription.plans_desc : props.location.state.subscription.plans_desc_ar}
            </p> */}
          </div>
          <PlansBlock subscription={props.location.state.subscription} location={props.location}/>

          {props.location.state.subscription.faqs && props.location.state.subscription.faqs.length > 0 && <div className="text-center my-4 py-4   d-flex justify-content-center flex-column">
            <h2 className="plans-title-text">{t("faqs")}</h2>
            <div className="row">
              <FAQsBlock faqs={props.location.state.subscription.faqs} />
            </div>
          </div>}

        </Container>
      </div>
    </MainLayout>
  );
}
