// clearAuthAction.ts
import { createAction } from "@reduxjs/toolkit";

export const CLEAR_AUTH = "userStore/logout";
export const SET_AUTH = "userStore/login";
export const SET_LAST_CODE_REQUEST = "userStore/last_code_request";

export const clearAuthAction = createAction(CLEAR_AUTH);
export const loginAction = createAction(SET_AUTH);
export const lastCodeRequest = createAction(SET_LAST_CODE_REQUEST);
