import { clearAuthAction, loginAction } from "./authActions";

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../../utils/makeUrl";
import { RE_AUTH } from "../../utils/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");
    const { token } = getState().userStore;
    if (token?.access) {
      headers.set("Authorization", `Bearer ${token.access}`);
    }
    if (apiBaseUrl.includes("ngrok")) {
      headers.set("ngrok-skip-browser-warning", "69420");
    }
    headers.set("Accept-Language", getState().userStore.language);
    return headers;
  }
});
export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const isError = result.error && result.error.status === 401;
  if (isError && args.url !== "token/") {
    const refresh = api.getState().userStore.token.refresh;
    const refreshResult = await baseQuery(
      { url: RE_AUTH.TOKEN_REFRESH, method: "POST", body: { refresh } },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      api.dispatch(loginAction(refreshResult.data));
      // api.dispatch(setToken(refreshResult.data.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      console.log("refreshResult.data empty, logout");
      api.dispatch(clearAuthAction());
      // api.dispatch(logoutAction());
    }
  }
  return result;
};
