import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { Container } from "react-bootstrap";
import { otpLoginSchema } from "../../utils/ValidationSchemas";
import { useTranslation } from "react-i18next";
import { useLastCodeRequest } from "../../store/slices/userSlice";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";
import { useRequestCodeMutation } from "../../store/services/authApi";
import { useSelector } from "react-redux";
import CountryPhoneField from "../../components/CountryPhoneField";
import CountryCodes from "../../utils/constants/CountryCodes";
function EmailOrPhoneStep ({ changeStep, setUsedCredentials, userCountry, empedded }) {
  const { t } = useTranslation();
  const [otpCounter, setOtpCounter] = useState(0);
  const { last_code_request } = useLastCodeRequest();
  const [requestCode, { isLoading: isLoadingRequestCode }] = useRequestCodeMutation();
  const sms_support = useSelector(state => state.configStore.merchant.has_sms_provider);
  const [loginField, setLoginField] = useState(sms_support ? "phone_number" : "email");
  const handleSubmitLogin = async (values, { setErrors, resetForm, setStatus }) => {
    const body = { ...values, verification_method: loginField === "email" ? "0" : "1", phone_number: values.phone_number ? `${CountryCodes[values.country]}${values.phone_number}` : values.phone_number };
    const { success, errors } = await handleSubmitEmail(body);
    if (success) {
      setUsedCredentials(body);
      resetForm({ values: { [loginField]: "", country: values.country } });
      changeStep("verify");
    } else {
      resetForm({ values });
      setStatus(errors);
      setErrors(errors);
    }
  };

  const handleSubmitEmail = async (values) => {
    let resp;
    let success = false;
    let errors = {};
    try {
      resp = await requestCode(values);
      if (resp && resp.data && resp.data.msg) {
        success = true;
      } else if (resp.error?.data) {
        errors = resp.error.data;
      }
    } catch (error) {
      errors = { server: [error] };
    }
    return { success, errors };
  };

  const changeLoginField = (resetForm) => {
    const field = loginField === "email" ? "phone_number" : "email";
    setLoginField(field);
    resetForm({ values: { [field]: "", country: userCountry } });
  };

  useEffect(() => {
    const check = Math.floor(Date.now() / 1000) - last_code_request;
    setOtpCounter(check);
    if (check < 60) {
      const otpInterval = setInterval(() => {
        setOtpCounter((otpCounter) => otpCounter + 1);
      }, 1000);

      return () => clearInterval(otpInterval);
    }
  }, [last_code_request]);

  return (
    <div>
      <Container className={"py-4 my-4"}>
        <div className="row justify-content-center">
          <div className={empedded ? "col-lg-10 col-xl-7" : "col-md-8 col-lg-6 col-xl-5"}>
            <div className="form-container shadow bg-white">
              <div className="text-center">
                <h1 className="text-primary">{t(`${loginField.replace(loginField[0], loginField[0].toUpperCase())} Login`)}</h1>
                <p className="text-secondary pt-2 m-0 under-header">
                  {t(`Enter your ${loginField} to login and a message will be sent to you to verify your ${loginField}`)}
                </p>
              </div>
              <Formik
                  validationSchema={otpLoginSchema}
                  // onSubmit={(e) => {e.preventDefault();}}
                  onSubmit={handleSubmitLogin}
                  validateOnChange
                  initialValues={{
                    [loginField]: "",
                    country: userCountry
                  }}
                >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur, // handler for onBlur event of form elements
                  handleFocus, // handler for onBlur event of form elements
                  isValid,
                  handleSubmit,
                  dirty,
                  status,
                  resetForm,
                  setFieldValue
                }) => (
                  <Form
                      className="form rounded-3"
                      onSubmit={handleSubmit}
                    >
                    {status && (
                      Object.keys(status).map((key, n) => <div key={n} className="error-msg error mt-4 text-danger">{t(status[key][0])}</div>)
                    )}
                    {loginField === "email"
                      ? <div className="position-relative">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label mb-1"
                        >
                          {t("Email address")}
                        </label>
                        <div className="position-relative">
                          <Field
                            name="email"
                            className={`form-control ps-5 ${
                              errors.email && touched.email ? " is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            onFocus={handleFocus}
                            autoFocus
                          />
                          {errors.email && touched.email
                            ? (

                              <div className="invalid-feedback start-0 top-100">
                                {t(errors.email)}
                              </div>
                              )
                            : null}
                        </div>
                      </div>
                      : <CountryPhoneField
                        errors={errors}
                        values={values}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        handleFocus={handleFocus}
                        touched={touched}
                        autoFocus
                      />
                    }

                    <div className="text-center form-footer pt-4">
                      <button
                          type="submit"
                          disabled={
                            !isValid ||
                            !dirty ||
                            isLoadingRequestCode ||
                            otpCounter < 60
                          }
                          className="btn btn-lg w-100 btn-primary"
                        >
                        {isLoadingRequestCode && <ButtonLoadingSpinner />}{" "}
                        {otpCounter < 60 ? 60 - otpCounter : t("Continue")}
                      </button>
                      <div className="d-flex justify-content-between align-items-center another-way">
                        <hr></hr>
                        <div className="text-secondary">{t("Or try another method")}</div>
                        <hr></hr>
                      </div>
                      {sms_support &&
                        <div>
                          <a href={"javascript:void(0)"} onClick={(e) => changeLoginField(resetForm)} className="link btn btn-lg w-100 btn-outline-primary text-decoration-none">
                            {t(`Switch to ${loginField === "email" ? "phone" : "email"} login`)}
                          </a>
                        </div>
                    }
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default EmailOrPhoneStep;
