// eslint-disable-next-line react-hooks/exhaustive-deps
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function LoadingSpinner (props) {
  const { t } = useTranslation(["translation"]);
  return (
    <div className={"d-flex justify-content-center " + props.className ?? ""}>
      <Spinner animation="border" role="status" variant={"success"}>
        <span className="sr-only">{t("Loading")}...</span>
      </Spinner>
    </div>
  );
}
