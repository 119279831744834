export const units = [
  "MSecond",
  "Second",
  "Minute",
  "Hour",
  "Day",
  "Week",
  "Month",
  "Year"
];
export const recurrenceTypes = {
  RECURRING: "Every charge of the plan",
  ONETIME: "Once on plan subscription"
};

export const currencies = {
  0: "SAR",
  1: "KWD",
  2: "BHD",
  3: "OMR",
  4: "AED",
  5: "USD"
};
export const languages = {
  en: "English",
  ar: "Arabic"
};

export const USER_SUBSCRIPTION_STATUS = {
  PENDING: ["Pending", "1", "text-warning"],
  ACTIVE: ["Active", "2", "text-success"],
  PAUSED: ["Paused", "3", "text-warning"],
  ENDED: ["Ended", "4", "text-danger"],
  CANCELLING: ["Cancelling", "5", "text-warning"],
  CANCELLED: ["Cancelled", "6", "text-danger"]
};

export const RECURRING_UNITS = {
  0: "Once",
  1: "Second",
  2: "Minute",
  3: "Hour",
  4: "Day",
  5: "Week",
  6: "Month",
  7: "Year"
};
export const PAYMENT_REQUEST_STATUS = {
  PENDING: "PENDING",
  WAITING_FOR_MERCHANT: "WAITING_FOR_MERCHANT",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED"
};

export const PAYMENT_TRY_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "ACCEPTED",
  REJECTED: "REJECTED"
};

export const DISCOUNT_TYPES = {
  FIXED: "1",
  PERCENTAGE: "2",
  PERIOD: "3"
};
