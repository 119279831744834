import { isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiBaseUrl } from "./makeUrl";

const notify = (text) => toast.error(text, {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
});

export const requestErrorLogger = () => (next) => (action) => {
  if (action && isRejectedWithValue(action)) {
    const responseUrl = action.meta.baseQueryMeta.response.url;
    let notNotify = true;
    if ([400, 401, 404].includes(action.payload.status)) {
      notNotify = false;
    } else {
      // notNotify = !URLS_PROCESSED_IN_COMPONENTS.map(item => apiBaseUrl + item).some(url => url === responseUrl
    }
    if ((responseUrl !== apiBaseUrl + "/token/" || responseUrl !== apiBaseUrl + "/menus/") && action.payload.status === 502) {
      notNotify = false;
    }
    notNotify && notify((action.payload.originalStatus >= 500 && "Server error") || action.payload.data.errors[0].image[0]);
  }
  return next(action);
};
