// import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
// import ButtonLoadingSpinner from "../ButtonLoadingSpinner";
// import { toast } from "react-toastify";
// import FormDataJson from "form-data-json-convert";
// import { useMoyasarSaveTokenMutation } from "../../store/services/smApi";
import LoadingSpinner from "../LoadingSpinner";
import i18next from "../../i18n";
import { useFetchMoyaSarCredentialsMutation } from "../../store/services/smApi";

function MoyasarPayment ({
  currency,
  amount,
  handlePayment,
  loadingInit

}) {
//   const { t } = useTranslation();
//   const [moyasarSaveToken] = useMoyasarSaveTokenMutation();
//   const [moyasarPaymentData, setMoyasarPaymentData] = useState({});
  const moyaSarForm = useRef(null);
  const [moysarData, setMoysarData] = useState(null);
  const [fetchMoyaSarCredentials, { isLoading: isFetchingMoyaSarCredentials }] = useFetchMoyaSarCredentialsMutation();

  const getMoyasarCredentials = async () => {
    const result = await fetchMoyaSarCredentials();
    if (result.data) {
      setMoysarData(result.data);
    }
  };

  const initMoyasar = (handleData = {}) => {
    if (+amount < 1 || !moyaSarForm.current || !moysarData) {
      console.log("WILL RETURN");
      return;
    }

    window.Moyasar.init({
      element: moyaSarForm.current,
      amount: (amount || handleData.amount) * 100, // in halalas
      language: i18next.language,
      currency: currency || "SAR",
      description: handleData.subscription_id ? "Subscription Payment" : "Payment",
      publishable_api_key: moysarData.public_key,
      callback_url: handleData.callback_url || (window.location.origin + "/checkout/processing"), // where to redirect after payment (success or failure)
      on_completed: handleData.init_url || (window.location.origin + "/api/moyasar/init/"),
      methods: ["creditcard"],
      credit_card: {
        save_card: true,
        manual: handleData.manual || false
      },
      on_initiating: onMoyasarInit,
      metadata: {
        reference: handleData.reference || "1234567890",
        subscription_id: handleData.subscription_id || "1234567890",
        operation_for: handleData.operation_for || "payment"
      }
    });
  };

  const onMoyasarInit = async (data) => {
    console.log("MOYASAR INIT", data);
    const handleData = await handlePayment("moyasar", null, null, true);
    console.log("HANDLE DATA", handleData);
    if (!handleData) {
      return false;
    }

    initMoyasar(handleData);
    return true;
  };

  useEffect(() => {
    // setMoyasarPaymentData({});
    initMoyasar();
  }, [amount, currency, loadingInit, moyaSarForm.current, moysarData]);

  useEffect(() => {
    getMoyasarCredentials();
  }, []);

  return (
    <div className="w-100">
      <div id="root-form" className="w-100">
        {
          isFetchingMoyaSarCredentials
            ? (
              <LoadingSpinner />
              )
            : (

              <form ref={moyaSarForm} id="moyasar-payment-form" className="w-100"></form>
              )

        }
      </div>
    </div>
  );
}

export default MoyasarPayment;
