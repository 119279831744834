import { Helmet } from "react-helmet";

export const SiteHelmet = ({ merchant }) => {
  return (
    <Helmet>
      <link rel="icon" href={merchant.favicon ?? "/favicon.ico"} />
      <link rel="apple-touch-icon" href={merchant.favicon ?? "/logo192.png"} />
      {merchant && <title>{merchant.company_name ?? "No Merchant"}</title>}
      <meta name="title" content={merchant.company_name ?? "No Merchant"} />
      <meta name="description" content={merchant.description ?? "No Merchant"} />
      <meta name="description" content={merchant.description ?? "No Merchant"} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={merchant.company_name ?? "No Merchant"} />
      <meta property="og:title" content={merchant.company_name ?? "No Merchant"} />
      <meta property="og:description" content={merchant.description ?? "No Merchant"} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content={merchant.banner ?? "/favicon.ico"} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="800" />
      <meta property="twitter:card" content={merchant.banner ?? "/favicon.ico"} />
      <meta property="twitter:site" content={merchant.company_name ?? "No Merchant"} />
      <meta property="twitter:title" content={merchant.company_name ?? "No Merchant"} />
      <meta property="twitter:description" content={merchant.description ?? "No Merchant"} />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="twitter:image" content={merchant.favicon ?? "/favicon.ico"} />
    </Helmet>
  );
};
