import React, { useEffect, useState } from "react";
import { GoSellElements } from "@tap-payments/gosell";
import { useFetchTapCredentialsMutation } from "../store/services/smApi";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ButtonLoadingSpinner from "./ButtonLoadingSpinner";

function TapPayment ({ amount, currency, onSuccess, LoadingSubscripe, isUpdateMethod = false }) {
  const { t } = useTranslation();
  const [tap, setTap] = useState({});
  const [fetchTapCredentials, { isLoading: isFetchingTapCredentials }] = useFetchTapCredentialsMutation();
  const locale = useSelector((state) => state.userStore.language);
  const [loadingInit, setLoadingInit] = useState(false);
  const observerRef = React.useRef();
  const fetchCredentials = async () => {
    const result = await fetchTapCredentials();
    if (result.data) {
      setTap(result.data);
    }
  };

  useEffect(() => {
    fetchCredentials();
    observerRef.current = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        console.log("MutationObserverId", mutation.target.id);
        if (mutation.target.id === "gosell-gateway-element-container" && document.getElementById("gosell-gateway-element-container").childElementCount > 1) {
          document.getElementById("gosell-gateway-element-container").children[0].remove();
        }
      });
    });
    observerRef.current.observe(document, {
      attributes: true,
      childList: true,
      subtree: true
    });
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <style>
        {`
          #gosell-gateway-form-container {
            width: 100%;
          }
          `}
      </style>
      {isFetchingTapCredentials &&
        (
          <Spinner animation="border" variant="primary" />
        )}
      {tap.pb_key && (
        <div className={"d-flex flex-column align-items-center justify-content-center w-100"}>

          {
                    tap.with_apple_pay
                      ? (
                        <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={async () => {
                              onSuccess({ id: "src_all" });
                            }}
                            >
                          {
                                isUpdateMethod ? t("Update Card Details") : t("Pay")} {amount} {t(currency)
                              }
                        </button>
                        )
                      : <>
                        <GoSellElements
                            gateway={{
                              publicKey: tap.pb_key,
                              language: locale,
                              supportedCurrencies: "all",
                              supportedPaymentMethods: "all",
                              notifications: "msg",
                              callback: onSuccess,
                              labels: {
                                cardNumber: t("Card Number"),
                                expirationDate: t("MM/YY"),
                                cvv: t("CVV"),
                                cardHolder: t("Card Holder")
                                // actionButton: `${t("Pay")} ${amount} ${t(currency)} ${t("with")} Tap`
                              },
                              style: {
                                base: {
                                  width: "100%",
                                  color: "#535353",
                                  lineHeight: "18px",
                                  fontFamily: "IBM Plex Sans Arabic, sans-serif",
                                  fontSmoothing: "antialiased",
                                  fontSize: "16px",
                                  "::placeholder": {
                                    color: "rgba(0, 0, 0, 0.26)",
                                    fontSize: "15px"
                                  }

                                },
                                invalid: {
                                  color: "red",
                                  iconColor: "#fa755a "
                                }

                              }
                            }}

                        />
                        <p id="msg" className="text-center text-danger font-weight-bold" />
                        <button
                            type="button"
                            disabled={loadingInit || LoadingSubscripe}
                            className="btn btn-primary w-100"
                            onClick={async () => {
                              setLoadingInit(true);
                              await GoSellElements.submit();
                              setLoadingInit(false);
                            }}
                            onKeyDown={("Enter", async () => {
                              setLoadingInit(true);
                              await GoSellElements.submit();
                              setLoadingInit(false);
                            }
                            )}
                        >
                          {
                                LoadingSubscripe || loadingInit
                                  ? <ButtonLoadingSpinner />
                                  : isUpdateMethod
                                    ? t("Update Card Details")
                                    : `${t("Pay")} ${amount} ${t(currency)}`
                                    }
                        </button>
                      </>
                }
        </div>
      )
        }
    </div>
  );
}

export default TapPayment;
