export const AUTH_API = {
  TOKEN: "token/",
  ME: "me/",
  SIGNUP: "signup/",
  REQUEST_CODE: "request-otp/",
  VERIFY_CODE: "verify-otp/",
  FIELD_REQUEST_CODE: "user-request-otp/",
  FIELD_VERIFY_CODE: "user-verify-otp/"
};

export const RE_AUTH = {
  TOKEN_REFRESH: "token/refresh/"
};

export const APP_ROUTE = {
  LOGIN: "/login",
  LOGIN_WITH_EMAIL: "/login-with-email",
  MANAGE_MENUS: "/manage-menus",
  MANAGE_MENUS_ITEMS: "/manage-menus/items",
  RESTAURANT_REGISTRATION: "/restaurant-registration",
  CHOOSE_RESTAURANT: "/choose-restaurant",
  RESTAURANT: "/restaurant",
  ADD_RESTAURANT: "/add-restaurant",
  ENTRY: "/",
  BLOG: "/blog",
  ABOUT: "/#about",
  PRICING: "/#pricing",
  GET_STARTED: "/#getstarted",
  POST: "/blog/:postSlug/",
  THANK_YOU: "/thank-you",
  WAIT_LIST: "/wait-list",
  MANAGE_MODIFIERS: "/manage-modifiers",
  SETTINGS_LANGUAGE: "/settings/language",
  SETTINGS_USER: "/settings/user",
  SETTINGS_THEME: "/settings/theme",
  SETTINGS_SUBSCRIPTION: "/settings/subscription",
  SETTINGS_SUBSCRIPTION_FORM: "/settings/subscription/form",
  SETTINGS_SUBSCRIPTION_ACTIVATE: "/settings/subscription/activate"
};
