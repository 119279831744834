export const prepare_errors = (errors) => {
  if (!errors) {
    return "";
  }
  if (typeof errors === "string") {
    return errors;
  }
  if (typeof errors === "object") {
    const errorElements = Object.entries(errors).map(([key, value], index) => {
      return (

        `${key}: ${typeof value === "string" ? value : Array.isArray(value) ? value.join(",") : prepare_errors(value)}`
      );
    });

    return errorElements;
  }
  return errors;
};
