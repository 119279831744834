import { useTranslation } from "react-i18next";
import { useFetchPaymentMethodsMutation } from "../../store/services/smApi";
import { useEffect, useState } from "react";
import { AccountLayout } from "../../layouts/AccountLayout";
import usePaymentMethodsColumns from "../../hooks/columns/PaymentMethods";
import CustomTable from "../../components/Table/CustomTable";

const PaymentMethods = () => {
  const { t } = useTranslation(["translation"]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [fetchPaymentMethods, { isLoading }] = useFetchPaymentMethodsMutation();
  const columns = usePaymentMethodsColumns();
  const [count, setCount] = useState(0);
  const [queryParam, setQueryParam] = useState({
    page: 1,
    page_size: 8,
    search: ""
  });

  const prepareQuery = () => {
    const query = { ...queryParam };
    Object.keys(query).forEach(key => (!query[key] || query[key] === "") && delete query[key]);
    const queryStr = new URLSearchParams(query).toString();
    return queryStr;
  };

  const handleChangeQueryParams = (param, value) => {
    setQueryParam({ ...queryParam, [param]: value, page: param === "page" ? value : 1 });
  };

  const getPaymentMethods = async () => {
    const res = await fetchPaymentMethods(prepareQuery());
    if (res.data) {
      setCount(res.data.count);
      setPaymentMethods(res.data.results);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, [queryParam]);

  return (
    <AccountLayout>

      <h2 className="py-2 mb-0">{t("Payment Methods")}</h2>
      <hr />
      <CustomTable
            columns={columns}
            data={paymentMethods}
            onSearch={(e) => handleChangeQueryParams("search", e.target.value)}
            onPaginate={{
              count,
              limit: queryParam.page_size,
              page: queryParam.page,
              setPage: (page) => handleChangeQueryParams("page", page)
            }}
            isLoading={isLoading}
            emptyDataTitle={t("No Payment Methods")}
            emptyDataText={t("You don't have any payment methods yet")}
            indexed
        />

    </AccountLayout>
  );
};

export default PaymentMethods;
