import { Switch, Route, Redirect } from "react-router-dom";
import Registration from "./views/Registration/Registration";
import Home from "./views/Home/Home";
import Embedded from "./views/Embeded/Embedded";
import PlansEmbedded from "./views/Plans/PlansEmbedded";
import CheckoutEmbedded from "./views/Checkout/CheckoutEmbedded";
import RegistrationSuccess from "./views/RegistrationSuccess/RegistrationSuccess";
import Logout from "./views/Logout/Logout";
import Plans from "./views/Plans/Plans";
import General from "./views/Account/General";
import PaymentInfo from "./views/Account/PaymentInfo";
import MyAddresses from "./views/Account/MyAddresses";
import Subscriptions from "./views/Account/Subscriptions";
import Checkout from "./views/Checkout/Checkout";
import PaymentRequests from "./views/Account/PaymentRequests/PaymentRequests";
import PaymentRequestDetails from "./views/Account/PaymentRequests/PaymentRequestDetails";
import PaymentRequestTry from "./views/Account/PaymentRequests/PaymentRequestTry";
// import Checkout from "./views/Checkout/checkout_temp";

import NewPaymentSource from "./views/Account/NewPaymentSource";
import PaymentProcessing from "./views/Checkout/PaymentProcessing";
import Auth from "./views/Auth/Auth";
import UpdateAccountInfo from "./views/Account/UpdateAccountInfo";
import AddressForm from "./views/Account/AddressForm";
import SubscriptionDetails from "./views/Account/SubscriptionDetails";
import Language from "./views/Account/Language";
import UpgradePlan from "./views/Upgrade";
import OfflinePaymentOptionsList from "./views/Payment/OfflinePayment/OfflinePaymentOptionsList";
import PaymentMethods from "./views/Account/PaymentMethods";

export const useRoutes = (isAuthenticated) => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home/>
      </Route>
      <Route path="/login">
        <Auth />
      </Route>
      <Route path="/embedded" exact>
        <Embedded/>
      </Route>
      <Route path={"/embedded/subscriptions/:subscriptionId/plans"} component={PlansEmbedded} exact/>
      <Route path={"/embedded/checkout"} component={CheckoutEmbedded} exact/>

      <Route path={"/registration"} component={Registration} exact/>
      <Route path={"/registration/success"} component={RegistrationSuccess} exact/>
      <Route path={"/logout"} component={Logout} exact/>
      <Route path={"/subscriptions/:subscriptionId/plans"} component={Plans} exact/>

      <Route path={"/checkout"} component={Checkout} exact/>
      {isAuthenticated && <>
        <Route path={"/checkout/:operation"} component={PaymentProcessing} exact/>
        <Route authed={isAuthenticated} path={"/subscription/upgrade/:userSubscriptionId"} component={UpgradePlan} exact/>
        <Route authed={isAuthenticated} path={"/offline_payments/options"} component={OfflinePaymentOptionsList} exact/>
        <Route path="/account" render={({ match: { url } }) => (
          <>
            <Route authed={isAuthenticated} path={`${url}/profile/account-info`} component={General} exact/>
            <Route authed={isAuthenticated} path={`${url}/profile/update-account-info/:field`} component={UpdateAccountInfo} exact />

            <Route authed={isAuthenticated} path={`${url}/profile/payment-info`} component={PaymentInfo}/>
            <Route authed={isAuthenticated} path={`${url}/profile/payment-add-source`} component={NewPaymentSource}/>

            <Route authed={isAuthenticated} path={`${url}/profile/addresses`} component={MyAddresses}/>
            <Route authed={isAuthenticated} path={`${url}/profile/address_form`} component={AddressForm} exact/>
            <Route authed={isAuthenticated} path={`${url}/profile/language`} component={Language} exact/>
            <Route authed={isAuthenticated} path={`${url}/subscriptions`} component={Subscriptions} exact/>
            <Route authed={isAuthenticated} path={`${url}/subscriptions/:userSubscriptionId`} component={SubscriptionDetails} exact/>

            {/*   Payment Methods */}
            <Route authed={isAuthenticated} path={`${url}/payment_methods`} component={PaymentMethods} exact/>

            {/*   Payment Requests */}
            <Route authed={isAuthenticated} path={`${url}/profile/payment_requests`} component={PaymentRequests} exact/>
            <Route authed={isAuthenticated} path={`${url}/profile/payment_requests/:id`} component={PaymentRequestDetails} exact/>
            <Route authed={isAuthenticated} path={`${url}/profile/payment_requests/:paymentRequestId/try/:tryId`} component={PaymentRequestTry} exact/>

          </>
        )}
				/>
      </>}
      <Redirect to="/login"/>
    </Switch>
  );
};
