import React, { useState } from "react";
import addIcon from "../assets/images/addicon.svg";
import closeIcon from "../assets/images/closeicon.svg";
import { Collapse } from "@mui/material";

const FAQsBlock = ({ faqs, ...props }) => {
  const [open, setOpen] = useState(null);
  const toggleCollapse = (id) => setOpen(id === open ? null : id);
  return (<div className=" w-100 faqs d-flex flex-column align-items-center  justify-content-start">
    <div className="w-75">

      { faqs.map((faq, faqIndex) => {
        return (
          <div key={faqIndex} >
            {faqIndex === 0 && <hr/>}
            <FAQ faq={faq} isOpen={faq.id === open} toggleCollapse={() => toggleCollapse(faq.id)} />
            <hr/>
          </div>
        );
      })}
    </div>
  </div>);
};

export default FAQsBlock;

export const FAQ = ({ faq, toggleCollapse, isOpen, ...props }) => {
  return (<div className="p-2  ">

    <div className="d-flex flex-row justify-content-between " onClick={toggleCollapse}>
      <p className={"text-head cursor-pointer pd-h-4 " + ` ${isOpen ? "active" : ""}`}>{faq.question}</p>
      <img src={isOpen ? closeIcon : addIcon} alt="icon" />
    </div>
    <Collapse in={isOpen} timeout={300} >
      <p className="pd-h-4 text-body d-flex align-content-center justify-content-start  text-start">{faq.answer}</p>

    </Collapse>

  </div>

  );
};
