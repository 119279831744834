import { Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export const ModelDataTypes = {
  none: 0,
  privacy: 1,
  terms: 2
};

export const ModelTemplate = ({ head, msg, modal = true, accept_text = "accept", handle_accept = undefined, close_text = "close", handleClose = undefined, ...props }) => {
  const Parent = modal ? Modal : Fragment;
  console.log("handle_accept", handle_accept);
  return <Parent {...props} onHide={handleClose} backdrop={true} size="lg" autoFocus centered>
    <Modal.Header closeButton>
      <Modal.Title className="main-title">{head}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="body-model min-title"> {msg} </Modal.Body>
    <Modal.Footer>
      {handleClose && <Button variant="secondary" onClick={handleClose}>
        {close_text}
      </Button>}
      {handle_accept}
      {handle_accept && <Button variant="primary" onClick={handle_accept}>{accept_text}</Button>}

    </Modal.Footer>
  </Parent>;
};
