import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./slices/configSlice";
import checkoutSlice from "./slices/checkoutSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userSlice from "./slices/userSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE
} from "redux-persist/es/constants";
import { authApi } from "./services/authApi";
import { smApi } from "./services/smApi";
import { requestErrorLogger } from "../utils/requestErrorLogger";

export const store = configureStore({
  reducer: {
    userStore: persistReducer({ key: "auth", storage }, userSlice),
    configStore: configReducer,
    checkoutStore: persistReducer({ key: "checkout", storage }, checkoutSlice),
    [authApi.reducerPath]: authApi.reducer,
    [smApi.reducerPath]: smApi.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([smApi.middleware, authApi.middleware]),
    requestErrorLogger
  ]
});
