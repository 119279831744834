import { Button } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFetchUserMutation } from "../../store/services/authApi";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useUpdateProfileMutation } from "../../store/services/smApi";
import { TbPencilMinus } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import { AccountLayout } from "../../layouts/AccountLayout";
import i18n from "../../i18n";
export default function General () {
  const history = useHistory();
  const { t } = useTranslation(["translation"]);
  const isAuthenticated = useSelector(state => state.userStore.isLoggedIn);
  const user = useSelector(state => state.userStore.user);
  const sms_support = useSelector(state => state.configStore.merchant.has_sms_provider);

  const schemaProfile = yup.object().shape({
    name: yup.string().trim().required("Name is a required field"),
    email: yup.string().email("This field accept emails only")
  });

  const [fetchUser, { isLoading: isLoadingFetchUser }] = useFetchUserMutation();
  const [updateProfile, { isLoading: isLoadingUpdateProfile }] = useUpdateProfileMutation();
  const handleSubmitProfileUpdate = async (values, { setStatus, setErrors, resetForm }) => {
    const updatedProfile = await updateProfile({ body: values });
    if ("error" in updatedProfile) {
      setErrors(updatedProfile.error.data);
    } else {
      resetForm({ values });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  if ((isLoadingFetchUser || !isAuthenticated || !user)) {
    return (
      <AccountLayout>
        <h2 className="py-2 mb-0">{t("Profile")}</h2>
        <hr />
        <div className="d-flex justify-content-center mt-5">
          <LoadingSpinner/>
        </div>
      </AccountLayout>
    );
  } else {
    return (
      <AccountLayout>
        <h2 className="py-2 mb-0">{t("Profile")}</h2>
        <hr />

        <Formik
                  initialValues={{ name: user.name, email: user.email, phone_number: user.phone_number }}
                  validationSchema={schemaProfile}
>
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            handleFocus,
            isValid,
            dirty,
            handleSubmit,
            resetForm,
            setErrors,
            setStatus
          }) => (
            <Form className="form" onSubmit={() => {
              document.querySelector("input[name='name']").disabled = true;
              handleSubmitProfileUpdate(values, { setStatus, setErrors, resetForm });
            }}>
              <div className="form-group mb-4">
                <label htmlFor="name" className="form-label">{t("Full Name")}</label>
                <div className="position-relative">
                  <Field type="text"
                          disabled
                          className={`form-control pe-5 ${(errors.name && touched.name) ? " is-invalid" : ""}`}
                          placeholder={t("Enter Full Name")}
                          name={"name"}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          value={values.name}/>
                  {errors.name && touched.name
                    ? (
                      <span className="error invalid-feedback">Error: {errors.name}</span>
                      )
                    : null}

                  <div className="position-absolute top-50 end-0 translate-middle-y text-secondary input-inside-icon px-3 h-100 d-flex align-items-center"
                   style={ i18n.language === "ar" ? { borderLeft: 0 } : { borderRight: 0 }}
                   >
                    <TbPencilMinus role="button" size={24} onClick={() => {
                      document.querySelector("input[name='name']").disabled = false;
                    }} />
                  </div>
                </div>

              </div>

              <div className="form-group mb-4">
                <label htmlFor="email" className="form-label">{t("Email")}</label>
                <div className="position-relative">
                  <Field type="text" className="form-control disabled" id="email"
                          name={"email"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          style={{ cursor: "not-allowed" }}
                          disabled
                          readOnly
                        />
                  <div className="position-absolute top-50 end-0 translate-middle-y text-secondary input-inside-icon px-3 h-100 d-flex align-items-center"
                    style={ i18n.language === "ar" ? { borderLeft: 0 } : { borderRight: 0 }}
                  >
                    <TbPencilMinus role="button" size={24} onClick={() => {
                      history.push("/account/profile/update-account-info/email");
                    }} />
                  </div>
                  {errors.email && touched.email
                    ? (
                      <span className="error invalid-feedback">Error: {errors.email}</span>
                      )
                    : null}
                </div>
              </div>

              <div className="form-group mb-4">
                <label htmlFor="phone_number" className="form-label">{t("Phone Number")}</label>
                <div className="position-relative">
                  <Field readOnly type="text" id="phone_number"
                          className={`form-control pe-5 ${(errors.phone_number && touched.phone_number) ? " is-invalid" : ""}`}
                          name={"phone_number"}
                          style={{ cursor: "not-allowed" }}
                          onChange={handleChange}
                          disabled
                          onBlur={handleBlur}
                          value={values.phone_number}
                        />
                  {sms_support &&
                    <div className="position-absolute top-50 end-0 translate-middle-y text-secondary input-inside-icon px-3 h-100 d-flex align-items-center"
                    style={ i18n.language === "ar" ? { borderLeft: 0 } : { borderRight: 0 }}
                    >
                      <TbPencilMinus role="button" onClick={() => {
                        history.push("/account/profile/update-account-info/phone_number");
                      }} size={24} />
                    </div>
                        }
                  {errors.phone_number && touched.phone_number
                    ? (
                      <span className="error invalid-feedback">Error: {errors.phone_number}</span>
                      )
                    : null}
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-md-4 me-auto">
                  <div className="form-group">
                    <Button disabled={!isValid || !dirty || isLoadingUpdateProfile}
                                                            type={"submit"} className={"w-100"} variant="primary">
                      {!isLoadingUpdateProfile && (<>
                        {t("Save changes")}
                      </>
                      )}

                      {isLoadingUpdateProfile && (<>
                        <span className="spinner-border spinner-border-sm"
                                                                      role="status" aria-hidden="true"></span>
                        {t("Updating")}...
                      </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>

            </Form>
          )}
        </Formik>
      </AccountLayout>
    );
  }
}
