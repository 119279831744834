import { useTranslation } from "react-i18next";
import FormatDate from "../../utils/formatDate";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { USER_SUBSCRIPTION_STATUS, RECURRING_UNITS } from "../../utils/beDicts";
import { useSelector } from "react-redux";

export const useSubscriptionsColumns = () => {
  const { t } = useTranslation(["translation"]);
  const currency = useSelector(state => state.configStore.merchant.currency_code);

  const columns = [
    {
      title: t("Plan"),
      dataIndex: "name"
    },
    {
      title: t("Total"),
      dataIndex: "last_transaction_amount",
      render: (value, row) => (
        <>
          {parseFloat(value).toFixed(2)}
          {" "}
          {t(currency)}/
          {`${row.recurrence_period > 0 ? row.recurrence_period + " " : ""}`}
          {t((RECURRING_UNITS[row.recurrence_unit] + (row.recurrence_period > 1 ? "s" : "")).toLocaleLowerCase())}
          {
                        row.recurring_count < 1 && row.setup_fee > 0 &&
                          <span className="small ms-1">
                            {"("}
                            {t("Including")}
                            {" "}
                            {parseFloat(row.setup_fee).toFixed(2)}
                            {" "}
                            {t(currency)}
                            {" "}
                            {t("Setup Fee")}
                            {")"}
                          </span>
                      }
        </>
      )
    },
    {
      title: t("Subscription Date"),
      dataIndex: "date_billing_start",
      render: (value) => FormatDate(value, "DD/MM/YYYY")
    },
    {
      title: t("Next Subscription Date"),
      dataIndex: "date_billing_next",
      render: (value) => FormatDate(value, "DD/MM/YYYY")
    },
    {
      title: t("Subscription Status"),
      dataIndex: "current_status",
      render: (value) => {
        const status = Object.values(USER_SUBSCRIPTION_STATUS).find(([label, val]) => val === value);
        return <span className={status[2]}>{t(status[0])}</span>;
      }
    },
    {
      title: t("Action"),
      dataIndex: "id",
      render: (value) => (
        <NavLink to={`/account/subscriptions/${value}`} className="py-2 px-3 btn btn-primary btn-sm table-btn w-auto h-100">{t("View Details")}</NavLink>
      )
    }
  ];

  return columns;
};
