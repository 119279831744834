import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    },
    // resources,
    // lng: 'cimode', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    // fallbackLng: 'en',
    fallbackLng: false,
    returnEmptyString: false,
    react: {
      useSuspense: true,
      wait: true
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    whitelist: ["en", "ar"],
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: process.env.REACT_APP_ENV_NAME !== "prod",
    ns: ["translation"],
    defaultNS: "translation",
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "lang",
      checkWhitelist: true
    },
    // saveMissing: true,
    saveMissingTo: "all"
  });

export default i18n;
