import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  useCreateUserAddressMutation,
  useUpdateUserAddressMutation
} from "../../store/services/smApi";
import { MainLayout } from "../../layouts/MainLayout";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setEditableAddress } from "../../store/slices/userSlice";
import * as yup from "yup";
import countryList from "react-select-country-list";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";
import Breadcrumb from "../../components/Breadcrumb";

function AddressForm () {
  const [newAddress, setNewAddress] = useState(null);
  const [createUserAddress, { isLoading: isLoadingCreateUserAddressMutation }] = useCreateUserAddressMutation();
  const [updateUserAddress, { isLoading: isLoadingUpdateUserAddressMutation }] = useUpdateUserAddressMutation();
  const { t } = useTranslation();
  const history = useHistory();
  const editableAddress = useSelector((state) => state.userStore.editableAddress);
  const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  const schema = yup.object().shape({
    country: yup.string().required("Country is required"),
    full_name: yup.string().required("Full Name field is required"),
    city: yup.string().required("City field is required"),
    street: yup.string().required("Street field is required"),
    phone: yup.string().required("Phone field is required"),
    building: yup.string().required("Apt, Suite, Other field is required")
  });
  const [crumbs, setCrumbs] = useState([]);

  const handleSubmit = async (
    values,
    { setErrors, setStatus, resetForm }
  ) => {
    let result;
    if (editableAddress) {
      result = await updateUserAddress({ body: values, id: editableAddress.id });
    } else {
      result = await createUserAddress({ body: values });
    }
    console.log("values", result, result.error);
    if (result && result.error) {
      resetForm({ values });
      setErrors(result.error.data);
      setStatus(result.error.data);
    } else if (result) {
      resetForm();
      dispatch(setEditableAddress(null));
      history.push("/account/profile/addresses");
    } else {
      setStatus("Something went wrong");
      resetForm({ values });
    }
  };

  useEffect(() => {
    if (editableAddress) {
      setNewAddress(editableAddress);
    } else {
      setNewAddress({ full_name: "", country: "", city: "", phone: "", street: "", state: "", zip: "", is_default: false });
    }
    setCrumbs([
      { name: "account-info", display: "Account Management" },
      { name: "addresses", display: "Addresses" },
      { name: "address_form", display: editableAddress ? "Edit Address" : "New Address" }
    ]);
  }, []);

  return (
    <MainLayout>
      <div>
        <Container className={"py-4 my-4"}>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <Breadcrumb crumbs={crumbs} basePath="/account/profile" />
              <div className="form-container account-content-container">
                <div className="text-start">
                  <h2 className="text-primary mb-0">{!editableAddress ? t("Add new address") : t("Edit Address")}</h2>
                  <hr className="mb-0" />
                  {newAddress &&
                    <Formik
                      validationSchema={schema}
                      onSubmit={handleSubmit}
                      validateOnChange
                      enableReinitialize
                      initialValues={newAddress}
                    >
                      {({
                        errors,
                        touched,
                        dirty,
                        values,
                        handleChange,
                        handleBlur, // handler for onBlur event of form elements
                        isValid,
                        setFieldValue,
                        handleSubmit,
                        status
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          {status && (
                            Object.keys(status).map((key, n) => {
                              console.log(status);
                              if (!touched[key]) {
                                return (
                                  <div key={n} className="error-msg error mt-4 text-danger">{t(key)}: {t(status[key][0])}</div>
                                );
                              }
                              return null;
                            })
                          )}
                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mt-3 mb-1 position-relative">
                                <label
                                    htmlFor={"full_name"}
                                    className="form-label"
                                  >
                                  {t("Full name")}
                                </label>
                                <div
                                    className={`position-relative ${
                                      errors.full_name && touched.full_name
                                        ? " is-invalid"
                                        : ""
                                    }`}
                                  >
                                  <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.full_name && touched.full_name
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                      id={"fullname"}
                                      name="full_name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.full_name}
                                      autoFocus={!editableAddress}
                                    />
                                  {errors.full_name && touched.full_name
                                    ? (
                                      <div
                                        className={
                                        "invalid-feedback start-0 top-100"
                                        }
                                      >
                                        {errors.full_name}
                                      </div>
                                      )
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group mt-3 mb-1 position-relative">
                                <label
                                    htmlFor="phone"
                                    className="form-label"
                                  >
                                  {t("Phone")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.phone && touched.phone
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                      id="phone"
                                      name="phone"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.phone}
                                    />
                                  {errors.phone && touched.phone
                                    ? (
                                      <div
                                        className={
                                        "invalid-feedback start-0 top-100"
                                        }
                                      >
                                        {errors.phone}
                                      </div>
                                      )
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group mt-3 mb-1">
                                <label htmlFor="country" className="form-label">
                                  {t("Country")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                      as="select"
                                      name="country"
                                      className={`form-select ${
                                        errors.country && touched.country
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                      options={options}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                    <option>{t("Select Country")}</option>
                                    {options.map((country) => {
                                      return (
                                        <option
                                            key={country.label}
                                            value={country.value}
                                          >
                                          {country.label}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  {errors.country && touched.country
                                    ? (
                                      <div className="invalid-feedback start-0 top-100">
                                        {errors.country}
                                      </div>
                                      )
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group mt-3 mb-1 position-relative">
                                <label
                                    htmlFor="street"
                                    className="form-label"
                                  >
                                  {t("Street")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.street && touched.street
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                      id="street-field"
                                      name="street"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.street}
                                    />
                                  {errors.street && touched.street
                                    ? (
                                      <div
                                        className={
                                        "invalid-feedback start-0 top-100"
                                        }
                                      >
                                        {errors.street}
                                      </div>
                                      )
                                    : null}
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="row">

                            <div className="col-sm-6 col-xl-3">
                              <div className="form-group mt-3 mb-1">
                                <label htmlFor="city" className="form-label">
                                  {t("City")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.city && touched.city
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                      id="city"
                                      name="city"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.city}
                                    />
                                  {errors.city && touched.city
                                    ? (
                                      <div
                                        className={
                                        "invalid-feedback start-0 top-100"
                                        }
                                      >
                                        {errors.city}
                                      </div>
                                      )
                                    : null}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 col-xl-3">
                              <div className="form-group mt-3 mb-1">
                                <label
                                    htmlFor="state"
                                    className="form-label"
                                  >
                                  {t("State")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.state && touched.state
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                      id="state"
                                      name="state"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.state}
                                    />
                                  {errors.state && touched.state
                                    ? (
                                      <div
                                        className={
                                        "invalid-feedback start-0 top-100"
                                        }
                                      >
                                        {errors.state}
                                      </div>
                                      )
                                    : null}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 col-xl-3">
                              <div className="form-group mt-3 mb-1">
                                <label htmlFor="building" className="form-label">
                                  {t("Apt, Suite, Other")}
                                </label>
                                <div className="position-relative">
                                  <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.building && touched.building
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                      id="building"
                                      name="building"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.building}
                                    />
                                  {errors.building && touched.building
                                    ? (
                                        console.log(errors.building),
                                          <div
                                        className={
                                        "invalid-feedback start-0 top-100"
                                        }
                                      >
                                            {errors.building}
                                          </div>
                                      )
                                    : null}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 col-xl-3">
                              <div className="form-group mt-3 mb-1">
                                <label htmlFor="zip" className="form-label">
                                  {t("Zip Code")}
                                </label>
                                <div className="position-relative">
                                  <input
                                      type="text"
                                      className={`form-control ${
                                        errors.zip && touched.zip
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                      id="zip"
                                      name="zip"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.zip}
                                    />
                                  {errors.zip && touched.zip
                                    ? (
                                      <div
                                        className={
                                        "invalid-feedback start-0 top-100"
                                        }
                                      >
                                        {errors.zip}
                                      </div>
                                      )
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mb-4">
                              <div className="form-check mt-3">
                                <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="is_default"
                                      id="new_address"
                                      checked={values.is_default}
                                      value={values.is_default}
                                      onChange={() => setFieldValue("is_default", !values.is_default)
                                      }
                                    />
                                <label
                                      className="form-check-label text-muted"
                                      htmlFor="address1"
                                    >
                                  {t("Make default")}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-label">&nbsp;</label>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={!dirty}
                                  >
                                  {isLoadingCreateUserAddressMutation || isLoadingUpdateUserAddressMutation
                                    ? <ButtonLoadingSpinner />
                                    : t(`${editableAddress ? "Update" : "Create"}`)
                                    }
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary ms-2"
                                    onClick={() => {
                                      dispatch(setEditableAddress(null));
                                      history.push("/account/profile/addresses");
                                    }}
                                  >
                                  {t("Cancel")}
                                </button>
                              </div>
                            </div>
                          </div>

                        </Form>
                      )}
                    </Formik>
                }
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
}

export default AddressForm;
