import { useTranslation } from "react-i18next";
import { CardNumber, Cvv, ExpiryDate, Frames, SchemeChoice } from "frames-react";
import { Button } from "react-bootstrap";
import ButtonLoadingSpinner from "./ButtonLoadingSpinner";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function AddCard ({
  setError, onSuccess = () => {
  }, onCancel = null, display_amount = undefined

}) {
  const { t } = useTranslation(["translation"]);
  const [isValid, setIsValid] = useState(false);
  // const [createPaymentSource, {isLoading: isLoadingCreatePaymentSource}] = useCreatePaymentSourceMutation();
  const [isSubmittingCard, setIsSubmittingCard] = useState(false);
  const checkeoutPubKey = useSelector(state => state.configStore.merchant.is_checkout_enabled);
  return (
    <>
      <Frames
        config={{
          debug: process.env.REACT_APP_ENV_NAME !== "prod",
          // todo: from merchant settings
          publicKey: checkeoutPubKey,
          localization: {
            cardNumberPlaceholder: "Card number",
            expiryMonthPlaceholder: "MM",
            expiryYearPlaceholder: "YY",
            cvvPlaceholder: "CVV"
          },
          style: {
            base: {
              color: "#1e2438",
              paddingLeft: 0,
              paddingRight: 0,
              borderRadius: 0,
              border: "none",
              borderBottom: "1px solid #677291",
              fontWeight: 500,
              height: 50
            },
            autofill: {
              backgroundColor: "yellow"
            },
            hover: {
              // color: "blue"
            },
            focus: {
              // color: "blue"
            },
            valid: {
              // color: "green"
            },
            invalid: {
              color: "red"
            },
            placeholder: {
              base: {
                color: "gray"
              },
              focus: {
                // border: "solid 1px blue"
              }
            }
          }
        }}
        ready={() => {
        }}
        frameActivated={(e) => {
        }}
        frameFocus={(e) => {
        }}
        frameBlur={(e) => {
        }}
        frameValidationChanged={(e) => {
          console.log("22frameValidationChanged", e);
          // setIsValid(e.isFormValid)
        }}
        paymentMethodChanged={(e) => {
        }}
        cardValidationChanged={(e) => {
          console.log("11cardValidationChanged", e);
          if ("isValid" in e) {
            setIsValid(e.isValid);
          }
        }}
        cardSubmitted={() => {
        }}
        cardTokenized={(e) => {
        }}
        cardTokenizationFailed={(e) => {
        }}
        cardBinChanged={(e) => {
        }}
        // isCardValid={(e) => {}}
      >
        <p className="credit-title mb-20">{t("credit card information")}</p>

        <div className={"row card-inputs-block mb-20"}>
          <div className={"  mx-auto"}>
            <p className="credit-label mb-8"> {t("card number")} </p>

            <CardNumber className="checkout-frames-card-number"/>
            <div className={"row  d-flex pt-4 mb-20"}>
              <div className={"col-6"}>
                <p className="credit-label mb-8"> {t("expiration date")} </p>
                <ExpiryDate className="checkout-frames-card-number" dir="rtl"/>
              </div>

              <div className={"col-6 "}>
                <p className="credit-label mb-8"> {t("cvv")} </p>

                <Cvv className="checkout-frames-card-number"/>
              </div>
            </div>

          </div>
        </div>
        <SchemeChoice/>
        <div className="row justify-content-end">
          {onCancel && (<div className="col-sm-4">
            <div className="form-group">
              <label className="form-label">&nbsp;</label>
              <button type="button" className="btn btn-secondary w-100" onClick={onCancel}>
                {t("Cancel")}
              </button>
            </div>
          </div>)}
          <div className="col-12">
            <div className="form-group  ">

              <Button type={"submit"} className="checkout-btn btn btn-lg w-100 btn-primary" variant="primary"
                      disabled={!isValid || isSubmittingCard}
                      onClick={() => {
                        setIsSubmittingCard(true);
                        Frames.submitCard().then(async result => {
                          onSuccess({ token: result.token, schema_local: result.scheme_local });
                          // const createPsResult = await createPaymentSource({body: {token: result.token}});
                          // console.log("createPsResult", createPsResult);
                          // if (createPsResult && createPsResult.data) {
                          //     onSuccess();
                          // } else {
                          //     setError("Something went wrong, please try again");
                          // }
                          setIsSubmittingCard(false);
                        }).catch(error => {
                          console.log("error", error);

                          setIsSubmittingCard(false);
                        });
                      }}
              >
                {(isSubmittingCard) && <ButtonLoadingSpinner/>}
                {display_amount ?? t("Add Card")}
              </Button>
            </div>
          </div>
        </div>
      </Frames>
    </>
  );
}
