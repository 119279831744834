import { Container } from "react-bootstrap";
import { useAuth } from "../../hooks/auth.hook";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CheckoutEmbedded (props) {
  console.log("Checkout props", props);
  const { t } = useTranslation(["translation"]);
  const { loading, isAuthenticated, makeUrl, makeRequest } = useAuth();
  const [subscription, setSubscription] = useState({ title: "" });
  const [plan, setPlan] = useState({ name: "", cost: "", plan_options: [] });
  const [cost, setCost] = useState({ name: "", cost: "" });
  const [total, setTotal] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [userAddresses, setUserAddresses] = useState([]);
  // const { errorMessage, setErrorMessage } = useAuth();
  const handleCheckboxChange = event => {
    const new_option = plan.plan_options.find(o => o.id + "" === event.target.id + "");
    if (new_option) {
      let newArray = [...selectedOptions, new_option];
      if (selectedOptions.find(option => option.id + "" === event.target.id + "")) {
        newArray = newArray.filter(option => option.id + "" !== event.target.id + "");
      }
      setSelectedOptions(newArray);
    } else {
      console.error("!!!", event);
      console.error(event);
    }
  };
  const handleAddressChange = e => {
    const { value } = e.target;
    setSelectedAddress(
      value
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const url = makeUrl("/subscribe/");
    makeRequest(url, "POST", { subscription: cost.id, address: selectedAddress }).then((response) => {
      props.history.push("/success");
    });
  };
  const getUserAddresses = async () => {
    // const url = makeUrl('/user-addresses/');
    const data = [];
    // await makeRequest(url).then((result) => {
    // 	data = result;
    // });
    return data;
  };
  useEffect(() => {
    setPlan(props.location.state.plan);
    setCost(props.location.state.cost);
    setTotal(props.location.state.cost.cost);
    setSubscription(props.location.state.subscription);
    getUserAddresses().then((result) => {
      if (result) {
        result.map(add => {
          if (add.is_default) {
            setSelectedAddress(add.id);
          }
          return null;
        });
        setUserAddresses(result);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isAuthenticated]);

  useEffect(() => {
    console.log("total recalc");
    let total = props.location.state.cost.cost;
    console.log(selectedOptions);
    selectedOptions.forEach((o) => {
      total = parseFloat(total) + parseFloat(o.cost);
    });
    console.log("total", total);
    setTotal(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  if (!loading && !isAuthenticated) {
    return (
      <Redirect to="/login"/>
    );
  }
  return (
    <div>
      <Container>
        <div className="text-center my-4 py-4">
          <h1>
            Checkout
          </h1>
        </div>
        <div className="row align-items-start position-relative">

          <div className="col-md-6 sticky-md-top">
            <div className="border p-3 mb-4">
              <h3 className="mb-4">
                {t("Plan Options")}
              </h3>

              <ul className="list-unstyled">
                {plan.plan_options.map(plan_option => <>
                  <li className="py-2">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id={plan_option.id}
                               onClick={handleCheckboxChange}/>
                      <label className="form-check-label fw-normal" htmlFor="flexCheckDefault">
                        {plan_option.name}: <strong>$ {parseFloat(plan_option.cost).toFixed(2)}</strong>
                      </label>
                    </div>
                  </li>
                </>
                )}
              </ul>
            </div>
            <div className="mb-4">
              <div className="bg-primary text-white p-4 position-relative">
                <div>
                  <div className="d-flex align-items-center justify-content-between py-1">
                    <h5 className="mb-1">
                      {subscription.title}
                    </h5>
                  </div>
                  <ul className="list-unstyled">
                    <li className="d-flex align-items-center justify-content-between py-1">
                      <h6 className="fw-normal">
                        {plan.name}
                      </h6>
                      <span>
                        $ {parseFloat(cost.cost).toFixed(2)}
                      </span>
                    </li>
                    {selectedOptions.map(selectedOption => {
                      return (
                        <li key={selectedOption.id} className="d-flex align-items-center justify-content-between py-1">
                          <h6 className="fw-normal">
                            - {selectedOption.name}
                          </h6>
                          <span>
                            $ {parseFloat(selectedOption.cost).toFixed(2)}
                          </span>
                        </li>
                      );
                    })}

                    {/* <li className="d-flex align-items-center justify-content-between py-1"> */}
                    {/*	<h6 className="fw-normal"> */}
                    {/*		Options */}
                    {/*	</h6> */}
                    {/*	<strong> */}
                    {/*		$500.00 */}
                    {/*	</strong> */}
                    {/* </li> */}
                    {/* <li className="d-flex align-items-center justify-content-between py-1"> */}
                    {/*	<h6 className="fw-normal"> */}
                    {/*		Options */}
                    {/*	</h6> */}
                    {/*	<strong> */}
                    {/*		$500.00 */}
                    {/*	</strong> */}
                    {/* </li> */}
                  </ul>
                </div>

                <hr className="hr"/>

                <div className="d-flex align-items-center justify-content-between">
                  <span><strong>Total</strong></span>

                  <strong>$ {parseFloat(total).toFixed(2)}</strong>
                </div>
              </div>
            </div>
            {/* <div className="mb-4"> */}
            {/*	<div className="p-5 position-relative"> */}
            {/*		<div className="position-absolute top-0 start-0 p-4"> */}
            {/*			<h4 className="mb-3"> */}
            {/*				{subscription.name} */}
            {/*			</h4> */}
            {/*			<h6 className="fw-normal"> */}
            {/*				{plan.name} - {cost.cost} */}
            {/*			</h6> */}

            {/*		</div> */}
            {/*	</div> */}
            {/* </div> */}
          </div>

          <div className="col-md-6">
            <h3 className="mb-4">
              Payment detail
            </h3>
            <ul className="list-unstyled row g-3 mb-5">

              <li className="col-12">
                <div className="form-check border border-primary p-4  d-flex align-items-center">
                  <input className="form-check-input ms-0 me-3" type="radio" name="cardS" id="btn-check-1"
                         checked={"checked"}/>
                  <label className="form-check-label d-flex align-items-center" htmlFor="btn-check-1">
                    <span className="text-muted me-3"><i className="far fa-credit-card"></i></span>
                    <span className="me-2">{t("Credit card")}</span>
                    <strong>**** **** **** 2652</strong>
                  </label>
                </div>
              </li>
              <li className="col-12">
                <button className="w-100 btn btn-lg btn-primary fw-bold" disabled={true}>
                  <small className="text-nowrap">
                    <i className="fas fa-plus me-3"></i> {t("Add new")}
                  </small>
                </button>
              </li>
            </ul>
            <form className="form">
              <h3 className="mb-4">
                {t("Address")}
              </h3>
              <ul className="list-unstyled row g-3 mb-5">
                {userAddresses.length === 0 && (
                  <span>You did not add any address yet</span>
                )
                }
                {userAddresses.map(address => {
                  return (
                    <li className="col-12" key={address.id}>
                      <div className="form-check border border-primary p-4  d-flex align-items-center">
                        <input className="form-check-input ms-0 me-3" value={address.id} type="radio" name="address"
                               id={`btn-check-a-${address.id}`}
                               defaultChecked={selectedAddress + "" === address.id + ""}
                               onChange={handleAddressChange}/>
                        <label className="form-check-label d-flex align-items-center" htmlFor="btn-check-a-1">
                          <span className="text-muted me-3">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-block">
                            <strong className="d-block">{address.full_name}</strong>
                            <span
                              className="d-block">{address.building} {address.street}, {address.city}, {address.zip ? `${address.zip},` : ""} {address.country}</span>
                          </span>
                        </label>
                      </div>
                    </li>
                  );
                })}
                <li className="col-12 d-none">
                  <button className="w-100 btn btn-lg btn-primary fw-bold" disabled={true}>
                    <small className="text-nowrap">
                      <i className="fas fa-plus me-3"></i> {t("Add new")}
                    </small>
                  </button>
                </li>
              </ul>

              <div className="row">
                {/* <div className="col-12 py-4"> */}
                {/*	<div className="form-check"> */}
                {/*		<input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/> */}
                {/*		<label className="form-check-label" htmlFor="flexCheckChecked"> */}
                {/*			Save payment info */}
                {/*		</label> */}
                {/*	</div> */}
                {/* </div> */}

                <div className="col-12 ">
                  <button type="submit" className="btn btn-lg btn-primary w-100" onClick={handleSubmit}>
                    {t("Pay")} <strong>$ {parseFloat(total).toFixed(2)}</strong>
                  </button>

                  <small className="form-text text-center d-block">
                    {t("Payments are secure and encrypted")}
                  </small>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
}
