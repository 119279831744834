import * as yup from "yup";

export const confirmSchema = yup.object().shape({
  code: yup.string().required("Type code to continue").test("len", "Must be 6 digits", val => val && val.length === 6)
});

export const registrationSchema = yup.object().shape({
  name: yup.string().required("Full Name field is required"),
  email: yup.string().email("This field accept emails only"),
  terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
  phone_number: yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, "Phone number is not valid"),
  country: yup.string().required("Country field is required")

});

export const otpLoginSchema = yup.object().shape({
  email: yup.string().email().when("phone_number", {
    is: (phone_number) => !phone_number || phone_number.length === 0,
    then: yup.string().email("This field accept emails only").required("Email field is required"),
    otherwise: yup.string().email("This field accept emails only")
  }),
  phone_number: yup.string().when("email", {
    is: (email) => !email || email.length === 0,
    then: yup.string().required("Phone Number field is required").matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, "Phone number is not valid"),
    otherwise: yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, "Phone number is not valid")
  }),
  country: yup.string().when("phone_number", {
    is: (phone_number) => phone_number?.length > 0,
    then: yup.string().required("Country is required in case login with phone"),
    otherwise: yup.string()
  })
},
// This is what you missed
[
  ["email", "phone_number"],
  ["phone_number", "country"]
]
);
