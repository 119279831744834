import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import successImg from "../../assets/images/success.svg";

export default function CheckoutSuccess () {
  const { t } = useTranslation(["translation"]);

  return (
    <Container className="py-4 py-lg-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="text-center">
            <img src={successImg} alt="Your SVG Image" />
          </div>
          <div className="text-center">
            <h1 className="mb-3">
              <p className="card-title">{t("thanks for subscripting with us")}</p>
              <p className="card-info">{t("subscription operation done successfully")}{" "}
                {t("you will receive a message informing you about subscription")}</p>
            </h1>
            <div className="rounded-3  w-100 p-2 p-sm-5 d-flex justify-content-around align-content-center success ">

              <p className="mb-5 c ">
                <NavLink className={"btn btn-primary  h-100 min-w100"} to={"/"} style={{ minWidth: "100px" }} >{t("back to home page")}
                </NavLink>
              </p>

              <p className="mb-5 d-flex justify-content-around align-content-center">
                <NavLink className={" btn  btn-primary  btn-outer  h-100"} to={"/account/profile/account-info"} >{t("Profile")}</NavLink>
              </p>

            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
