import { MainLayout } from "../../layouts/MainLayout";
import { Container } from "react-bootstrap";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RegistrationSuccess () {
  const { t } = useTranslation(["translation"]);
  useEffect(() => {
    console.log("useEffect");
  }, []);
  return (
    <MainLayout>
      <div>
        <Container>
          <div className="text-center my-4 py-4">
            <h1 className="text-primary mb-5">
              {t("You successfully registered")}
            </h1>
          </div>
          <div className="row justify-content-center">
          </div>
          <div className="row justify-content-center">
            <p><NavLink className={"btn"} to={"/Login"} variant="primary">{t("Login")}</NavLink></p>
            <p><NavLink className={"btn"} to={"/"} variant="primary">{t("Go to home page")}</NavLink></p>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
}
