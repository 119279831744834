import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { PaymentProvider } from "../Upgrade/Payment";
import { useUpdatePaymentMethodMutation } from "../../store/services/smApi";
import { toast } from "react-toastify";

export default function UpdatePaymentModal ({ show, onHide, onSuccess, subscriptionId, prevMethod }) {
  const { t } = useTranslation();
  const isTapEnabled = useSelector(state => state.configStore.merchant.is_tap_enabled);
  const isCheckoutEnabled = useSelector(state => state.configStore.merchant.is_checkout_enabled);
  const isMoyasarEnabled = useSelector(state => state.configStore.merchant.is_moyasar_enabled);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [title, setTitle] = useState("");
  const [isCheckoutComPayment, setIsCheckoutComPayment] = useState(!!isCheckoutEnabled);
  const [updatePaymentMethod, { isLoading: isUpdatingPaymentMethod }] = useUpdatePaymentMethodMutation();
  const isLoggedIn = useSelector((state) => state.userStore.isLoggedIn);
  const currency = useSelector(
    (state) => state.configStore.merchant.currency_code
  );

  useEffect(() => {
    console.log("PAYMENT METHOD", paymentMethod);
    console.log("PREV METHOD", prevMethod);
    console.log("SOURCE ID", subscriptionId);
  }, [paymentMethod, prevMethod, subscriptionId]);

  useEffect(() => {
    if (isTapEnabled && !isCheckoutEnabled && !isMoyasarEnabled) {
      setPaymentMethod("tap");
    } else if (!isTapEnabled && isCheckoutEnabled && !isMoyasarEnabled) {
      setPaymentMethod("checkout");
    } else if (!isTapEnabled && !isCheckoutEnabled && isMoyasarEnabled) {
      setPaymentMethod("moyasar");
    }
  }, [isTapEnabled, isCheckoutEnabled]);

  useEffect(() => {
    if (prevMethod === paymentMethod) {
      setTitle(t("Update Payment Card"));
    } else {
      setTitle(t("Change Payment Method"));
    }
  }, [paymentMethod, prevMethod]);

  const handlePayment = async (paymentMethod, token, schema_local, return_data) => {
    try {
      const body = {
        payment_provider: paymentMethod,
        card_token: token,
        schema_local
      };
      const result = await updatePaymentMethod({ id: subscriptionId, ...body });
      if (result.data) {
        if (return_data) {
          return result.data;
        }
        onSuccess(result.data);
      } else if (result.error?.data) {
        Object.entries(result.error.data).forEach(([key, value]) => toast.error(`${key}: ${value instanceof Array ? value.join(", ") : value}`));
      } else {
        throw new Error("An error occurred while updating payment method");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <style type="text/css">
        {`
        .modal-content {
            border-radius: 8px;
        }
        .modal-header {
            padding: 1.5rem;
            border-bottom: 0;
        }
        .modal-title {
            font-size: 1.5rem;
            font-weight: 600;
        }
        .modal-body {
            padding: 1.5rem;
        }
        `}
      </style>
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentProvider
                    handlePayment={handlePayment}
                    isArbEnabled={false}
                    isCheckoutEnabled={isCheckoutEnabled}
                    isTabbyEnabled={false}
                    isTapEnabled={isTapEnabled}
                    isMoyasarEnabled={isMoyasarEnabled}
                    isCheckoutComPayment={isCheckoutComPayment}
                    currency={currency}
                    amount={1}
                    upgradeRequestIsLoading={!paymentMethod || !subscriptionId || !prevMethod || isUpdatingPaymentMethod}
                    isLoggedIn={isLoggedIn}
                    isUpdateMethod
                    setIsCheckoutComPayment={setIsCheckoutComPayment}
                />
        </Modal.Body>
      </Modal>
    </>
  );
}
