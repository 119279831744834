import React, { useState } from "react";
import { registrationSchema } from "../../utils/ValidationSchemas";
import { Container } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useHttp } from "../../hooks/http.hook";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import CountryCodes from "../../utils/constants/CountryCodes";
import CountryPhoneField from "../../components/CountryPhoneField";
import { useFetchUserMutation } from "../../store/services/authApi";
import { useHistory } from "react-router-dom";
import { setToken } from "../../store/slices/userSlice";
import { ModelTemplate, ModelDataTypes } from "../../components/ModelTemplate";
function OtherDataStep ({ userCredentials, next, empedded }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang", "ar");
  const [isLoadingReg, setIsLoadingReg] = useState(false);
  const [serverError, setServerError] = useState("");
  const { request } = useHttp();
  const [fetchUser] = useFetchUserMutation();
  const [modelData, setModelData] = useState(ModelDataTypes.none);

  const merchant = useSelector(state => state.configStore.merchant);

  const handleSubmit = async (values, { setErrors, setStatus, resetForm }) => {
    setIsLoadingReg(true);
    try {
      const result = await request("signup/", "POST", { ...values, phone_number: values.phone_number ? `${CountryCodes[values.country]}${values.phone_number}` : values.phone_number })
        .catch(e => {
          setIsLoadingReg(false);
          console.log(JSON.parse(e.message));
          setErrors(JSON.parse(e.message));
          setStatus(JSON.parse(e.message));
        });
      if (result && result.errors) {
        setErrors(result.errors);
        setIsLoadingReg(false);
      } else if (result.data) {
        dispatch(setToken(result.data));
        const userResponse = await fetchUser(result.data.access);
        if (userResponse && !empedded) await history.push(next);
      }
    } catch (e) {
      setIsLoadingReg(false);
      console.warn(e);
      setServerError(JSON.stringify(e));
    }
  };
  const handle_open_model = (type) => {
    console.log(type);
    console.log(type);

    setModelData(type);
  };
  const handle_close_model = () => {
    setModelData(ModelDataTypes.none);
  };

  return (
    <div>
      <style>
        {`
          .react-select__indicators {
            ${lang === "ar" ? "left:0;right:unset !important;" : "right:0 !important;left:unset"}
          }
        `}
      </style>
      <Container className={"py-4 my-4"}>
        <ModelTemplate
        close_text={t("close")}
        handleClose={handle_close_model}
        msg={modelData === ModelDataTypes.privacy ? merchant.privacy_policy : modelData === ModelDataTypes.terms ? merchant.terms : "none" }
        head={modelData === ModelDataTypes.privacy ? t("Privacy Policy") : modelData === ModelDataTypes.terms ? t("Terms and Conditions") : "none"}
        show = {modelData !== ModelDataTypes.none}

        />

        <div className="row justify-content-center">
          <div className={empedded ? "col-lg-10 col-xl-7" : "col-md-8 col-lg-6 col-xl-5"}>
            {userCredentials.signup_token &&
              (
                <div className="form-container shadow bg-white">
                  <div className="text-center">
                    <h1 className="text-primary">{t("Registration")}</h1>
                    <p className="text-secondary pt-2 m-0 under-header">{t("Please fill below fields to create your account")}</p>
                  </div>
                  <Formik
                    validationSchema={registrationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange
                    initialValues={{
                      name: "",
                      email: "",
                      terms: false,
                      country: "SA",
                      phone_number: "",
                      ...userCredentials
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur, // handler for onBlur event of form elements
                      handleFocus,
                      isValid,
                      dirty,
                      setFieldValue,
                      status
                    }) => (
                      <Form className="form">
                        {status && (
                          Object.keys(status).map((key, n) => <div key={n} className="error-msg error mt-4 text-danger">{t(status[key][0])}</div>)
                        )}
                        <div className="position-relative mb-3">
                          <label
                          htmlFor="exampleInputName2"
                          className="form-label mb-1"
                        >
                            {t("Full Name")}
                          </label>
                          <Field
                              autoFocus
                              name="name"
                              className={`form-control ${
                                errors.name && touched.name ? " is-invalid" : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                          {errors.name && touched.name
                            ? (
                              <div className="invalid-feedback start-0 top-100">
                                {t(errors.name)}
                              </div>
                              )
                            : null}
                        </div>
                        {userCredentials.email &&
                          <div className="position-relative">
                            <label
                            htmlFor="exampleInputEmail2"
                            className="form-label mb-1"
                          >
                              {t("Email address")}
                            </label>
                            <div className="position-relative">
                              <Field
                              name="email"
                              className={`form-control ${
                                errors.email && touched.email
                                  ? " is-invalid"
                                  : ""
                              }`}
                              disabled={userCredentials.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                              {errors.email && touched.email
                                ? (
                                  <div className="invalid-feedback start-0 top-100">
                                    {t(errors.email)}
                                  </div>
                                  )
                                : null}
                            </div>
                          </div>
                        }
                        {userCredentials.phone_number &&
                          <CountryPhoneField
                            disabled={userCredentials.phone_number}
                            values={values}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleFocus={handleFocus}
                            setFieldValue={setFieldValue}
                            touched={touched}
                          />
                        }
                        <div className="form-check text-start mt-3">
                          <Field
                              type="checkbox"
                              name="terms"
                              id="exampleCheck2"
                              className="form-check-input flex-shrink-0"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          <label
                              className="form-check-label text-muted fsz-md"
                              htmlFor="exampleCheck2"
                            >
                            {t("I accept to")}{" "}
                            <a onClick={(e) => {
                              e.preventDefault();
                              console.log("terms");
                              handle_open_model(ModelDataTypes.terms);
                            }} className="text-reset cursor-pointer">
                              {t("Terms and Conditions")}
                            </a>{" "}
                            {t("of and")}{" "}
                            <a onClick={(e) => {
                              console.log("privacy");
                              e.preventDefault();

                              handle_open_model(ModelDataTypes.privacy);
                            } } className="text-reset cursor-pointer">
                              {t("Privacy Policy")}
                            </a>
                          </label>
                        </div>
                        <div className="text-center form-footer">
                          {serverError
                            ? (
                              <div className="invalid-feedback start-0 top-100">
                                {serverError}
                              </div>
                              )
                            : null}
                          {isLoadingReg
                            ? <ButtonLoadingSpinner />
                            : <button
                            type="submit"
                            disabled={!isValid || !dirty}
                            className="btn btn-lg w-100 btn-primary mt-4"
                          >
                              {t("Registration")}
                            </button>
                        }
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>)
             }
          </div>
        </div>
      </Container>
    </div>
  );
}

export default OtherDataStep;
