import { useMemo } from "react";

import { useRoutes } from "../../routes";
export default function RoutesMemo ({ isAuthenticated }) {
  const routes = useMemo(
    () => useRoutes(isAuthenticated),
    [isAuthenticated]
  );
  return <div className="content h-100">{routes}</div>;
}
