import React from "react";

const Close = (props) => {
  return (
    <div className="close-parent">
      <div className="close">

        {props.children}

      </div>
    </div>
  );
};

export default Close;
