import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/auth.hook";
import { useTranslation } from "react-i18next";

export default function PlansBlockEmbedded (props) {
  const [plans, setPlans] = useState([]);
  const [subscription, setSubscription] = useState({});
  const { makeUrl, makeUnauthorizedRequest } = useAuth();
  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    const url = makeUrl(`/subscriptions/${props.subscription.id}/plans/`);
    makeUnauthorizedRequest(url).then((result) => {
      result = result.map(plan => { return { ...plan, ...{ chosenCost: plan.costs[0].id } }; });
      setPlans(result);
    });
    setSubscription(props.subscription);
  }, [makeUnauthorizedRequest, makeUrl, props]);
  const costHandler = index => e => {
    const newArr = [...plans]; // copying the old datas array
    newArr[index].chosenCost = e.target.value; // replace e.target.value with whatever you want to change it to
    setPlans(newArr);
  };
  return (<>
    <Row className={"py-4 my-4 row row-cols-1 row-cols-md-3 g-4"}>
      {plans.length === 0 && <span>{t("This subscription does not have any plan yet. Try again later")}</span>}
      {plans.map((plan, planIndex) => {
        return (
          <Col key={plan.id}>
            <Card className={"h-100 text-center"}>
              <Card.Header className={"bg-primary text-white py-3"}>
                <h5 className="mb-0">
                  {plan.name}
                </h5>
              </Card.Header>
              <Card.Body>
                <ul className="list-unstyled lh-lg mb-0">
                  <li>
                    <small>
                      <FontAwesomeIcon icon={faCheck} />
                    </small> <span>Feature 1</span>
                  </li>
                  <li>
                    <small>
                      <FontAwesomeIcon icon={faCheck} /></small> <span>Feature 2</span>
                  </li>
                  <li>
                    <small>
                      <FontAwesomeIcon icon={faCheck} /></small> <span>Feature 3</span>
                  </li>
                  <li>
                    <small>
                      <FontAwesomeIcon icon={faCheck} /></small> <span>Feature 4</span>
                  </li>
                </ul>
                <Card.Text>
                  {plan.header}
                </Card.Text>
                <div className="position-relative py-5">

                  <hr className="position-absolute w-100 m-0 top-50 start-50 translate-middle" />
                  <span className="bg-primary text-white p-1 rounded-2 position-absolute top-50 start-50 translate-middle">
                    Plan Price
                  </span>
                </div>
                <Row className={"justify-content-center"}>
                  <Col className={"col-10"}>
                    <div className="border p-4 text-start mx-auto">
                      <FormGroup as={Row}>
                        {plan.costs.length > 1 && plan.costs.map((cost, costIndex) => {
                          return <Col key={cost.id} sm={10}>
                            <Form.Check type="radio" id={`plan_cost_${cost.id}`} name={`plan_${plan.id}`} value={cost.id}>
                              <Form.Check.Input className={"form-check-input "} type={"radio"} name={`plan_${plan.id}`}
                                defaultChecked={costIndex === 0} onChange={costHandler(planIndex)} value={cost.id} />
                              <Form.Check.Label>
                                <strong className="h4 fw-bolder text-dark">{cost.display_cost_text}</strong> <small
                                  className="text-muted">{cost.display_billing_frequency_text}</small>
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>;
                        }
                        )
                        }
                        {plan.costs.length === 1 && (<div className="p-4 text-start mx-auto">
                          <label className="form-check-label pt-3" htmlFor="flexRadioDefault1">
                            <strong className="h4 fw-bolder text-dark">{plan.costs[0].display_cost_text}</strong> <small
                              className="text-muted">{plan.costs[0].display_billing_frequency_text}</small>
                          </label>
                        </div>
                        )
                        }
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <NavLink to={{
                  pathname: "/embedded/checkout",
                  state: { plan, cost: plan.costs.find(item => item.id === plan.chosenCost), subscription }
                }} className={"btn btn-lg w-100 btn-primary"} variant="primary">Subscribe</NavLink>
              </Card.Footer>
            </Card>
          </Col>
        );
      }
      )}
    </Row>
  </>);
}
