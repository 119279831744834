import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import { useRetrievePaymentRequestsMutation } from "../../../store/services/smApi";
import { useTranslation } from "react-i18next";
import { AccountLayout } from "../../../layouts/AccountLayout";
import LoadingSpinner from "../../../components/LoadingSpinner";
import SubscriptionDetailsSection from "../../../components/SubscriptionDetailsSection";
import PaymentRequestSection from "../../../components/PaymentRequestSection";
import BaseTable from "../../../components/Table/Table";
import formatDateTime from "../../../utils/formatDateTime";
import EmptyData from "../../../components/Table/Empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const PaymentRequestDetails = (props) => {
  const { t } = useTranslation();
  const [retrievePaymentRequests, { isLoading: isLoadingPaymentRequests }] = useRetrievePaymentRequestsMutation();
  const [paymentRequest, setPaymentRequest] = useState({});
  const { id } = useParams();
  const get_payment_request = async () => {
    const result = await retrievePaymentRequests(id);
    if (result && result.data) {
      setPaymentRequest(result.data);
    }
  };
  useEffect(() => {
    get_payment_request();
  }, []);
  return (
    <AccountLayout>
      <h2 className="py-2 mb-0">{t("Payment Request")}</h2>
      {
        (isLoadingPaymentRequests || !paymentRequest || !paymentRequest.id)
          ? <LoadingSpinner/>
          : (
            <div className="row row-cols-auto justify-content-between">
              <div className ="col-12 col-lg-5">
                <PaymentRequestSection paymentRequest={paymentRequest} />
              </div>
              <div className ="col-12 col-lg-5">

                <SubscriptionDetailsSection
                      subscription={paymentRequest?.user_subscription}

                  >
                  <div className="flex items-center">
                    <NavLink to={`/account/subscriptions/${paymentRequest?.user_subscription.id}`} className="btn btn-primary w-100">
                      {t("View Subscription")}
                    </NavLink>
                  </div>
                </SubscriptionDetailsSection>
                <div/>

                <div/>
                <div/>

              </div>
            </div>
            )
      }

      <BaseTable columns={[{
        title: t("Time"),
        dataIndex: "created_at",
        key: "created_at",
        render: (text, record) => {
          return (
            <span className="text-base font-medium text-neutral-600 leading-6">
              {formatDateTime(record?.created_at)}
            </span>
          );
        }
      }, {
        title: t("Status"),
        dataIndex: "status",
        key: "status",
        render: (text, record) => {
          return (
            <span className="text-base font-medium text-neutral-600 leading-6">
              {record?.status}
            </span>
          );
        }
      },
      // {
      //   title: t("Payment Request Status"),
      //   dataIndex: "status",
      //   key: "status",
      //   render: (text, record) => {
      //     return (
      //       <span className="text-base font-medium text-neutral-600 leading-6">
      //         {t(record?.status)}
      //       </span>
      //     );
      //   }
      // },
      {
        title: t("Actions"),
        dataIndex: "id",
        key: "actions",
        render: (text, record) => {
          return (
            <div className="flex items-center flex-column align-content-center">

              <NavLink to={`/account/profile/payment_requests/${paymentRequest?.id}/try/${record?.id}/`} >
                <FontAwesomeIcon icon={faEye} />
              </NavLink>

            </div>
          );
        }
      }
      ]}
       isLoading={isLoadingPaymentRequests} data={paymentRequest?.payment_tries} indexed={true}
       noDataWidget={<EmptyData title={t("There is no Payment Tries Yet")} hint={t("Payment Try will appear her . Click  \"Add Payment Try\" to add Payment Try")}/>} />
      <hr/>

    </AccountLayout>
  );
};
export default PaymentRequestDetails;
