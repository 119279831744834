import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { Container } from "react-bootstrap";
import { otpLoginSchema, confirmSchema } from "../../utils/ValidationSchemas";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFieldRequestCodeMutation, useFieldVerifyCodeMutation, useFetchUserMutation } from "../../store/services/authApi";
import { useLastCodeRequest } from "../../store/slices/userSlice";
import ButtonLoadingSpinner from "../../components/ButtonLoadingSpinner";
import CountryCodes from "../../utils/constants/CountryCodes";
import CountryPhoneField from "../../components/CountryPhoneField";
import { useParams, useHistory } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";

function UpdateAccountInfo () {
  const { t } = useTranslation();
  const history = useHistory();
  const [userCountry, setUserCountry] = useState();
  const user = useSelector(state => state.userStore.user);
  const { last_code_request } = useLastCodeRequest();
  const [fieldRequestCode, { isLoading: isLoadingFieldRequestCode }] = useFieldRequestCodeMutation();
  const [fieldVerifyCode, { isLoading: isLoadingFieldVerifyCode }] = useFieldVerifyCodeMutation();
  const [fetchUser] = useFetchUserMutation();
  const [savedBody, setSavedBody] = useState({});
  const [otpCounter, setOtpCounter] = useState(0);
  const [successMessage, setSuccessMessage] = useState();
  const { field } = useParams();

  const handleSubmitField = async (values) => {
    let resp;
    let success = false;
    let errors = {};
    try {
      resp = await fieldRequestCode(values);
      if (resp && resp.data && resp.data.msg) {
        success = true;
      } else if (resp.error?.data) {
        errors = resp.error.data;
      }
    } catch (error) {
      errors = { server: [error] };
    }
    return { success, errors };
  };

  const handleSubmitFinal = async (values, { setErrors, resetForm, setStatus }) => {
    const body = { ...values, verification_method: field === "email" ? "0" : "1" };
    setSavedBody(values);
    const { success, errors } = await handleSubmitField({ ...body, phone_number: values.phone_number ? `${CountryCodes[values.country]}${values.phone_number}` : values.phone_number });
    if (success) {
      resetForm({ values: { [field]: "", country: userCountry } });
      setSuccessMessage(`${t("The authorisation code has been sent to")} ${body.verification_method === "0" ? body.email : body.phone_number}`);
    } else {
      resetForm({ values });
      setStatus(errors);
      setErrors(errors);
    }
  };

  const handleSubmitCode = async (values, { setErrors, setStatus, resetForm }) => {
    let resp;
    try {
      resp = await fieldVerifyCode(values);
      if (resp && resp.errors) {
        setErrors(resp.errors);
        resetForm({ values });
        return null;
      } else if (resp?.data) {
        await fetchUser();
        history.push("/account/profile/account-info");
        resetForm({ values: { code: "" } });
      } else {
        setStatus(t("Something went wrong, try again later"));
        resetForm({ values });
      }
    } catch (error) {
      console.error(error);
      setStatus(t("Something went wrong, try again later"));
      resetForm({ values });
      return null;
    }
  };

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const result = await fetch("https://ipapi.co/json/").then(res => res.json())
          .catch(err => {
            console.log(err
            );
            setUserCountry("SA");
          });
        setUserCountry(result?.country_code ?? "SA");
      } catch (err) {
        console.log(err);
        setUserCountry("SA");
      }
    };
    fetchCountry();
  }, []);
  useEffect(() => {
    const check = Math.floor(Date.now() / 1000) - last_code_request;
    setOtpCounter(check);
    if (check < 60) {
      const otpInterval = setInterval(() => {
        setOtpCounter((otpCounter) => otpCounter + 1);
      }, 1000);

      return () => clearInterval(otpInterval);
    }
  }, [last_code_request]);

  useEffect(() => {
    document.querySelectorAll("input").forEach((input) => {
      if (input.name.includes("code")) {
        const { width } = input.getBoundingClientRect();
        input.setAttribute("style", "height: " + width + "px");
      }
    });
  });

  return (
    <MainLayout>
      <div>
        {userCountry &&
          <Container className={"py-4 my-4"}>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-xl-5">
                <div className="form-container shadow bg-white">
                  <div className="text-center">
                    {successMessage
                      ? <>
                        <h1 className="text-primary">{t(`Verify ${field}`)}</h1>
                        <p className="text-secondary pt-2 m-0 under-header">
                          {user[field]} <span onClick={() => setSuccessMessage(null)} role="button" className="text-first">تغيير</span>
                        </p>
                        <p className="text-secondary pt-2 m-0 under-header">
                          {t(successMessage)}
                        </p>
                      </>
                      : <>
                        <h1 className="text-primary">{t(`Change ${field}`)}</h1>
                        <p className="text-secondary pt-2 m-0 under-header">
                          {t(`Current ${field}`)}: {user[field]}
                        </p>
                        <p className="text-secondary pt-2 m-0 under-header">
                          {t("Please enter the")} {t("new_" + field)} {t("you would like to add to your account below. A message will be sent to verify the")} {t("new_" + field)} {t("added")}
                        </p>
                      </>

                }
                  </div>
                  {!successMessage &&
                    <Formik
                  validationSchema={otpLoginSchema}
                  // onSubmit={(e) => {e.preventDefault();}}
                  onSubmit={handleSubmitFinal}
                  validateOnChange
                  initialValues={{
                    [field]: "",
                    country: userCountry
                  }}
                >
                      {({
                        errors,
                        touched,
                        values,
                        handleChange,
                        handleBlur, // handler for onBlur event of form elements
                        handleFocus, // handler for onBlur event of form elements
                        isValid,
                        handleSubmit,
                        dirty,
                        status,
                        resetForm,
                        setFieldValue
                      }) => (
                        <Form
                      className="form rounded-3"
                      onSubmit={handleSubmit}
                    >
                          {status && (
                            Object.keys(status).map((key, n) => <div key={n} className="error-msg error mt-4 text-danger">{t(status[key][0])}</div>)
                          )}
                          {field === "email"
                            ? <div className="position-relative">
                              <label
                          htmlFor="exampleInputEmail1"
                          className="form-label mb-1"
                        >
                                {t("Email address")}
                              </label>
                              <div className="position-relative">
                                <Field
                            name="email"
                            className={`form-control ps-5 ${
                              errors.email && touched.email ? " is-invalid" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            onFocus={handleFocus}
                            autoFocus
                          />
                                {errors.email && touched.email
                                  ? (

                                    <div className="invalid-feedback start-0 top-100">
                                      {t(errors.email)}
                                    </div>
                                    )
                                  : null}
                              </div>
                            </div>
                            : <CountryPhoneField
                        errors={errors}
                        values={values}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        handleFocus={handleFocus}
                        touched={touched}
                        autoFocus
                      />
                    }

                          <div className="text-center form-footer pt-4">
                            <button
                          type="submit"
                          disabled={
                            !isValid ||
                            !dirty ||
                            isLoadingFieldRequestCode ||
                            otpCounter < 60
                          }
                          className="btn btn-lg w-100 btn-primary"
                        >
                              {isLoadingFieldRequestCode && <ButtonLoadingSpinner />}{" "}
                              {otpCounter < 60 ? 60 - otpCounter : t("Continue")}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                   }
                  {successMessage && <Formik
                        validationSchema={confirmSchema}
                        onSubmit={handleSubmitCode}
                        validateOnChange
                        initialValues={{
                          code: ""
                        }}
                        >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur, // handler for onBlur event of form elements
                      handleFocus, // handler for onBlur event of form elements
                      isValid,
                      handleSubmit,
                      dirty,
                      status,
                      setErrors,
                      setStatus,
                      setFieldValue,
                      resetForm
                    }) => (
                      <Form
                  className="form verify-form"
                  onSubmit={handleSubmit}
                >
                        {status && (
                          <div className="error-msg error mt-4">{status}</div>
                        )}
                        <div className="position-relative">
                          <div className="position-relative d-flex justify-content-between" dir="ltr">
                            {[1, 2, 3, 4, 5, 6].map(i => {
                              return (

                                <Field
                                    key={i}
                                    name={"code" + i}
                                    autoFocus={i === 1}
                                    className={`form-control otp-input ${
                                    errors.code && touched.code ? " is-invalid" : ""
                                    }`}
                                    onChange={(e) => {
                                      console.log(values.code);
                                      if (values.code.length >= 6 && e.target.value !== "") return;
                                      else if (values.code.length < i - 1) i = values.code.length + 1;
                                      const digits = /^[0-9]+$/;
                                      if (e.target.value.length && !e.target.value.match(digits)) return;
                                      if (e.target.value.length > 0) {
                                        e.target.value.split("").forEach((c, ind) => {
                                          const next = document.querySelector(`input[name="code${i + ind + 1}"]`);
                                          if (next) {
                                            // next.disabled = false;
                                            next.focus();
                                            next.previousElementSibling.disabled = true;
                                          }
                                        });
                                      } else {
                                        const prev = e.target.previousElementSibling;
                                        if (prev) {
                                          prev.disabled = false;
                                          prev.focus();
                                        // prev.nextElementSibling.disabled = true;
                                        }
                                      }
                                      setFieldValue("code", values.code.substring(0, i - 1) + e.target.value + values.code.substring(i));
                                    }}
                                    onBlur={handleBlur}
                                    value={values.code[i - 1] ?? ""}
                                    onFocus={handleFocus}
                                    // disabled={!i === 1}
                                />
                              );
                            })}
                            {errors.code && touched.code
                              ? (
                                <div className="invalid-feedback start-0 top-100">
                                  {t(errors.code)}
                                </div>
                                )
                              : null}
                          </div>
                        </div>

                        <div className="text-center form-footer">
                          <button
                      type="submit"
                      disabled={
                        !values.code || values.code.length < 6 || isLoadingFieldVerifyCode
                      }
                      className="btn btn-lg w-100 btn-primary my-4"
                    >
                            {isLoadingFieldVerifyCode && (
                              <ButtonLoadingSpinner />
                            )}{" "}
                            {t("Login")} {t("Confirm")}
                          </button>
                          <div className="error-msg error mb-1">
                            {t("Resent OTP") + " "}
                            {otpCounter < 60
                              ? (
                                <span
                        className="resend-span">
                                  {(60 - otpCounter) + t("Seconds")}
                                </span>
                                )
                              : (
                                <span
                        role="button"
                        className="resend-span fw-bold"
                        onClick={() => {
                          handleSubmitFinal(
                            savedBody, { setErrors, resetForm, setStatus }
                          );
                        }}
                      >
                                  {isLoadingFieldRequestCode ? <ButtonLoadingSpinner /> : t("Resend")}
                                </span>
                                )}
                            {errors.email && touched.email
                              ? (
                                <div className="invalid-feedback start-0 top-100">
                                  {errors.email}
                                </div>
                                )
                              : null}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
              }
                </div>
              </div>
            </div>
          </Container>
      }
      </div>
    </MainLayout>
  );
}

export default UpdateAccountInfo;
