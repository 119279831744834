import { Button, Card, Col, Form, FormGroup, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFetchSubscriptionPlansMutation } from "../store/services/smApi";
import LoadingSpinner from "./LoadingSpinner";
import { useSelector } from "react-redux";
import formatPeriod from "../utils/formatPeriod";
import { Collapse } from "@mui/material";

export const PlanHtml = ({ plan, collapsed, setCollapsed }) => {
  const { t } = useTranslation(["translation"]);
  return (
    <Card.Text className="justify-content-center plan-card-info text-start py-1">
      {collapsed[plan.id] ? plan.html_content : plan.html_content.substring(0, 255)}
      {plan.html_content.length > 255 &&
        <span role="button" className="text-primary mx-2 fw-bolder fs-6 text-decoration-underline"
              onClick={() => setCollapsed({ ...collapsed, [plan.id]: !collapsed[plan.id] })}>
          {collapsed[plan.id] ? t("Show less") : t("Show more")}
        </span>
      }
    </Card.Text>
  );
};

export default function PlansBlock (props) {
  const history = useHistory();

  const lang = localStorage.getItem("lang", "en");
  const [plans, setPlans] = useState([]);

  const [options, setOptions] = useState([]);
  const [collapsed, setCollapsed] = useState({});
  const [subscription, setSubscription] = useState({});
  const { t } = useTranslation(["translation"]);
  const [fetchSubscriptionPlans, { isLoading: isLoadingSubscriptionPlans }] = useFetchSubscriptionPlansMutation();
  const isLoggedIn = useSelector((state) => state.userStore.isLoggedIn);

  useEffect(() => {
    async function fetchData () {
      // You can await here
      const plans = await fetchSubscriptionPlans(props.subscription.id);
      plans.data.forEach(plan => {
        setCollapsed({ ...collapsed, [plan.id]: false });
      });
      setPlans(plans.data.map((p) => ({ ...p, ...{ chosenCost: p.costs[0].id } })));
      setSubscription(props.subscription);
      // ...
    }

    fetchData();
  }, []);

  const costHandler = index => e => {
    const newArr = [...plans.map((p, pIndex) => index === pIndex ? { ...p, ...{ chosenCost: e.target.value } } : p)];
    setPlans(newArr);
  };
  const plan_option_Handler = (e, id, planIndex) => {
    const newOptions = [...options];
    if (e.target.checked) {
      // we will add it if not exist
      const exists = options.findIndex(el => el.id === id);
      if (!exists === -1) {
        return;
      }
      console.log(plans);
      newOptions.push(plans[planIndex].plan_options.find(el => el.id === id));
    } else {
      const el_index = options.findIndex(el => el.id === id);
      if (el_index > -1) { // only splice array when item is found
        newOptions.splice(el_index, 1); // 2nd parameter means remove one item only
      }
    }
    setOptions(newOptions);
  };

  const SubscribeHandler = (planData) => {
    console.log("SubscribeHandler", planData.selectedOptions);
    // dispatch(setCheckoutingPlan(planData));
    const checkout_url = `checkout?cost=${planData.cost.id}&options=${planData.selectedOptions.map(el => el.id).join(",")}`;
    if (isLoggedIn) history.push("/" + checkout_url);
    else history.push("/login?next=" + checkout_url);
  };
  if (isLoadingSubscriptionPlans) {
    return <LoadingSpinner/>;
  }
  return (<>
    <Row className="justify-content-center pb-4 block-plans">
      {!props.location?.pathname.includes("plans") &&
        <div className={"text-center pb-3"}>
          <h2>{lang === "en" ? subscription.plans_title : subscription.plans_title_ar}</h2>
        </div>
      }

      {plans.map((plan, planIndex) => {
        return (
          <Col className=" col-10 col-md-8 col-lg-4 mb-4" key={planIndex}>
            <Card className="plan-card transition-card text-center h-100 d-flex ">
              <Card.Header className="mb-24 flex-grow-1" style={{ minHeight: "300px", maxHeight: "400px" }}>
                {plan.image &&
                  <Image className="img-fluid plan_img w-100" src={plan.image}/>
                  }
              </Card.Header>
              <Card.Header className="mb-24  ">
                <p className="mb-16 p-0 plan-card-title text-start">
                  {plan.name}
                </p>
                {plan.html_content?.length >= 255
                  ? <Collapse in={collapsed[plan.id]} collapsedSize={200}>
                    <PlanHtml plan={plan} collapsed={collapsed} setCollapsed={setCollapsed}/>
                  </Collapse>
                  : <PlanHtml plan={plan} collapsed={collapsed} setCollapsed={setCollapsed}/>
                  }

              </Card.Header>

              <Card.Body className="pd-0  mb-48 d-flex flex-column">

                <div className="justify-content-center mb-24  flex-grow-1">
                  <div className="col-sm-12">
                    <div
                        className="plan-card-price">
                      <FormGroup>
                        {plan.costs.length >= 1 && plan.costs.map((cost, costIndex) => {
                          return <Form.Check key={cost.id}
                                                 className="d-flex align-items-center plan-card-cost "
                                                 type="radio"
                                                 id={`plan_cost_${cost.id}`}
                                                 name={`plan_${plan.id}`}
                                                 value={cost.id}>
                            <Form.Check.Input className="ml-6 flex-shrink-0 mt-0 icon"
                                                  type={"radio"}
                                                  name={`plan_${plan.id}`}
                                                  defaultChecked={costIndex === 0}
                                                  onChange={costHandler(planIndex)}
                                                  value={cost.id}/>

                            <Form.Check.Label className="label-class">
                              <div className="d-flex flex-column justify-content-center align-items-start gap-1 py-1">
                                <p className="plan-card-price-text lh-sm">
                                  {parseFloat(cost.full_vat_data.total_after_vat).toFixed(2)} {t(cost.display_currency_code)}
                                  <span
                                        className="text-muted d-inline-block ms-1 plan-card-price-text-span">{formatPeriod(cost.recurrence_unit, cost.recurrence_period, t)}</span>
                                </p>
                                {
                                  cost.setup_fee > 0 &&
                                    <span
                                        className="text-muted d-block lh-sm small fw-bold">
                                      {`( + ${parseFloat(cost.setup_fee_with_vat).toFixed(2)} ${t(cost.display_currency_code)} ${t("Setup Fee")})`}
                                    </span>

                                }
                              </div>

                            </Form.Check.Label>

                          </Form.Check>;
                        }
                        )
                          }
                      </FormGroup>
                    </div>
                  </div>
                </div>
                {
                    plan.plan_options && plan.plan_options.length > 0 && <Row className=" mb-24 flex-grow-1 ">
                      <Col><p className="plan-card-title fs-20 text-start mt-1">{t("plan options")}</p>
                        <div className={"d-flex justify-content-start features-block costs-block"}>

                          <FormGroup>

                            {plan.plan_options && plan.plan_options.map(({
                              id,
                              name,
                              price,
                              full_vat_data,
                              recurrence_type
                            }) => {
                              return <Form.Check

                                  key={id}
                                  className="d-flex align-items-center p-0"
                                  type="radio"
                                  id={`plan_option_${id}`}
                                  name={`plan_${id}`}
                                  value={id}>
                                <Form.Check.Input
                                    className="flex-shrink-0 mt-0 ml-6"
                                    type={"checkbox"}
                                    name={`plan_${id}`}
                                    defaultChecked={false}
                                    onChange={(e) => plan_option_Handler(e, id, planIndex)}
                                    value={full_vat_data.total_after_vat}/>
                                <p className="plan-card-price-text">
                                  <span
                                    className=""> {name} </span>

                                  <span
                                      className="">  {full_vat_data.total_after_vat}</span>
                                  <span className="mx-1">{`(${t(recurrence_type)})`}</span>

                                </p>
                              </Form.Check>;
                            }
                            )
                            }
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>

                  }

                <div className="flex-grow-1">

                  {plan.plan_features?.length > 0 && <>
                    <p className="plan-card-title fs-20 text-start mt-1">{t("plan features")}</p>
                    <Row><Col>
                      <div className={"d-flex justify-content-start features-block"}>
                        <ul className="list-unstyled text-muted lh-lg mb-0">
                          {plan.plan_features && plan.plan_features.map((plan_feature, planFIndex) => (
                            <li key={plan.id + "_plan_feature_" + planFIndex}
                             className={"d-flex align-items-center mb-16"}>
                              <FontAwesomeIcon icon={faCheckCircle} className="ml-8 text-primary"/>
                              {plan_feature.actual_value && <span
                                  className="feature-plan-text"> {plan_feature.name}: {plan_feature.actual_value} </span>}
                              {!plan_feature.actual_value &&
                                <span className="feature-plan-text"> {plan_feature.name}</span>}
                            </li>))
                            }
                        </ul>
                      </div>
                    </Col></Row></>
                    }
                </div>
              </Card.Body>
              <Card.Footer className="border-0 d-grid gap-2 ">
                <Button onClick={() => SubscribeHandler({
                  plan,
                  cost: plan.costs.find(item => item.id === plan.chosenCost),
                  chosenCost: plan.chosenCost,
                  subscription,
                  selectedOptions: options

                })} className="p-0 width-none btn">{plan.subscribe_button_text ?? t("subscribe")}</Button>
              </Card.Footer>
            </Card>
          </Col>

        )
        ;
      }
      )}
    </Row>
  </>);
}
