import React, { useEffect, useState } from "react";
import { RECURRING_UNITS, USER_SUBSCRIPTION_STATUS } from "../utils/beDicts";
import { BsFillCheckCircleFill } from "react-icons/bs";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const _parseFloat = (value) => {
  return parseFloat(value).toFixed(2);
};
const SubscriptionDetailsSection = ({ subscription, style = undefined, ...props }) => {
  const currency = useSelector(state => state.configStore.merchant.currency_code);
  const [subscriptionStatus, setSubscriptionStatus] = useState(["", "", ""]);

  useEffect(() => {
    if (subscription.current_status) {
      setSubscriptionStatus(Object.values(USER_SUBSCRIPTION_STATUS).find((status) => status[1] === subscription.current_status));
    }
  }, [subscription]);
  const { t } = useTranslation();
  return (
    <div className={`${style ?? "w-100 h-100"}`}>
      <div className="subscription-details-card p-4">
        <div className="d-flex justify-content-between mb-2 flex-wrap">
          <h2 className="m-0">{subscription.name}</h2>
          <p className={`mt-1 plan-status ${subscriptionStatus[2]}`}>
            &#x2022; <strong>{t(subscriptionStatus[0])}</strong>
          </p>
        </div>
        <p className="plan-price mt-1">
          {/* {parseInt(subscription.grant_total).toFixed(2)} */}
          {_parseFloat(subscription?.full_vat_data?.total_after_vat ?? subscription?.subscription?.full_vat_data?.total_after_vat) }
          {" "}
          {t(currency)} / <span className="text-muted fw-light">
            {`${subscription?.subscription?.recurrence_period ?? subscription.recurrence_period} ${t(RECURRING_UNITS[subscription?.subscription?.recurrence_unit ?? subscription.recurrence_unit].toLowerCase() + (subscription?.subscription?.recurrence_period ?? subscription.recurrence_period > 1 ? "s" : ""))}`}
          </span>
          {
          subscription.setup_fee > 0 && subscription.recurring_count === 0 && (
            <span className="text-muted fw-light small">
              {" ("}
              {t("Including")}
              {" "}
              {_parseFloat(+subscription.setup_fee)}
              {" "}
              {t(currency)}{" "}
              {t("Setup Fee")}
              {")"}
            </span>
          )
        }
        </p>
        {
          subscription.user_subscription_options?.length > 0 && (
            <div className="pt-2">
              <p className="plan-advs mb-0 mt-1">{t("Plan Addons")}</p>

              <div className="mt-2">
                {subscription.user_subscription_options?.map((option, optionIndex) => {
                  return <p key={optionIndex} className="plan-option mb-0 mt-12">
                    <BsFillCheckCircleFill size={18} className="me-2 btn-primary icon" /> {option.name} - {parseInt(option?.full_vat_data?.total_after_vat).toFixed(2)} {t(currency)} \ {t(option.plan_option_origin.recurrence_type)}
                  </p>;
                })}
              </div>

            </div>
          )
          }
        {
          subscription.plan_features?.length > 0 && (
            <div className="pt-2">
              <p className="plan-advs mb-0">{t("Plan Features")}</p>

              <div className="mt-2">
                {subscription.plan_features?.map((feature, featureIndex) => {
                  return <p key={featureIndex} className="plan-option mb-0 mt-12">
                    <BsFillCheckCircleFill size={18} className="me-2 btn-primary icon" /> {feature.name} - {feature.value}
                  </p>;
                })}
              </div>

            </div>
          )
      }

        {props.children}
      </div>
    </div>
  );
};
export default SubscriptionDetailsSection;
