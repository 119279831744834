// eslint-disable-next-line react-hooks/exhaustive-deps
import { Spinner } from "react-bootstrap";

export default function ButtonLoadingSpinner () {
  return (
    <Spinner animation="border" role="status" as="span"
             size="sm"
             aria-hidden="true" variant={"success"}

    />
  );
}
