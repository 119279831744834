export default function invokeDownload (responseFile, fileName) {
  console.log({ responseFile });
  const file = window.URL.createObjectURL(new Blob([responseFile]));
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = file;
  console.log(file);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(file);
}
