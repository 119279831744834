import React from "react";
import { useTranslation } from "react-i18next";
import { AccountLayout } from "../../layouts/AccountLayout";
import { Formik, Form } from "formik";
import i18n from "../../i18n";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { changeLang } from "../../store/slices/userSlice";

function Language () {
  const { t } = useTranslation(["translation"]);
  const dispatch = useDispatch();
  const lang = i18n.language || "ar";
  const options = [
    { value: "en", label: t("English") },
    { value: "ar", label: t("Arabic") }
  ];
  const changeDirection = (lang) => {
    if (lang === "ar") {
      document.body.lang = lang;
      document.body.dir = "rtl";

      document.getElementById("ltr").disabled = true;
      document.getElementById("rtl").disabled = false;
    } else {
      document.body.dir = "ltr";

      document.getElementById("rtl").disabled = true;
      document.getElementById("ltr").disabled = false;
    }
  };
  return (
    <AccountLayout>
      <h2 className="py-2 mb-0">{t("Language")}</h2>
      <hr />
      <Formik
        initialValues={{ lang }}
        onSubmit={async (values) => {
          await i18n.changeLanguage(values.lang);
          dispatch(changeLang(values.lang));
          changeDirection(values.lang);
        }}
        validateOnChange
        enableReinitialize
        >
        {({ handleSubmit, values, dirty, setFieldValue, handleBlur }) => (
          <Form className="form">
            <div className="language-container">
              <label htmlFor="lang">
                <p className="mb-2">{t("Choose language")}</p>
              </label>
              <Select
                            className="language-select form-select form-control mb-2"
                            classNamePrefix="react-select"
                            name="lang"
                            defaultValue={options.find(option => option.value === lang)}
                            value={options.find(option => option.value === values.lang)}
                            options={options}
                            onChange={(e) => setFieldValue("lang", e.value)}
                        />
              <span>{t("Displayed language for addresses, buttons and other texts")}</span>
            </div>
            <div className="mt-4">
              <button className="btn btn-primary px-5 py-3 h-auto w-auto" disabled={!dirty}>
                {t("Save changes")}
              </button>
            </div>
          </Form>
        )}
      </Formik>

    </AccountLayout>
  );
}

export default Language;
